import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './rental-menu/home/home.component';
import { VendorRegistrationComponent } from './rental-menu/vendor-registration/vendor-registration.component';
import { RentalMenuComponent } from './rental-menu/rental-menu.component';
import { VendorLoginComponent } from './rental-menu/vendor-login/vendor-login.component';
import { RentOnlineComponent } from './rental-menu/vendor-login/rent-online/rent-online.component';
import { MarketMapComponent } from './rental-menu/market-map/market-map.component';
import { NewsComponent } from './rental-menu/news/news.component';
import { ContactUsComponent } from './rental-menu/contact-us/contact-us.component';
import { VendorPaymentsComponent } from './Shared/vendor-Payment-details/vendor-payments.component';
import { RentalConfirmationComponent } from './rental-menu/vendor-login/rent-online/rental-confirmation/rental-confirmation.component';
import { AuthGuard } from './auth/auth.guard';
import { NewVendorRentonlineComponent } from './rental-menu/vendor-login/rent-online/new-vendor-rentonline/new-vendor-rentonline.component';

const routes: Routes = [
  {
    path: ':marketId',
    component: HomeComponent,
  },
  {
    path: 'home/:marketId',
    component: HomeComponent,
  },
  {
    path: 'registration/:marketId',
    component: VendorRegistrationComponent,
  },
  {
    path: 'menu/:marketId',
    component: RentalMenuComponent,
  },
  {
    path: 'login/:marketId',
    component: VendorLoginComponent,
  },
  {
    path: 'rent-online/:marketId/:vendorId',
    component: RentOnlineComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rent-online/:marketId/:vendorId/:bookingpage/:rentaltype',
    component: RentOnlineComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'newven-online/:marketId/:vendorId',
    component:NewVendorRentonlineComponent,
  },
  {
    path: 'map/:marketId',
    component: MarketMapComponent,
  },
  {
    path: 'news/:marketId',
    component: NewsComponent,
  },
  {
    path: 'contact/:marketId',
    component: ContactUsComponent,
  },
  {
    path: 'vendor-payments/:marketId',
    component: VendorPaymentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'vendor-payments/:marketId/:vendorId',
    component: VendorPaymentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'thank-you/:marketId',
    component: RentalConfirmationComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
