import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { HomeComponent } from './rental-menu/home/home.component';
import { VendorRegistrationComponent } from './rental-menu/vendor-registration/vendor-registration.component';
import { RentalMenuComponent } from './rental-menu/rental-menu.component';
import { VendorLoginComponent } from './rental-menu/vendor-login/vendor-login.component';
import { RentOnlineComponent } from './rental-menu/vendor-login/rent-online/rent-online.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { MarketMapComponent } from './rental-menu/market-map/market-map.component';
import { NewsComponent } from './rental-menu/news/news.component';
import { ContactUsComponent } from './rental-menu/contact-us/contact-us.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { DiagramModule } from '@syncfusion/ej2-angular-diagrams';
import { DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { LoaderComponent } from './Shared/loader/loader.component';
import { MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DailyrentalOnlineComponent } from './rental-menu/vendor-login/rent-online/dailyrental-online/dailyrental-online.component'
import { TokenInterceptor } from './Shared/token.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { customCurrencyPipe } from './Shared/customCurrency.pipe';
import { PaymentComponent } from './Shared/payment/payment.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { WeeklyrentalOnlineComponent } from './rental-menu/vendor-login/rent-online/weeklyrental-online/weeklyrental-online.component';
import { MonthlyrentalOnlineComponent } from './rental-menu/vendor-login/rent-online/monthlyrental-online/monthlyrental-online.component';
import { CombinedmonthlyrentalOnlineComponent } from './rental-menu/vendor-login/rent-online/combinedmonthlyrental-online/combinedmonthlyrental-online.component';
import { CombinedweeklyrentalOnlineComponent } from './rental-menu/vendor-login/rent-online/combinedweeklyrental-online/combinedweeklyrental-online.component';
import { RentalLayoutComponent } from './rental-menu/vendor-login/rent-online/rentallayout-online/rentallayout-online.component';
import { VendorPaymentsComponent } from './Shared/vendor-Payment-details/vendor-payments.component';
import { RentalConfirmationComponent } from './rental-menu/vendor-login/rent-online/rental-confirmation/rental-confirmation.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BookingDetailsComponent } from './rental-menu/vendor-login/rent-online/booking-details/booking-details.component';
import { BookingDetailsWeeklyMonthlyComponent } from './rental-menu/vendor-login/rent-online/booking-details-weekly-monthly/booking-details-weekly-monthly.component';
import { SuccessTickComponent } from './Shared/success-tick/success-tick.component';
import { FailureCrossComponent } from './Shared/failure-cross/failure-cross.component';
import { NewVendorRentonlineComponent } from './rental-menu/vendor-login/rent-online/new-vendor-rentonline/new-vendor-rentonline.component';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';

@NgModule({
  declarations: [AppComponent, RentalMenuComponent, PaymentComponent, HomeComponent,SuccessTickComponent,FailureCrossComponent, VendorRegistrationComponent, VendorLoginComponent, RentOnlineComponent, MarketMapComponent, NewsComponent, ContactUsComponent, LoaderComponent, customCurrencyPipe, DailyrentalOnlineComponent, WeeklyrentalOnlineComponent, MonthlyrentalOnlineComponent, CombinedweeklyrentalOnlineComponent, CombinedmonthlyrentalOnlineComponent,RentalLayoutComponent,VendorPaymentsComponent, RentalConfirmationComponent, BookingDetailsComponent, BookingDetailsWeeklyMonthlyComponent, NewVendorRentonlineComponent],
  imports: [GridAllModule, HttpClientModule, CurrencyPipe, DecimalPipe, BrowserAnimationsModule, CommonModule, FormsModule, ReactiveFormsModule, BrowserModule, AppRoutingModule, DatePickerModule, DiagramModule, DropDownListModule, MaskedTextBoxModule,ToastrModule.forRoot({preventDuplicates: true}),  ModalModule.forRoot(),ComboBoxModule],
  exports: [CurrencyPipe, DecimalPipe, DatePickerModule, ToastrModule, customCurrencyPipe],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    DatePipe, CurrencyPipe, customCurrencyPipe, BsModalService, PaymentComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
