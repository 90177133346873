import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';
import {
  DiagramComponent, NodeModel, NodeConstraints, MarginModel, ZoomOptions, ConnectorModel, SelectorConstraints,
  AnnotationConstraints, SelectorModel, PaletteModel,DiagramTooltipModel, ImageModel, DiagramTools, SnapConstraints , SnapSettingsModel, DiagramBeforeMenuOpenEventArgs, BasicShapeModel, PathAnnotationModel, TextModel,
  ScrollSettingsModel
} from '@syncfusion/ej2-angular-diagrams';
import Swal from 'sweetalert2';
import { RentalService } from 'src/app/services/Rental.service';

interface DateString {
  startDate: string;
}
@Component({
  selector: 'app-market-map',
  templateUrl: './market-map.component.html',
  styleUrls: ['./market-map.component.scss']
})
export class MarketMapComponent implements OnInit {
  public loaderIcon: Promise<boolean>;
  marketMap: FormGroup;
  @ViewChild('diagram', { static: false })
  public diagram: DiagramComponent;
  private subscriptions: Subscription[] = [];
  selectedDays: Set<number> = new Set();
  selectedWeekdays: string[] = [];
  additionalMarketDate=[];
  excludedMarketDate = [];
  excludedMarketDays=[];
  public snapSettings: SnapSettingsModel = {
    constraints: SnapConstraints.None, 
  };
  parts=[];
  dailyMarketDays=[];
  exclusivedates = []; 
  filteredMarketDays = [];
  selectedDates=[];
  sunday = false;
  monday = false;
  tuesday = false;
  wednesday = false;
  thursay = false;
  friday = false;
  saturday = false;
  AllowBookingsCw = false;
  AllowBookingsWeekly = false;
  AllowBookingsCm = false;
  AllowBookingsMonthly = false;
  rentalStartDates = [];
  monthlyRentalStartDates = [];
  AllowBookingsDaily = false;
  selectedDailyRentalMarketDays=[];
  selectedItems: SelectorModel;
  defaultPart;
  selectedMarketDays=[];
  boothShapes;
  fontName;
  weeklyMarketDays=[];
  monthlyMarketDays=[];
  defaultZoomFactor=0.2;
  fontStyle = [];
  layoutImages = [];
  monthlyCMResvPeriod =0;
  defaultRentalTypeSelected;
  images = [];
  public palettes?: PaletteModel[];
  weeklyCWResvPeriod = 0;
  marketId;
  marketName = "";
  constructor(
    private formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private rentalService: RentalService,
    private marketInfoService:MarketInfoService,
    private datePipe: DatePipe,
    public router: Router) {
    this.marketMap = this.formbuilder.group({
        rentalTypes: ["1"],
        marketWeekSunday:[0],
        marketWeekMonday:[0],
        marketWeekTuesday:[0],
        marketWeekWednesday:[0],
        marketWeekThursday:[0],
        marketWeekFriday:[0],
        marketWeekSaturday:[0],
        marketParts:[''],
        rentalStartDate:[],
        rentalEndDate:[],
        rentalStartMonth:[],
        rentalEndMonth:[],
        monthlyRentalStartDates:[],
        weeklyRentalStartDates:[]
    })
    this.selectedItems = {
      constraints: SelectorConstraints.All &
        ~(SelectorConstraints.ResizeAll | SelectorConstraints.Rotate)
    };
  }
  
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
    });
    this.getMarketDetails();
    this.getMarketDays();   
    this.getParts();
    this.getListOfValuesData();    
    this.getImages(); 
  }

  getMarketDetails() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.marketInfoService.getMarketDetails(this.marketId).subscribe((res: any) => {
      if (res) {
          this.marketName = res.MarketName;
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  getMarketDays() {
    const sub = this.marketInfoService.getMarketDays(this.marketId).subscribe((res: any) => {
      if (res) {
        this.sunday = res.Sunday;
        this.monday = res.Monday;
        this.tuesday = res.Tuesday;
        this.wednesday = res.Wednesday;
        this.thursay = res.Thursday;
        this.friday = res.Friday;
        this.saturday = res.Saturday;
        this.selectedDays["Sunday"] = res.Sunday;
        this.selectedDays["Monday"] = res.Monday;
        this.selectedDays["Tuesday"] = res.Tuesday;
        this.selectedDays["Wednesday"] = res.Wednesday;
        this.selectedDays["Thursday"] = res.Thursday;
        this.selectedDays["Friday"] = res.Friday;
        this.selectedDays["Saturday"] = res.Saturday;

        this.marketMap.get('marketWeekSunday').setValue(this.sunday);
        this.marketMap.get('marketWeekMonday').setValue(this.monday);
        this.marketMap.get('marketWeekTuesday').setValue(this.tuesday);
        this.marketMap.get('marketWeekWednesday').setValue(this.wednesday);
        this.marketMap.get('marketWeekThursday').setValue(this.thursay);
        this.marketMap.get('marketWeekFriday').setValue(this.friday);
        this.marketMap.get('marketWeekSaturday').setValue(this.saturday);        
        this.getDailyMarkets();
        this.getWeeklyMarkets();
        this.getMonthlyMarketDays();
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  getDailyMarkets() {
    this.subscriptions.push(this.marketInfoService.getDailyMarketDays(this.marketId).subscribe((response) => {
      if (response) {
        this.dailyMarketDays = response;
        this.filteredMarketDays = this.dailyMarketDays;
      }
    }, (err) => {
    }));
  }

  checkboxChanged(args, item: any) {
    if (args.srcElement.checked) {
      this.selectedDailyRentalMarketDays.push(new Date(item.MarketDays));     
    } else {
      this.selectedDailyRentalMarketDays = this.selectedDailyRentalMarketDays.filter(day => day.getTime() !== new Date(item.MarketDays).getTime());
    }
  }

  changeMarketMap(){
    this.diagram.clear();
  }

  checkRentalWeeks() {
    const rentalStartDate = new Date(this.marketMap.value['rentalStartDate'].split('-')[0]);
    const rentalEndDate = new Date(this.marketMap.value['rentalEndDate'].split('-')[0]);
    if (rentalEndDate < rentalStartDate) {
      this.marketMap.get('rentalEndDate').setValue(this.marketMap.value['rentalStartDate']);
    }
    this.selectedWeeklyMarketDays();
  }

  checkRentalMonths() {
    if (this.marketMap.value['rentalStartMonth'] && this.marketMap.value['rentalEndMonth']) {
      const startMonth = this.marketMap.value['rentalStartMonth'];
      const endMonth = this.marketMap.value['rentalEndMonth'];
      const [startMonthDate, startYear] = startMonth.split(' ');
      const [endMonthDate, endMonthYear] = endMonth.split(' ');
      const startMonthIndex = this.getMonthIndex(startMonthDate);
      const endMonthIndex = this.getMonthIndex(endMonthDate);
      if (endMonthYear < startYear || (endMonthYear == startYear && endMonthIndex < startMonthIndex)) {
        this.marketMap.get('rentalEndMonth').setValue(startMonth);
      }    
      this.selectedMonthlyMarketDays();      
    }
  }  

  selectedWeeklyMarketDays(){
    this.selectedMarketDays = [];
    this.marketMap.value['marketWeekSunday'] == true ? this.selectedMarketDays.push(1) : 0;
    this.marketMap.value['marketWeekMonday'] == true ? this.selectedMarketDays.push(2) : 0;
    this.marketMap.value['marketWeekTuesday'] == true ? this.selectedMarketDays.push(3) : 0;
    this.marketMap.value['marketWeekWednesday'] == true ? this.selectedMarketDays.push(4) : 0;
    this.marketMap.value['marketWeekThursday'] == true ? this.selectedMarketDays.push(5) : 0;
    this.marketMap.value['marketWeekFriday'] == true ? this.selectedMarketDays.push(6) : 0;
    this.marketMap.value['marketWeekSaturday'] == true ? this.selectedMarketDays.push(7) : 0;

    const rentalStartDate = new Date(this.marketMap.value['rentalStartDate'].split('-')[0]);
    const rentalEndDate = new Date(this.marketMap.value['rentalEndDate'].split('-')[0]);
    if (rentalEndDate < rentalStartDate) {
      this.marketMap.get('rentalEndDate').setValue(this.marketMap.value['rentalStartDate']);
    }
    const today: Date = new Date(new Date().toDateString())
    this.rentalStartDates = [];
    this.selectedMarketDays.map((el, i) => {
      const startDate = new Date(this.marketMap.value['rentalStartDate'].split("-")[0]);
      const endDate = new Date(this.marketMap.value['rentalEndDate'].split("-")[1]);
      const selectedDayOfWeek = el - 1;
      let currentDay = new Date(startDate);
      while (currentDay <= endDate) {
        if (currentDay >= today) {
          if (currentDay.getDay() === selectedDayOfWeek) {
            this.rentalStartDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
          }
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }
      const compareDates = (a: DateString, b: DateString): number => {
        const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
        const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
        return dateA - dateB;
      };
      const sortedDates = this.rentalStartDates.sort(compareDates);

      if (this.rentalStartDates.length)
        this.marketMap.get('weeklyRentalStartDates').setValue(this.rentalStartDates[0].startDate);     
    });   
  }

  selectedMonthlyMarketDays() {
    this.selectedMarketDays = [];
    this.marketMap.value['marketWeekSunday'] == true ? this.selectedMarketDays.push(1) : 0;
    this.marketMap.value['marketWeekMonday'] == true ? this.selectedMarketDays.push(2) : 0;
    this.marketMap.value['marketWeekTuesday'] == true ? this.selectedMarketDays.push(3) : 0;
    this.marketMap.value['marketWeekWednesday'] == true ? this.selectedMarketDays.push(4) : 0;
    this.marketMap.value['marketWeekThursday'] == true ? this.selectedMarketDays.push(5) : 0;
    this.marketMap.value['marketWeekFriday'] == true ? this.selectedMarketDays.push(6) : 0;
    this.marketMap.value['marketWeekSaturday'] == true ? this.selectedMarketDays.push(7) : 0;
    this.monthlyRentalStartDates = [];
    this.selectedMarketDays.map((el, i) => {      
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const startMonth = this.marketMap.value['rentalStartMonth'];
      const endMonth = this.marketMap.value['rentalEndMonth'];
      const [startMonthDate, startYear] = startMonth.split(' ');
      const [endMonthDate, endMonthYear] = endMonth.split(' ');
      const excludedDates = this.excludedMarketDays.map(dateString => new Date(dateString));
      const startMonthIndex = this.getMonthIndex(startMonthDate);
      const endMonthIndex = this.getMonthIndex(startMonthDate);
      var startDate;
      if (startYear === currentYear.toString() && startMonthIndex === currentMonth)
        startDate = currentDate
      else
        startDate = new Date(startYear, startMonthIndex, 1);
      const endDate = new Date(startYear, endMonthIndex + 1, 0);;
      const selectedDayOfWeek = el - 1;
      let currentDay = new Date(startDate);
      while (new Date(this.datePipe.transform(currentDay,'MM/dd/yyyy')) <= new Date(this.datePipe.transform(endDate,'MM/dd/yyyy'))) {
        var exludedDate = excludedDates.some(date => {
          return this.datePipe.transform(date, 'MM/dd/yyyy') === this.datePipe.transform(currentDay, 'MM/dd/yyyy');
        });
        if (currentDay.getDay() === selectedDayOfWeek && !exludedDate) {
          this.monthlyRentalStartDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }
      const compareDates = (a: DateString, b: DateString): number => {
        const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
        const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
        return dateA - dateB;
      };
      const sortedDates = this.monthlyRentalStartDates.sort(compareDates);
      if (this.monthlyRentalStartDates && this.monthlyRentalStartDates.length > 0) {
        this.marketMap.get('monthlyRentalStartDates').setValue(this.monthlyRentalStartDates[0].startDate)
      }
    });
  }

  getMonthIndex(monthString: string): number {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthIndex = months.findIndex(month => month.toLowerCase() === monthString.toLowerCase());
    return monthIndex !== -1 ? monthIndex : 0;
  }

  rentalTypeChange(){
    this.selectedMarketDays =[];
    this.diagram.clear();
   this.getMarketDays();
  }

  viewLayout() {
    this.diagram.clear(); 
    this.selectedMarketDays=[];
    this.marketMap.value['marketWeekSunday'] == true ? this.selectedMarketDays.push(1) : 0;
    this.marketMap.value['marketWeekMonday'] == true ? this.selectedMarketDays.push(2) : 0;
    this.marketMap.value['marketWeekTuesday'] == true ? this.selectedMarketDays.push(3) : 0;
    this.marketMap.value['marketWeekWednesday'] == true ? this.selectedMarketDays.push(4) : 0;
    this.marketMap.value['marketWeekThursday'] == true ? this.selectedMarketDays.push(5) : 0;
    this.marketMap.value['marketWeekFriday'] == true ? this.selectedMarketDays.push(6) : 0;
    this.marketMap.value['marketWeekSaturday'] == true ? this.selectedMarketDays.push(7) : 0;

    var rentalType = this.marketMap.value['rentalTypes'];
    switch (rentalType) {
      case '1':
        if (this.selectedDailyRentalMarketDays.length == 0) {
          Swal.fire({
            // title: $localize`Warning`,
            text: `Please select a Rental Day`,
            icon: "warning",
            confirmButtonText: `Ok`,
          });
        //  Swal.fire('Hey user!', 'You are the rockstar!', 'info');
        }
        else {
          this.loaderIcon = Promise.resolve(true);
          this.subscriptions.push(this.marketInfoService.getAllBoothsForDailyRentalTypeLayout(this.marketId,this.marketMap.value['marketParts'], this.selectedDailyRentalMarketDays).subscribe((res: any) => {
            if (res) {
              const data = res;
              this.bindBoothsToLayout(data);
              this.getBuildingForLayout();
              this.getImagesForLayout();
              this.loaderIcon = Promise.resolve(false);
            }
          }, err => {
            this.loaderIcon = Promise.resolve(false);
          }));
        }
        break;
      case '3':
      case '5':
        this.subscriptions.push(this.rentalService.getRentalSettings(this.marketId).subscribe((response: any) => {
          if (response) {
            const weeklyRentalStartDateYear = new Date(this.marketMap.value['rentalStartDate'].split("-")[0]).getFullYear();
            const weeklyRentalStartDateMonth = new Date(this.marketMap.value['rentalStartDate'].split("-")[0]).getMonth() + 1;
            const weeklyRentalEndDateYear = new Date(this.marketMap.value['rentalEndDate'].split("-")[1]).getFullYear();
            const weeklRentalEndDateMonth = new Date(this.marketMap.value['rentalEndDate'].split("-")[1]).getMonth() + 1;
            const weeklyRentalStartDateYearMonth = `${weeklyRentalStartDateYear}${weeklyRentalStartDateMonth < 10 ? '0' : ''}${weeklyRentalStartDateMonth}`;
            const weeklyRentalEndDateYearMonth = `${weeklyRentalEndDateYear}${weeklRentalEndDateMonth < 10 ? '0' : ''}${weeklRentalEndDateMonth}`;
            var marketDays = '';
            this.selectedMarketDays.forEach(element => {
              marketDays = marketDays.concat(element) + ',';
            });
            if (this.selectedMarketDays.length > 0) {
              this.selectedDates = [];
              this.selectedMarketDays.map((el, i) => {
                const startDate = new Date(this.marketMap.value['rentalStartDate'].split("-")[0]);
                const endDate = new Date(this.marketMap.value['rentalEndDate'].split("-")[1]);
                const selectedDayOfWeek = el - 1;
                let currentDay = new Date(startDate);
                while (currentDay <= endDate) {
                  if (currentDay.getDay() === selectedDayOfWeek) {
                    this.selectedDates.push(this.datePipe.transform(new Date(currentDay), 'MM/dd/yyyy'));
                  }
                  currentDay.setDate(currentDay.getDate() + 1);
                }
              });
            }
            var marketDays = '';
            this.selectedMarketDays.forEach(element => {
              marketDays = marketDays.concat(element) + ',';
            });

            var rentalStartDate = this.selectedDates[0];
            var rentalEndDate = this.selectedDates[this.selectedDates.length - 1];
            var obj = {
              PartId: this.marketMap.value['marketParts'],
              RentalStartDate: new Date(this.marketMap.value['weeklyRentalStartDates']),
              RentalEndDate: new Date(rentalEndDate),
              FirstDayOfRentalStartWeek: new Date(this.marketMap.value['weeklyRentalStartDates']),
              FirstDayOfRentalEndWeek: new Date(rentalEndDate),
              RentalStartDateYearMonth: weeklyRentalStartDateYearMonth,
              RentalEndDateYearMonth: weeklyRentalEndDateYearMonth,
              MarketDays: marketDays.substring(0, marketDays.length - 1),
              WeeklyCombWeeklyReservationPeriod: response.WeeklyCombWeeklyReservationPeriod,
              MonthlyCombMonthlyReservationPeriod: response.MonthlyCombMonthlyReservationPeriod,
              RentalStartDateWeekDayNumber: new Date(rentalStartDate).getDay(),
              AllowVendorVacation: false,
              Monday: this.marketMap.value['marketWeekMonday'] ? true : false,
              Tuesday: this.marketMap.value['marketWeekTuesday'] ? true : false,
              Wednesday: this.marketMap.value['marketWeekWednesday'] ? true : false,
              Thursday: this.marketMap.value['marketWeekThursday'] ? true : false,
              Friday: this.marketMap.value['marketWeekFriday'] ? true : false,
              Saturday: this.marketMap.value['marketWeekSaturday'] ? true : false,
              Sunday: this.marketMap.value['marketWeekSunday'] ? true : false,
            }
            this.subscriptions.push(this.marketInfoService.getALLBoothsForLayoutALLRentalTypes(this.marketId, obj).subscribe((response) => {
              let data = response;
              this.bindBoothsToLayout(data);
              this.getBuildingForLayout();
              this.getImagesForLayout();
            }, err => {
            }));
          }
        }));
        break;
      case '2':
      case '4':
        this.subscriptions.push(this.rentalService.getRentalSettings(this.marketId).subscribe((response: any) => {
          if (response) {
            const rentalStartDateYear = new Date(this.marketMap.get('rentalStartMonth').value.split('-')[0]).getFullYear();
            const rentalStartDateMonth = new Date(this.marketMap.get('rentalStartMonth').value.split('-')[0]).getMonth() + 1;
            const rentalEndDateYear = new Date(this.marketMap.get('rentalEndMonth').value.split('-')[0]).getFullYear();
            const rentalEndDateMonth = new Date(this.marketMap.get('rentalEndMonth').value.split('-')[0]).getMonth() + 1;
            const rentalStartDateYearMonth = `${rentalStartDateYear}${rentalStartDateMonth < 10 ? '0' : ''}${rentalStartDateMonth}`;
            const rentalEndDateYearMonth = `${rentalEndDateYear}${rentalEndDateMonth < 10 ? '0' : ''}${rentalEndDateMonth}`;
            if (this.selectedMarketDays.length > 0) {
              this.selectedDates = [];
              this.selectedMarketDays.map((el, i) => {
                const startDate = new Date(this.marketMap.get('rentalStartMonth').value.split('-')[0])
                const endMonth = this.marketMap.value['rentalEndMonth'];
                const [endMonthDate, endMonthYear] = endMonth.split(' ');
                const endMonthIndex = this.getMonthIndex(endMonthDate);
                const endDate = new Date(endMonthYear, endMonthIndex + 1, 0);

                const selectedDayOfWeek = el - 1;
                let currentDay = new Date(startDate);
                while (currentDay <= endDate) {
                  if (currentDay.getDay() === selectedDayOfWeek) {
                    this.selectedDates.push(this.datePipe.transform(new Date(currentDay), 'MM/dd/yyyy'));
                  }
                  currentDay.setDate(currentDay.getDate() + 1);
                }

                const compareDates = (a, b): number => {
                  const dateA = new Date(a.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                  const dateB = new Date(b.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                  return dateA - dateB;
                };
                const sortedDates = this.selectedDates.sort(compareDates);
              });
            }
            var marketDays = '';
            this.selectedMarketDays.forEach(element => {
              marketDays = marketDays.concat(element) + ',';
            });

            var rentalStartDate = this.selectedDates[0];
            var rentalEndDate = this.selectedDates[this.selectedDates.length - 1];
            var obj = {
              PartId: this.marketMap.value['marketParts'],
              RentalStartDate: new Date(this.marketMap.value['monthlyRentalStartDates']),
              RentalEndDate: new Date(rentalEndDate),
              FirstDayOfRentalStartWeek: new Date(this.marketMap.value['monthlyRentalStartDates']),
              FirstDayOfRentalEndWeek: new Date(rentalEndDate),
              RentalStartDateYearMonth: rentalStartDateYearMonth,
              RentalEndDateYearMonth: rentalEndDateYearMonth,
              MarketDays: marketDays.substring(0, marketDays.length - 1),
              WeeklyCombWeeklyReservationPeriod: response.WeeklyCombWeeklyReservationPeriod,
              MonthlyCombMonthlyReservationPeriod: response.MonthlyCombMonthlyReservationPeriod,
              RentalStartDateWeekDayNumber: new Date(rentalStartDate).getDay(),
              AllowVendorVacation: false,
              Monday: this.marketMap.value['marketWeekMonday'] ? true : false,
              Tuesday: this.marketMap.value['marketWeekTuesday'] ? true : false,
              Wednesday: this.marketMap.value['marketWeekWednesday'] ? true : false,
              Thursday: this.marketMap.value['marketWeekThursday'] ? true : false,
              Friday: this.marketMap.value['marketWeekFriday'] ? true : false,
              Saturday: this.marketMap.value['marketWeekSaturday'] ? true : false,
              Sunday: this.marketMap.value['marketWeekSunday'] ? true : false,
            }
            this.subscriptions.push(this.marketInfoService.getALLBoothsForLayoutALLRentalTypes(this.marketId, obj).subscribe((response) => {
              let data = response;
              this.bindBoothsToLayout(data);
              this.getBuildingForLayout();
              this.getImagesForLayout();
            }, err => {
            }));
          }
        }));
        break;
    }
  }

  getWeeklyMarkets() {
    this.marketInfoService.getWeeklyMarketDays(this.marketId).subscribe((response) => {
      if (response) {
        this.weeklyMarketDays = response;
        this.marketMap.patchValue({
          rentalStartDate: response[0].RentalStartDays,
          rentalEndDate: response[0].RentalEndDays,
        });
        this.selectedWeeklyMarketDays();
      }
    }, (err) => {
    });
  }

  getMonthlyMarketDays() {
    this.subscriptions.push(this.marketInfoService.getMonthlyMarketDays(this.marketId).subscribe((response) => {
      if (response) {
        this.monthlyMarketDays = response;
        this.marketMap.patchValue({
          rentalStartMonth: response[0].RentalMonths,
          rentalEndMonth: response[0].RentalMonths,
        })
        this.selectedMonthlyMarketDays();  
      }
    }, (err) => {
    }));
  }


  toggleDaySelection(day: string, event: any) {
    this.selectedDailyRentalMarketDays = [];
    var selectedRentalTypes= this.marketMap.value['rentalTypes']
    if (selectedRentalTypes == "4" || selectedRentalTypes == "5") {
      this.selectedMarketDays.map((el, i) => {
        switch (el) {
          case 1: this.marketMap.get('marketWeekSunday').setValue(true);
            break;
          case 2: this.marketMap.get('marketWeekMonday').setValue(true);
            break;
          case 3: this.marketMap.get('marketWeekTuesday').setValue(true);
            break;
          case 4: this.marketMap.get('marketWeekWednesday').setValue(true);
            break;
          case 5: this.marketMap.get('marketWeekThursday').setValue(true);
            break;
          case 6: this.marketMap.get('marketWeekFriday').setValue(true);
            break;
          case 7: this.marketMap.get('marketWeekSaturday').setValue(true);
            break;
        }
      });
    }
      this.selectedDays[day] = event.target.checked;
      this.filterDates(selectedRentalTypes);
  }

  filterDates(selectedRentalTypes) {
     if (selectedRentalTypes == "1"){
      this.filteredMarketDays = this.dailyMarketDays.filter(item => {
        const dayName = this.getDayNameFromDate(item.MarketDays);
        return this.selectedDays[dayName];
      });
    }

    var selectedRentalTypes= this.marketMap.value['rentalTypes']
    if (selectedRentalTypes == "3" || selectedRentalTypes == "5") {
      this.selectedWeeklyMarketDays();

    } else if (selectedRentalTypes == "2" || selectedRentalTypes == "4") {
      this.selectedMonthlyMarketDays();
    }
  }

  getDayNameFromDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  }

  getParts() {
    const sub = this.marketInfoService.getParts(this.marketId).subscribe((res: any) => {
      if (res) {
        this.parts = res;
        this.getWebRentalSetting();
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  getWebRentalSetting(){
    const sub = this.marketInfoService.getWebRentalSetting(this.marketId).subscribe((res: any) => {
      if (res) {
        this.defaultPart = res.DefaultLayoutPart;
        this.AllowBookingsDaily =res.AllowBookingsDaily;
        this.AllowBookingsMonthly = res.AllowBookingsMonthly;
        this.AllowBookingsCm =res.AllowBookingsCm;
        this.AllowBookingsWeekly = res.AllowBookingsWeekly;
        this.AllowBookingsCw = res.AllowBookingsCw;
        this.weeklyCWResvPeriod = res.MaxBookingWeeks;
        this.monthlyCMResvPeriod = res.MaxBookingMonths;
        this.defaultRentalTypeSelected = res.DefaultRentalType;
        this.marketMap.get('marketParts').setValue(this.defaultPart);
        this.marketMap.get('rentalTypes').setValue(this.defaultRentalTypeSelected.toString());
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  getListOfValuesData() {
    forkJoin({
      res: this.marketInfoService.getListOfValuesData(),
      layoutFonts: this.marketInfoService.getLayoutFonts(this.marketId)
    }).subscribe(({ res, layoutFonts }) => {
      if (res) {
        this.boothShapes = res.BoothShapes;
        this.fontName = res.FontName;
        this.fontStyle = res.FontStyle;
      }
      if (layoutFonts) {
        this.defaultZoomFactor = layoutFonts.DefaultZoomFactor ?? 0;
      }
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
  }

  bindBoothsToLayout(data){
    if (data.length > 0) {
      this.loaderIcon = Promise.resolve(true);
      data.map((item) => {
        var fontColor = item.Status.toLowerCase() == "r" ? "#fff" : "#000";
        var backColor = "#fff"
        var boothStatus = "Booked";
        switch (item.Status.toLowerCase()) {
          case "r": boothStatus = "Reserved"
          backColor = '#0032CC';
            break;
          case "ro": boothStatus = "Reserved Only"
          backColor = '#483D8B';
            break;
          case "a": boothStatus = "Available"
          backColor = '#fff';
            break;
          case "ab": boothStatus = "Absentee Booking"
          backColor = '#4F9F60';
            break;
          default: boothStatus = "Booked"
          backColor = '#4F9F60';
            break;
        }

        if(item.ExcludeForWeb){
          backColor = '#ff007f';
        }

        const nodeId = 'node_' + item.BoothNumber;
        const diagramNode: NodeModel = {
          id: nodeId,
          shape: {
            type: 'Basic',
            shape: this.boothShapes.filter(e => e.Id == item.ShapeId)[0].Name
          },
          style: {
            fill: backColor,
            strokeColor: this.convertColor(item.BorderColor),
            color: fontColor,
            strokeWidth: 1,
            fontSize: item.FontSize,
            textWrapping: 'Wrap',
            fontFamily: this.fontName.filter(e => e.Id == item.FontName)[0].Name
          },
          annotations:
            [{
              content: item.BoothNumber,
              style: {
                fontSize: item.FontSize,
                color: fontColor,
                fontFamily: this.fontName.filter(e => e.Id == item.FontName)[0].Name,
                textWrapping: 'Wrap',
                bold: this.fontStyle.filter(e => e.Id == item.FontStyle)[0].Name.toLowerCase().includes('bold'),
                italic: this.fontStyle.filter(e => e.Id == item.FontStyle)[0].Name.toLowerCase().includes('italic')
              },
              constraints: AnnotationConstraints.ReadOnly
            }],
          width: item.Width * 3,
          height: item.Length * 3,
          addInfo: {
            allowDrag: true,
            name: 'booth',
            status: item.Status
          },
          constraints: NodeConstraints.Default & ~(NodeConstraints.Drag) | NodeConstraints.Tooltip,
          offsetX: item.LeftCoordinate,
          offsetY: item.TopCoordinate,
          rotateAngle: item.RotateAngle
        };
        this.diagram.add(diagramNode);
      });
      this.loaderIcon = Promise.resolve(false);
      this.diagram.fitToPage();
      if (this.defaultZoomFactor) {
        let zoomOptions: ZoomOptions = {
          type: 'ZoomIn',
          zoomFactor: this.defaultZoomFactor
        };
        this.diagram.zoomTo(zoomOptions);
      }
    }    
  }

  layoutZoomInClick(event) {
    let zoomOptions: ZoomOptions = {
      type: "ZoomIn",
      zoomFactor: 0.2
    }
    this.diagram.zoomTo(zoomOptions);
  }

  layoutZoomOutClick(flag = false) {
    let zoomOptions: ZoomOptions = {
      type: "ZoomOut",
      zoomFactor: 0.2
    }
    this.diagram.zoomTo(zoomOptions);
  }

  getBuildingForLayout() {
    const sub = this.marketInfoService.getBuildingAreasInLayOut(this.marketMap.value['marketParts'], this.marketId).subscribe((res: any) => {
      let data = res;
      if (data.length > 0) {
        data.map((item) => {
          const nodeId = 'node_building' + item.BuildingId;
          const diagramNode: NodeModel = {
            id: nodeId,
            shape: {
              type: 'Basic',
              shape: this.boothShapes.filter(e => e.Id == item.ShapeId)[0].Name
            },
            style: {
              fill: item.BuildingBackColor != 0 ? this.convertColor(item.BuildingBackColor) : "#000",
              strokeColor: item.BuildingBackColor != 0 ? this.convertColor(item.BuildingBorderColor) : "#000",
              strokeWidth: 1,
              fontSize: item.BuildingFontSize,
              textWrapping: 'Wrap',
              fontFamily: this.fontName.filter(e => e.Id == item.BuildingFontNameId)[0].Name
            },
            annotations:
              [{
                content: item.Caption,
                style: {
                  fontSize: item.BuildingFontSize,
                  fontFamily: this.fontName.filter(e => e.Id == item.BuildingFontNameId)[0].Name,
                  textWrapping: 'Wrap',
                  bold: this.fontStyle.filter(e => e.Id == item.BuildingFontStyleId)[0].Name.toLowerCase().includes('bold'),
                  italic: this.fontStyle.filter(e => e.Id == item.BuildingFontStyleId)[0].Name.toLowerCase().includes('italic')
                },
                constraints: AnnotationConstraints.ReadOnly
              }],
            width: item.BuildingWidth * 3,
            height: item.BuildingLength * 3,
            addInfo: {
              allowDrag: false, // Enable drag-and-drop for this shape
              id: item.BuildingId,
              name: 'building'
            },
            constraints: NodeConstraints.Default & ~(NodeConstraints.Drag),
            offsetX: item.LeftCoordinate,
            offsetY: item.TopCoordinate,
            rotateAngle: item.BuildingVerticalDisplay == true ? 90 : 0
          };
          this.diagram.add(diagramNode);
        });
        this.diagram.fitToPage();
        if (this.defaultZoomFactor) {
          let zoomOptions: ZoomOptions = {
            type: 'ZoomIn',
            zoomFactor: this.defaultZoomFactor
          };
          this.diagram.zoomTo(zoomOptions);
        }
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  } 

  getImagesForLayout() {
    this.marketInfoService.getLayoutImages(this.marketMap.value['marketParts'], this.marketId).subscribe((response: any) => {
      if (response) {
        let data = response;
        if (data.length > 0) {

          for (let i = 0; i < data.length; i++) {
            let node = {
              id: 'node_images' + data[i].ImagesForLayoutId,
              addInfo: {
                status: this.layoutImages.filter(e => e.ImageId == data[i].LayoutImagesId)[0].ImagePath,
                id: data[i].ImagesForLayoutId,
                name: "image",
                ImageId: data[i].LayoutImagesId,
              },
              style: {
                strokeWidth: 0
              },
              annotations: [
                {
                  //    content: this.layoutImages.filter(e => e.ImageId == data[i].LayoutImagesId)[0].ImageName,
                  constraints: AnnotationConstraints.ReadOnly,
                  offset: { x: 0.05, y: -0.07 }
                }
              ],
              constraints: NodeConstraints.Default & ~(NodeConstraints.Drag),
              shape: { type: 'Image', shape: 'Image' } as ImageModel, // Type assertion to ImageModel
              width: data[i].ImageWidth * 3,
              height: data[i].ImageLength * 3,
              offsetX: data[i].ImageLeftCoordinate,
              offsetY: data[i].ImageTopCoordinate,
            };

            if ((node.shape as { type: string }).type === 'Image') {
              (node.shape as ImageModel).source = node.addInfo.status;
            }

            this.diagram.add(node);
          }
          this.diagram.fitToPage();
          if (this.defaultZoomFactor) {
            let zoomOptions: ZoomOptions = {
              type: 'ZoomIn',
              zoomFactor: this.defaultZoomFactor
            };
            this.diagram.zoomTo(zoomOptions);
          }
        }
      }
    });
  }

  getImages() {
    const sub = this.marketInfoService.getImages().subscribe((res: any) => {
      if (res) {
        this.layoutImages = res;
        let data = this.layoutImages;
        if (this.layoutImages.length > 0) {
          for (let i = 0; i < this.layoutImages.length; i++) {
            const nodeId = 'node_' + data[i].ImageId;
            let node = {
              id: nodeId, addInfo: {
                status: data[i].ImagePath, id: 'z' + data[i].ImageId, name: "image", ImageId: data[i].ImageId,
              },
              annotations: [{ content: data[i].ImageName, constraints: AnnotationConstraints.ReadOnly, offset: { x: 0.5, y: -0.07 } }],
              shape: { type: 'Image', shape: 'Image' },
              width: 50, height: 50, offsetX: 50, offsetY: 50,
            };
            if (node.shape.type === 'Image') {
              (node.shape as ImageModel).source = node.addInfo.status;
            }
            this.images.push(node);
          }

          this.palettes = [
            {
              id: 'Images',
              expanded: true,
              symbols: this.images,
              title: 'Images'
            },
          ]
        }
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  convertColor(colorValue: number): string {
    if (colorValue == 255 || colorValue == 65280 || colorValue == 0)
      return '#' + colorValue.toString(16).padStart(6, '0');
    else
      return '#' + colorValue.toString(16);
  }

  public created(args: Object): void {
    //fit the diagram to the page with respect to mode and region
     this.diagram.fitToPage({
         mode: 'Page',
         region: 'Content',
        //  margin: {
        //      bottom: 50
        //  },
         canZoomIn: true,
     });
     this.diagram.scrollSettings.horizontalOffset = 0;
     this.diagram.scrollSettings.verticalOffset = 0;
}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
