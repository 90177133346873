import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})

export class UserService {
  private baseURL: string;
  constructor(private http: HttpClient,
    private authService: AuthService
  ) {
    this.baseURL = environment.apiBaseUrl;
  }

  public options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  getToken(userData): Observable<any> {
    const url = `${this.baseURL}/user`;
    return this.http.post<any>(url, userData, this.options)
      .pipe(
        map(res => {
          if (res.Token) {
            this.authService.isLoggedIn = true;
            localStorage.setItem('token', res.Token);
            return res;
          }
          else {
            this.authService.isLoggedIn = false;
          }
        }),
        catchError(this.handleError)
      );
  }

  clearToken(): void {
    localStorage.removeItem('token');
    this.authService.isLoggedIn = false;
  }

  ForgotPassword(marketId, email): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/user/VendorWRLoginForgotPassword?marketId=${marketId}`
    return this.http.post<any>(url, { email }, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      // this.appinsights.logEvent(errMessage);
      return throwError(errMessage);
    }
    return throwError(error || 'Node.js server error');
  }
} 