import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { MarketInfoService } from './MarketInfoService.service';
@Injectable({
  providedIn: 'root',
})

export class ContactUsService {
  private baseURL: string;
  constructor(private http: HttpClient, private marketInfoService: MarketInfoService
  ) {
    this.baseURL = environment.apiBaseUrl;
  }

  public options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };


  SubmitContactUs(ContactUs): Observable<any> {
    var marketId = null;
    this.marketInfoService.getMarketId().subscribe(mrktId => {
      marketId = mrktId;
    });
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/ContactUs/PostContactUsMail/${marketId}`
    return this.http.post<any>(url, ContactUs, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }


  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError(error || 'Node.js server error');
  }
}
