<app-loader *ngIf="loaderIcon | async"></app-loader>
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-2 col-xl-2 alignMenu shadow text-left pt-2 px-1">
            <app-rental-menu></app-rental-menu>
        </div>
        <div class="col-lg-10 col-xl-10 bgMainSection bgBlue text-left">
            <div class="container">
                <div class="row">
                    <div class="col-5 text-left mt-4">
                        <label class="mainHeading">Vendor Registration</label>
                    </div>
                    <div class="col-7 text-right mt-4">
                        <label class="mainHeading">{{marketName}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                        <button #btnContact class="btn btnMain mr-3 mt-2" routerLinkActive="activeTab">Contact
                            Information</button>
                        <button #btnOnlineAccess class="btn btnMain mr-3 mt-2" routerLinkActive="activeTab">Online
                            Access</button>
                        <button #btnPayment class="btn btnMain mt-2" *ngIf="this.registrationFee.RegistrationFee!==0 && this.registrationFee.ChargeVendorRegistration">Payment</button>
                    </div>
                </div>
                <div #divContactInfo class="bgWghite shadow pt-2 mt-2"
                    [ngStyle]="{ display: showContactInfo ? 'block' : 'none' }">
                    <div class="container" [formGroup]="VendorForm">
                        <div class="row">
                            <div class="col-lg-4 pt-3">
                                <label for="txtFirstName" i18n>*First Name</label>
                                <input id="txtFirstName" class="form-control" type="text" #VendorFirstName
                                    formControlName="FirstName" required>
                                <small style="color: red;" *ngIf="VendorForm.get('FirstName').hasError('required')"
                                    i18n>* required </small>
                                <small style="color: red;" *ngIf="VendorForm.get('FirstName').hasError('maxlength')"
                                    i18n>* max length exceeded
                                </small>
                            </div>
                            <div class="col-lg-4 pt-3"
                                *ngIf="this.VendorGeneralSetting.VendorNameFormat.includes('Middle Name')">
                                <label for="txtMiddleName" i18n>Middle Name</label>
                                <input id="txtMiddleName" class="form-control" type="text" formControlName="MiddleName">
                                <small style="color: red;" *ngIf="VendorForm.get('MiddleName').hasError('maxlength')"
                                    i18n>* max length exceeded
                                </small>
                            </div>
                            <div class="col-lg-4 pt-3"
                                *ngIf="this.VendorGeneralSetting.VendorNameFormat.includes('Last Name')">
                                <label for="txtLastName" i18n>Last Name</label>
                                <input id="txtLastName" class="form-control" type="text" formControlName="LastName">
                                <small style="color: red;" *ngIf="VendorForm.get('LastName').hasError('maxlength')"
                                    i18n>* max length exceeded
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">*Address</label>
                                    <input id="txtAddress" class="form-control" type="text" formControlName="Address">
                                    <small style="color: red;" *ngIf="VendorForm.get('Address').hasError('maxlength')"
                                        i18n>* max length exceeded </small>
                                    <small style="color: red;" *ngIf="VendorForm.get('Address').hasError('required')"
                                        i18n>* required </small>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">*City</label>
                                    <input id="txtCity" class="form-control" type="text" formControlName="City">
                                    <small style="color: red;" *ngIf="VendorForm.get('City').hasError('required')"
                                        i18n>* required </small>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">*State/ Province</label>
                                    <!-- <ejs-dropdownlist id="ddlState" *ngIf="showStateDropDown" tabindex="9"
                                        [dataSource]="stateList" [fields]="StateDropDownfields"
                                        [placeholder]="'Select State'" [popupHeight]="200"
                                        filterBarPlaceholder="Search for State/Province" formControlName="State">
                                    </ejs-dropdownlist> -->
                                    <ejs-combobox id="ddlState" *ngIf="showStateDropDown" tabindex="9"
                                    [dataSource]="stateList" [fields]="StateDropDownfields"
                                    [placeholder]="'Select State'" [popupHeight]="200"
                                    filterBarPlaceholder="Search for State/Province" formControlName="State"></ejs-combobox>
                                    <input type="text" *ngIf="!showStateDropDown" maxlength="2" tabindex="9"
                                        class="form-control" formControlName="State">
                                    <small style="color: red;" *ngIf="VendorForm.get('State').hasError('required')"
                                        i18n>* required </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">*Country</label>
                                    <select id="ddlCountry" class="form-control" formControlName="CountryCode"
                                        tabindex="11" (change)="onCountryChange()">
                                        <option *ngFor="let ct of countryList" [value]="ct.Code">{{ct.Name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">*Zip/Postal Code</label>
                                    <ejs-maskedtextbox [mask]="ZipCodeMask" tabindex="13" name="ZipCode" id="ZipCode"
                                        formControlName="ZipCode"></ejs-maskedtextbox>
                                    <small style="color: red;" *ngIf="VendorForm.get('ZipCode').hasError('required')"
                                        i18n>* required </small>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">Work Phone</label>
                                    <ejs-maskedtextbox [mask]="PhoneNumberMask" tabindex="13" name="WorkPhone"
                                        id="telephone" formControlName="WorkPhone"></ejs-maskedtextbox>
                                    <small style="color: red;" *ngIf="VendorForm.get('WorkPhone').hasError('required')"
                                        i18n>* required </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">*Cell Phone</label>
                                    <ejs-maskedtextbox [mask]="PhoneNumberMask" tabindex="13" name="telephone"
                                        id="telephone" formControlName="CellPhone"></ejs-maskedtextbox>
                                    <small style="color: red;" *ngIf="VendorForm.get('CellPhone').hasError('minlength')"
                                        i18n>* not complete</small>
                                    <small style="color: red;" *ngIf="VendorForm.get('CellPhone').hasError('required')"
                                        i18n>* required </small>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">*Email</label>
                                    <input id="txtEmail" class="form-control" type="text" formControlName="Email">
                                    <small style="color: red;" *ngIf="VendorForm.get('Email').hasError('required')"
                                        i18n>* required </small>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">Drivers License</label>
                                    <input id="txtDriverLicense" class="form-control" type="text"
                                        formControlName="DriverLicenseNumber">
                                    <small style="color: red;"
                                        *ngIf="VendorForm.get('DriverLicenseNumber').hasError('required')" i18n>*
                                        required </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">DL Expiry Date</label>
                                    <!-- <ejs-datepicker format='MM/dd/yyyy' class="form-control" [allowEdit]=false 
                                        formControlName="DLExpiryDate"
                                        i18n-placeholder="DL Expiry Date" placeholder="DL Expiry Date"></ejs-datepicker> -->
                                    <input type="date" class="form-control" formControlName="DLExpiryDate"
                                        [placeholder]="'DL Expiry Date'" i18n-placeholder="DL Expiry Date">
                                    <div>
                                        <small style="color: red;" *ngIf="VendorForm.get('DLExpiryDate').invalid" i18n>*
                                            required </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">Resale #</label>
                                    <input id="txtResaleNumber" class="form-control" type="text"
                                        formControlName="ResaleNumber">
                                    <small style="color: red;"
                                        *ngIf="VendorForm.get('ResaleNumber').hasError('required')" i18n>* required
                                    </small>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">Business License #</label>
                                    <input id="txtBusinessLicenseNumber" class="form-control" type="text"
                                        formControlName="BusinessLicenseNumber">
                                    <small style="color: red;"
                                        *ngIf="VendorForm.get('BusinessLicenseNumber').hasError('required')" i18n>*
                                        required </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">* Primary Selling Product</label>
                                    <!-- <ejs-dropdownlist id="ProductPriority" [dataSource]="productList"
                                        [fields]="ProductDropDownfields" [popupHeight]="200" formControlName="ProductPriority"
                                        (valueChange)="primaryproductchange($event)"> -->
                                    <ejs-combobox id="ProductPriority" [dataSource]="productList"
                                    [fields]="ProductDropDownfields" [popupHeight]="200" formControlName="ProductPriority"
                                    (valueChange)="primaryproductchange($event)"></ejs-combobox>
                                    <small style="color: red;" *ngIf="VendorForm.get('ProductPriority').invalid" i18n>*
                                        required</small>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                <div>
                                    <label class="mb-0">* Vendor Type</label>
                                    <ejs-dropdownlist id="ddlType" [dataSource]="VendorTypeList"
                                        [fields]="{text:'VtyTxtVendorType',value:'VtyTxtVendorType'}"
                                        formControlName="VendorType" tabindex="11" placeholder="Select Vendor Type">

                                    </ejs-dropdownlist>
                                    <small style="color: red;" *ngIf="VendorForm.get('VendorType').invalid" i18n>*
                                        required</small>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-right mb-3">
                                <button class="btn btn-outline-primary mt-3 mr-2" (click)="Oncancel()">Cancel</button>
                                <button class="btn btn-primary mt-3" (click)="ValidationVendorDetails()">Next</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div #divOnlineAccess class="bgWghite shadow pt-2 mt-2"
                    [ngStyle]="{ display: showOnlineAccess ? 'block' : 'none' }">
                    <div class="container" [formGroup]="OnlineAccessForm">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                <div>
                                    <label class="mb-0">*User Name</label>
                                    <input id="txtUserName" class="form-control" type="text" formControlName="UserName">
                                    <small
                                        *ngIf="OnlineAccessForm.get('UserName').invalid && (OnlineAccessForm.get('UserName').dirty || OnlineAccessForm.get('UserName').touched)"
                                        style="color: red;">
                                        <span *ngIf="OnlineAccessForm.get('UserName').hasError('required')" i18n>*
                                            required</span>
                                        <span *ngIf="OnlineAccessForm.get('UserName').hasError('minlength')" i18n>*
                                            minimum length is 6 characters</span>
                                    </small>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                <div>
                                    <label class="mb-0">*Password</label>
                                    <input id="txtPassword" class="form-control" type="password"
                                        formControlName="Password">
                                    <small
                                        *ngIf="OnlineAccessForm.get('Password').errors && (OnlineAccessForm.get('Password').dirty || OnlineAccessForm.get('Password').touched)"
                                        style="color: red;">
                                        <div *ngIf="OnlineAccessForm.get('Password').errors.required">*password is
                                            required.</div>
                                        <div *ngIf="OnlineAccessForm.get('Password').errors.minLength">*password must be
                                            at least 6 characters long.</div>
                                        <div *ngIf="OnlineAccessForm.get('Password').errors.maxlength">*password must be
                                            at least 20 characters long.</div>
                                        <div *ngIf="OnlineAccessForm.get('Password').errors.specialChar">*password must
                                            contain at least one special
                                            character.</div>
                                        <div *ngIf="OnlineAccessForm.get('Password').errors.upperCase">*password must
                                            contain at least one uppercase letter
                                            .</div>
                                        <div *ngIf="OnlineAccessForm.get('Password').errors.lowerCase">*password must
                                            contain at least one lowercase letter
                                            .</div>
                                    </small>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                <div>
                                    <label class="mb-0">*Confirm Password</label>
                                    <input id="txtConfirmPassword" class="form-control" type="password"
                                        formControlName="ConfirmPassword">
                                    <small
                                        *ngIf="OnlineAccessForm.get('ConfirmPassword').errors && (OnlineAccessForm.get('ConfirmPassword').dirty || OnlineAccessForm.get('ConfirmPassword').touched)"
                                        style="color: red;">
                                        <div *ngIf="OnlineAccessForm.get('ConfirmPassword').hasError('required')">
                                            *confirm password is
                                            required.</div>
                                    </small>
                                    <small
                                        *ngIf="(OnlineAccessForm.get('ConfirmPassword').dirty || OnlineAccessForm.get('ConfirmPassword').touched)"
                                        style="color: red;">
                                        <div
                                            *ngIf="OnlineAccessForm.get('Password').value !== OnlineAccessForm.get('ConfirmPassword').value">
                                            *Confirm password does not match.
                                        </div>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                <div>
                                    <label class="mb-0">*Security Question</label>
                                    <select id="txtSecurityQuestion" class="form-control"
                                        formControlName="SecurityQuestion" tabindex="11">
                                        <option *ngFor="let sq of securityQuestions" [value]="sq.ID">
                                            {{sq.SecurityQuestion}}</option>
                                    </select>
                                    <small
                                        *ngIf="(OnlineAccessForm.get('SecurityQuestion').dirty || OnlineAccessForm.get('SecurityQuestion').touched)"
                                        style="color: red;">
                                        <div *ngIf="OnlineAccessForm.get('SecurityQuestion').hasError('required')">
                                            *security question is
                                            required.</div>
                                    </small>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                <div>
                                    <label class="mb-0">*Answer</label>
                                    <input id="txtAnswer" class="form-control" type="text" formControlName="Answer">
                                </div>
                                <small
                                    *ngIf="(OnlineAccessForm.get('Answer').dirty || OnlineAccessForm.get('Answer').touched)"
                                    style="color: red;">
                                    <div *ngIf="OnlineAccessForm.get('Answer').hasError('required')">*security answer is
                                        required.</div>
                                </small>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="this.registrationFee.RegistrationFee!==0 && this.registrationFee.ChargeVendorRegistration">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                <div>
                                    <label class="mb-0">*Registeration Fee</label>
                                    <input id="txtRegistrationFee" class="form-control text-right" type="text"
                                        [disabled]="true" [value]="OnlineAccessForm.get('RegistrationFee').value">
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="this.registrationFee.RegistrationFee!==0 && this.registrationFee.ChargeVendorRegistration">
                            <div class="col-12 text-left mt-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="chkCreditCard"
                                        formControlName="registrationPaymentCheck">
                                    <label class="form-check-label" for="chkCreditCard">
                                        I authorize ABC Flea Market to charge this amount to my Credit Card. Upon
                                        authorization and full payment by the issuing card company, ABC Flea Market will
                                        submit a confirmation number, as verification of charged transaction, to the
                                        above named cardholder.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-left mt-2">
                                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS4xMjQiIGhlaWdodD0iMTUuODQzIiB2aWV3Qm94PSIwIDAgMjEuMTI0IDE1Ljg0MyI+CiAgPHBhdGggaWQ9Ikljb25fYXdlc29tZS1oYW5kLXBvaW50LXJpZ2h0IiBkYXRhLW5hbWU9Ikljb24gYXdlc29tZS1oYW5kLXBvaW50LXJpZ2h0IiBkPSJNMjEuMTI0LDEwLjFBMS44NiwxLjg2LDAsMCwxLDE5LjI3NiwxMS45aC00LjEyYTIuMDI5LDIuMDI5LDAsMCwxLS4yNiwyLjkyNywxLjg5LDEuODksMCwwLDEtLjY3OSwyLjU3MWMuMzcsMi4wMjctLjkwNSwyLjk0LTMsMi45NC0uMTEzLDAtLjU0OC0uMDA4LS42Ni0uMDA4LTIuNTU3LjAwNy0zLjE3Mi0xLjI4Mi01LjEtMS41ODFhLjk4OS45ODksMCwwLDEtLjgzNi0uOTc4VjEwLjdoMEExLjk4LDEuOTgsMCwwLDEsNS44LDguODljMS4xOTItLjUzNiwzLjkzNy0yLjAyMyw0LjQzNy0zLjE5YTEuNzU5LDEuNzU5LDAsMCwxLDEuNjUtMS4yQTEuOTgyLDEuOTgyLDAsMCwxLDEzLjcsNy4yNmE1LjYwOCw1LjYwOCwwLDAsMS0uNTg0LDEuMDI4aDYuMTU3QTEuODksMS44OSwwLDAsMSwyMS4xMjQsMTAuMVptLTE3LjE2My4wMTR2Ny45MjJhLjk5Ljk5LDAsMCwxLS45OS45OUguOTlhLjk5Ljk5LDAsMCwxLS45OS0uOTlWMTAuMTExYS45OS45OSwwLDAsMSwuOTktLjk5aDEuOThBLjk5Ljk5LDAsMCwxLDMuOTYxLDEwLjExMVpNMi44MDYsMTcuMDQyYS44MjUuODI1LDAsMSwwLS44MjUuODI1QS44MjUuODI1LDAsMCwwLDIuODA2LDE3LjA0MloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTQuNSkiIGZpbGw9IiMwNzI4ZmYiLz4KPC9zdmc+Cg=="
                                    class="img-fluid" />
                                &nbsp;<a class="rules">Click here to read the Rules and Regulations</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-left mt-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="chkRules"
                                        formControlName="registrationRuleReadCheck">
                                    <label class="form-check-label" for="chkRules">
                                        By checking this box, I acknowledge that I have read, understand, and agree to
                                        the rules and regulations, and will Abide by them. Please visit the office for a
                                        copy of the rules and regulations
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-right mb-3">
                                <button class="btn btn-outline-primary mt-3 mr-1"
                                    (click)="displayContactInfo()">Back</button>
                                <button class="btn btn-outline-primary mt-3 mr-1" (click)="Oncancel()">Cancel</button>
                                <button class="btn btn-primary mt-3 mr-1"
                                    [disabled]="(this.registrationFee.RegistrationFee!==0 && this.registrationFee.ChargeVendorRegistration && !OnlineAccessForm.get('registrationPaymentCheck').value) || !OnlineAccessForm.get('registrationRuleReadCheck').value"
                                    (click)="OnVendorRegister($event)">Register</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div #divRegisterPayment class="bgWghite shadow pt-2 mt-2" *ngIf="processPayment"
                    [ngStyle]="{ display: showregisterpayment ? 'block' : 'none' }">
                    <app-payment [keyedInTransaction]="true" [isQDR]="IsQDR" [MarketId]="marketId"
                        [CCAmount]="registrationFee.RegistrationFee" [TransactionType]="transactionType"
                        [voidPayment]="false" [VerifoneId]="verifoneId" (ClosePaymentPage)="ClosePaymentPage()"
                        (PaymentResponse)="PaymentResponse($event)"></app-payment>
                </div>
            </div>
        </div>
    </div>
</div>