import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { GeneralService } from 'src/app/services/General.service';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';
import { RentalService } from 'src/app/services/Rental.service';
import { VendorService } from 'src/app/services/Vendor.service';
import { customCurrencyPipe } from 'src/app/Shared/customCurrency.pipe';
import { ICountry, IStates, RegistrationFeeModel } from 'src/app/Shared/models/GeneralModel';
import { IVendorGeneralSettings } from 'src/app/Shared/models/SettingsModel';
import { IVendorInfo, IVendorLoginInfoModel, IVendorProductImageContents, IVendorProductImages, IVendorProductModel } from 'src/app/Shared/models/VendorModel';
import { SharedDataService } from 'src/app/Shared/SharedData.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-vendor-rentonline',
  templateUrl: './new-vendor-rentonline.component.html',
  styleUrls: ['./new-vendor-rentonline.component.scss']
})

export class NewVendorRentonlineComponent implements OnInit, OnDestroy {
  showrentOnline: boolean = true;
  showContactInfo: boolean = false;
  showLoginInfo: boolean = false;
  showPaymentPage: boolean = false;
  IsVendorLogin: boolean = true;
  pageName: string = "Rent Online";
  @ViewChild('btnRentOnline') btnRentOnline!: ElementRef;
  @ViewChild('ContactInfo') ContactInfo!: ElementRef;
  @ViewChild('btnLoginInfo') btnLoginInfo!: ElementRef;
  @ViewChild('btnPayment') btnPayment!: ElementRef;
  marketId;
  rentalForm: FormGroup;
  vendorLoginData: IVendorLoginInfoModel;
  VendorGeneralSetting: IVendorGeneralSettings = {} as IVendorGeneralSettings;
  VendorForm: FormGroup;
  public ProductDropDownfields: Object = { text: 'ProductName', value: 'ProductId' };
  validationResponse: any;
  securityQuestions: any[];
  productList: any[];
  public loaderIcon: Promise<boolean>;
  AllowBookingsCw = false;
  AllowBookingsWeekly = false;
  AllowBookingsCm = false;
  vendorAddressMandatory= false;
  AllowBookingsMonthly = false;
  AllowBookingsDaily = false;
  private subscriptions: Subscription[] = [];
  defaultRentalTypeSelected;
  marketName = "";
  countryList = [];
  vendorId;
  openPaymentDetails=false;
  vendorinfo: IVendorInfo;
  showStateDropDown;
  securityQuestion = [];
  showCellPhoneError = false;
  showWorkPhoneError = false;
  vendorMarketID = "";
  selected = '1';
  toasterConfig = {
    closeButton: true,
    timeOut: 4500,
    easing: 'ease-out',
    progressBar: true,
    positionClass: 'toast-top-right',
    tapToDismiss: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'slideUp'
  };
  vendorName;
  resaleNumberRequired = false;
  businessLicenseNoRequired = false;
  stateList: IStates[];
  registrationFee: RegistrationFeeModel;
  newVendor = true;
  VendorProductsList: IVendorProductModel[] = [];
  VendorProductsImageList: IVendorProductImages[] = [] as IVendorProductImages[];
  VendorProductsImageContentList: IVendorProductImageContents[] = [] as IVendorProductImageContents[];
  public PhoneNumberMask: String = '000-000-0000';
  public StateDropDownfields: Object = { text: 'StateCode', value: 'StateCode' };
  public ZipCodeMask: String = '00000-0000';
  VendorTypeList = [];
  businessLicenseInfo :any;
  initialFormValues: any;
  emailIdsPattern = '^(([\\w+-.%]+)@([\\w-.]+)\\.([A-Za-z]{2,}))([\\s]?[,;]{1}[\\s]?([\\w+-.%]+)@([\\w-.]+)\\.([A-Za-z]{2,})){0,4}$';
  constructor(private renderer: Renderer2,
    private formbuilder: FormBuilder,
    private vendorService: VendorService,
    private route: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private generalService: GeneralService,
    public router: Router,
    private toastr: ToastrService,
    private rentalService:RentalService,
    private customCurrency: customCurrencyPipe,
    private marketInfoService: MarketInfoService,
  ) {
    const passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const password = control.value;
      const minLength = 6;
      const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      const hasUpperCase = /[A-Z]+/;
      const hasLowerCase = /[a-z]+/;

      // Check the password against the conditions
      if (password.length < minLength) {
        return { minLength: true };
      }
      if (!hasSpecialChar.test(password)) {
        return { specialChar: true };
      }
      if (!hasUpperCase.test(password)) {
        return { upperCase: true };
      }
      if (!hasLowerCase.test(password)) {
        return { lowerCase: true };
      }

      return null;
    };

    this.rentalForm = this.formbuilder.group({
      rentalTypes: ["1"],
      VendorForm: this.formbuilder.group(
        {
          Address: ['', [Validators.maxLength(50)]],
          BusAddress: ['', [Validators.maxLength(50)]],
          BusCity: [''],
          BusState: [''],
          BusTelePhone: [''],
          BusZipCode: ['', [Validators.maxLength(10)]],
          CellPhone: ['', [Validators.minLength(10), Validators.maxLength(12)]],
          City: [''],
          CompanyName: ['', [Validators.maxLength(50)]],
          Country: [''],
          CountryCode: [0],
          DLExpiryDate: [null],
          DriverLicenseNumber: [''],
          ResaleNumber: [''],
          BusinessLicenseNumber: [''],
          BusinessLicenseExpiryDate:[null],
          Email: ['', [Validators.maxLength(40), Validators.pattern('^(([\\w+-.%]+)@([\\w-.]+)\\.([A-Za-z]{2,}))([\\s]?[,;]{1}[\\s]?([\\w+-.%]+)@([\\w-.]+)\\.([A-Za-z]{2,})){0,4}$')]],
          FirstName: ['', [Validators.required, Validators.maxLength(50)]],
          LastName: ['', [Validators.maxLength(50)]],
          MarketId: [0, [Validators.required]],
          MarketVendorId: [0, [Validators.required]],
          MiddleName: ['', [Validators.maxLength(50)]],
          State: [''],
          VendorId: [0, [Validators.required]],
          VendorSince: [Date],
          VendorType: ['', Validators.required],
          WorkPhone: ['',[Validators.minLength(10),Validators.maxLength(12)]],
          TelePhone: [''],
          ZipCode: [''],
          ProductPriority: ['', [Validators.required]],
        }),
      OnlineAccessForm: this.formbuilder.group(
        {
          UserName: ['', [Validators.maxLength(16), Validators.required]],
          Password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), passwordValidator]],
          ConfirmPassword: ['', Validators.required],
          SecurityQuestion: ['', Validators.required],
          Answer: ['', Validators.required],
          RegistrationFee: [],
          registrationPaymentCheck: new FormControl(false),
          registrationRuleReadCheck: new FormControl(false),
        })
    })
    this.initialFormValues = this.rentalForm.controls['VendorForm'];
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
      this.vendorId = params['vendorId'];
    });
    this.getVendorGeneralSettings();
    this.getMarketDetails();
    this.getallproducts();
    this.getCountries();
    this.getStates();
    this.getWebRentalSetting();
    this.getVendorTypes();   
    this.RegistrationFee();
    this.getSecurityQuestion();
  }

  getSecurityQuestion() {
    this.generalService.GetSecurityQuestion().subscribe((response) => {
      if (response) {
        this.securityQuestions = response.body;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getCountries() {
    const sub = this.generalService.getCountries().subscribe((response: ICountry[]) => {
      this.countryList = response;
    });
    this.subscriptions.push(sub);
  }

  getStates() {
    const sub = this.generalService.getUSStates().subscribe((response: IStates[]) => {
      this.stateList = response;
    });
    this.subscriptions.push(sub);
  } 

  getVendorGeneralSettings() {
    this.loaderIcon = Promise.resolve(true);
    this.subscriptions.push(this.marketInfoService.getCurrentMarketVendorGeneralSettings(this.marketId).subscribe((res: any) => {
      
      if (res) {
        this.VendorGeneralSetting = {
          IsVendorIdManual : res.SpecifyVendorID,
          VendorAddressMandatory : res.VendAddressMandatory,
          AllowAbsenteeRefund : res.BoothStatusRefund,
          TermForLease : res.LeaseLabel,
          DefaultProductEnabled : res.DefaultProductSelected,
          Product : res.ProductId,
          ResaleNumberRequired : res.ResaleNumberRequired,
          DaysToSellWithoutResaleNo : res.DaysSellWithoutResaleNumber,
          BusinessLicenseNumberReq : res.BusinessLicenseNumberRequired,
          DaysToSellWithoutBusinessLicenseNo : res.DaysSellWithoutBusiLicNumber,
          AlphaNumericField1Reqd : res.AlphaNumericField1Reqd,
          AlphaNumericField2Reqd : res.AlphaNumericField2Reqd,
          AlphaNumericField1Name : res.AlphaNumericField1Name,
          AlphaNumericField2Name : res.AlphaNumericField2Name,
          NumericField1Reqd : res.NumericField1Reqd,
          NumericField2Reqd : res.NumericField2Reqd,
          NumericField1Name : res.NumericField1Name,
          NumericField2Name : res.NumericField2Name,
          VendorNameFormat : res.VendorNamingConvention,
          VendorNameFormatId : res.VendorNamingConventionId,
          BoothTerm : res.BoothTerm
        }
        if(!this.VendorGeneralSetting.IsVendorIdManual){
          this.rentalForm.controls['VendorForm'].get('MarketVendorId').disable(); 
        }

        if(this.VendorGeneralSetting.VendorAddressMandatory){
          this.vendorAddressMandatory = true;
          this.rentalForm.controls['VendorForm'].get('City').setValidators([Validators.required]);
          this.rentalForm.controls['VendorForm'].get('ZipCode').setValidators([Validators.required]);
          this.rentalForm.controls['VendorForm'].get('State').setValidators([Validators.required]);
          
          this.rentalForm.controls['VendorForm'].get('City').updateValueAndValidity();
          this.rentalForm.controls['VendorForm'].get('ZipCode').updateValueAndValidity();
          this.rentalForm.controls['VendorForm'].get('State').updateValueAndValidity();
        }
      }
      this.loaderIcon = Promise.resolve(false);
    }));
      this.loaderIcon = Promise.resolve(false);    
  }

  resaleNoInputRequired(val) {
    this.resaleNumberRequired = val;
    if (val) {
      this.rentalForm.controls['VendorForm'].get('ResaleNumber').setValidators([Validators.required]);
      this.rentalForm.controls['VendorForm'].get('ResaleNumber').updateValueAndValidity();
    }
  }

  businessLicenseRequired(val) {
    this.businessLicenseNoRequired = val;
    this.rentalForm.controls['VendorForm'].get('BusinessLicenseNumber').setValidators([Validators.required]);
    this.rentalForm.controls['VendorForm'].get('BusinessLicenseExpiryDate').setValidators([Validators.required]);
    this.rentalForm.controls['VendorForm'].get('BusinessLicenseNumber').updateValueAndValidity();
    this.rentalForm.controls['VendorForm'].get('BusinessLicenseExpiryDate').updateValueAndValidity();   
  }

  getallproducts() {
    this.subscriptions.push(this.marketInfoService.GetAllMarketProducts(this.marketId).subscribe((res: any) => {
      this.productList = [];
      res.map((el, i) => {
        const ob = {
          ProductId: el.ProductId,
          ProductName: el.ProductName,
          ProductCategory: el.ProductCategory,
          ProductCategoryId: el.ProductCategoryId,
          IsInActive: el.IsInActive,
          CompanyProductId: el.CompanyProductId,
        }

        this.productList.push(ob);

      });
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    }));
  }

  primaryproductchange(args1) {
    const Id = args1;
    const product = this.productList.find(p => p.ProductId === Id);

    if (product) {
      this.VendorProductsList.pop();
      const newProduct: IVendorProductModel = {
        VProductId: 0,
        ProductId: product.ProductId,
        Priority: true,
        VendorId: 0,
        ProductName: product.ProductName,
        ProductCategoryId: product.ProductCategoryId,
        ProductCategory: product.ProductCategory,
      };
      this.VendorProductsList.push(newProduct);
    } else {
      console.log("Product not found with ID:", Id);
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    if ((control.value !== '') && (control.value !== null)) {
      if (control.value) {
        const isWhitespace = (control.value).trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
      }
    }
  }

  restrictSpace(eve) {
    if (eve.which === 32) {
      eve.preventDefault();
    }
  }

  onKeyUp(field: string) {
    const phoneControl = this.rentalForm.controls['VendorForm'].get(field);
    if (field === 'CellPhone') {
      this.showCellPhoneError = phoneControl && phoneControl.invalid && phoneControl.touched;
    } else if (field === 'WorkPhone') {
      this.showWorkPhoneError = phoneControl && phoneControl.invalid && phoneControl.touched;
    }
  }

  ngAfterViewInit() {
    this.renderer.setStyle(this.btnRentOnline.nativeElement, 'background-color', '#DDEBFC');
    this.rentalForm.controls['VendorForm'].patchValue({
      CountryCode: this.countryList[0].Code
    });
    this.onCountryChange();
  }

  getMarketDetails() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.marketInfoService.getMarketDetails(this.marketId).subscribe((res: any) => {
      if (res) {
        this.marketName = res.MarketName;
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  onCountryChange() {
    this.showStateDropDown = true;
    if (this.rentalForm.controls['VendorForm'].value['CountryCode'] == 2) {
      this.showStateDropDown = false;
    }
  }

  getVendorTypes() {
    const sub = this.vendorService.GetVendorTypes(this.marketId).subscribe((response: IStates[]) => {
      this.VendorTypeList = response;
    });
    this.subscriptions.push(sub);
  }

  ValidationVendorDetails(): void {
    if (this.rentalForm.controls['VendorForm'].value['FirstName'].trim() == '') {
      this.toastr.error($localize`Vendor first name can't be empty`, $localize`Error`, this.toasterConfig)
      this.loaderIcon = Promise.resolve(false);
      return;
    }

    if(this.resaleNumberRequired){
      if (this.rentalForm.controls['VendorForm'].value['ResaleNumber'].trim() == '') {
        this.toastr.error($localize`Resale Number can't be empty`, $localize`Error`, this.toasterConfig)
        this.loaderIcon = Promise.resolve(false);
        return;
      }
    }

    if(this.businessLicenseNoRequired){
      if (this.rentalForm.controls['VendorForm'].value['BusinessLicenseNumber'].trim() == '' || !this.rentalForm.controls['VendorForm'].value['BusinessLicenseExpiryDate']) {
        this.toastr.error($localize`Business License Number or Expiry date can't be empty`, $localize`Error`, this.toasterConfig)
        this.loaderIcon = Promise.resolve(false);
        return;
      }
    }
    this.loaderIcon = Promise.resolve(true);
    this.vendorinfo = {
      Address: this.rentalForm.controls['VendorForm'].get('Address').value,
      BusAddress: null,
      BusCity: null,
      BusState: null,
      BusTelePhone: null,
      BusZipCode: null,
      CellPhone: this.rentalForm.controls['VendorForm'].get('CellPhone').value,
      City: this.rentalForm.controls['VendorForm'].get('City').value,

      CompanyName: this.rentalForm.controls['VendorForm'].get('CompanyName').value,
      Country: this.rentalForm.controls['VendorForm'].get('Country').value,
      CountryCode: this.rentalForm.controls['VendorForm'].get('CountryCode').value,
      DLExpiryDate: this.rentalForm.controls['VendorForm'].get('DLExpiryDate').value,
      DriverLicenseNumber: this.rentalForm.controls['VendorForm'].get('DriverLicenseNumber').value == null ? '' : this.rentalForm.controls['VendorForm'].get('DriverLicenseNumber').value,
      Email: this.rentalForm.controls['VendorForm'].get('Email').value,
      FirstName: this.rentalForm.controls['VendorForm'].get('FirstName').value,

      LastName: this.rentalForm.controls['VendorForm'].get('LastName').value,
      MarketId: Number(this.marketId),
      MarketVendorId: this.rentalForm.controls['VendorForm'].get('MarketVendorId').value,
      MiddleName: this.rentalForm.controls['VendorForm'].get('MiddleName').value,

      State: this.rentalForm.controls['VendorForm'].get('State').value,
      TelephoneEXT: null,
      VendorId: this.rentalForm.controls['VendorForm'].get('VendorId').value,
      VendorSince: new Date(),
      VendorType: this.rentalForm.controls['VendorForm'].get('VendorType').value,
      TelePhone: this.rentalForm.controls['VendorForm'].get('TelePhone').value,
      WorkPhone: this.rentalForm.controls['VendorForm'].get('WorkPhone').value,
      ZipCode: this.rentalForm.controls['VendorForm'].get('ZipCode').value,
      BusTelephoneEXT: null,

      VendorName: "",
      VendorProductsList: this.VendorProductsList,
      VendorProductImageList: this.VendorProductsImageList,
      VendorProductImageContentList: this.VendorProductsImageContentList,
      VendorProfile: null,
      VenTxtPhotoFile: null,
      BusinessLicNum: this.rentalForm.controls['VendorForm'].get('BusinessLicenseNumber').value,
      ResaleNum: this.rentalForm.controls['VendorForm'].get('ResaleNumber').value,
      WebRentalRequest: true
    };

    this.vendorService.RegisterVendorInfoValidation(this.vendorinfo).subscribe((res: any) => {
      this.validationResponse = res;
      if (this.validationResponse.Success) {       
        this.displayOnlineAccess();
      } else {
        this.toastr.error(this.validationResponse.Message, $localize`Error`, this.toasterConfig)
      }
      this.loaderIcon = Promise.resolve(false);
    }, err=>{
      this.loaderIcon = Promise.resolve(false);
    });
  }

  validateContactForm() {
    if (this.rentalForm['controls'].contactInfoForm.invalid) {
      return true
    } else {
      return false
    }
  }

  paymentCancelClick(eve) {
    this.ngOnInit();
    this.rentOnline();
  }

  getWebRentalSetting() {
    const sub = this.marketInfoService.getWebRentalSetting(this.marketId).subscribe((res: any) => {
      if (res) {
        this.AllowBookingsDaily = res.AllowBookingsDaily;
        this.AllowBookingsMonthly = res.AllowBookingsMonthly;
        this.AllowBookingsCm = res.AllowBookingsCm;
        this.AllowBookingsWeekly = res.AllowBookingsWeekly;
        this.AllowBookingsCw = res.AllowBookingsCw;
        this.rentalForm.get('rentalTypes').setValue(res.DefaultRentalType.toString());

        if (this.sharedDataService.rentalType) {
          this.rentalForm.get('rentalTypes').setValue(this.sharedDataService.rentalType.toString());
          this.sharedDataService.rentalType = "";
          this.sharedDataService.paymentSharedData.RentalMethod = "";
        }
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  bookingFormCompleted(eve) {
    if (eve) {
      this.contactInfo();
    }
  }

  RegistrationFee() {
    this.marketInfoService.RegistrationFee(this.marketId).subscribe((res: any) => {
      this.registrationFee = res;
      this.rentalForm.controls['OnlineAccessForm'].patchValue(
        {
          RegistrationFee: this.registrationFee.RegistrationFee.toString() != null ? this.customCurrency.transform(String(this.registrationFee.RegistrationFee)) : this.customCurrency.transform("0.00"),
        }
      );
    }, (err) => {
      console.log(err);
    });
  }

  rentOnline() {
    this.showrentOnline = true;
    this.showContactInfo = false;
    this.showLoginInfo = false;
    this.showPaymentPage = false;
    this.pageName = "Rent Online";
    this.renderer.setStyle(this.btnRentOnline.nativeElement, 'background-color', '#DDEBFC');
    this.renderer.setStyle(this.ContactInfo.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnLoginInfo.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnPayment.nativeElement, 'background-color', 'white');
  }

  contactInfo() {
    this.showrentOnline = false;
    this.showContactInfo = true;
    this.showLoginInfo = false;
    this.showPaymentPage = false;
    this.pageName = "Current Contact Information";
    this.renderer.setStyle(this.ContactInfo.nativeElement, 'background-color', '#DDEBFC');
    this.renderer.setStyle(this.btnLoginInfo.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnRentOnline.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnPayment.nativeElement, 'background-color', 'white');
  }

  displayOnlineAccess() {
    this.showContactInfo = false;
    this.showLoginInfo = true;
    this.showrentOnline = false;
    this.showPaymentPage = false;
    this.renderer.setStyle(this.btnLoginInfo.nativeElement, 'background-color', '#DDEBFC');
    this.renderer.setStyle(this.ContactInfo.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnRentOnline.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnPayment.nativeElement, 'background-color', 'white');
  }

  paymentInfo() {
    this.showrentOnline = false;
    this.showContactInfo = false;
    this.showLoginInfo = false;
    this.showPaymentPage = true;
    this.openPaymentDetails = true;
    this.pageName = "Online Payment Info";
    this.renderer.setStyle(this.btnPayment.nativeElement, 'background-color', '#DDEBFC');
    this.renderer.setStyle(this.btnRentOnline.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.ContactInfo.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnLoginInfo.nativeElement, 'background-color', 'white');
  }

  setDirtyFlag(eve) {

  }

  isCancelClicked(eve) {
    this.rentalForm.get('rentalTypes').setValue('0');
    setTimeout(() => {
      this.rentalForm.get('rentalTypes').setValue(eve);
    }, 0);
  }

  cancelContactModification() {
    if (this.rentalForm.controls['VendorForm'].dirty) {
      Swal.fire({
        title: $localize`Confirm`,
        text: $localize`Unsaved changes will be lost. Are you sure you want to cancel?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: $localize`Yes`,
        cancelButtonText: $localize`No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.rentalForm.controls['VendorForm'].reset(this.initialFormValues);
          this.rentalForm.controls['VendorForm'].markAsPristine();
        } else {
        }
      });
    } else {
    }
  }

  Oncancel(){

  }

  rentalBookingData(data) {
    this.sharedDataService.paymentSharedData = data;
  }

  openPaymentPage(){
    this.vendorLoginData = {
      VendorId: 0,
      VendorName: null,
      OnlineAccess: true,
      OnlineUserId:this.rentalForm.controls['OnlineAccessForm'].get('UserName').value,
      OnlinePassword:this.rentalForm.controls['OnlineAccessForm'].get('Password').value,
      SecurityQuestionId:this.rentalForm.controls['OnlineAccessForm'].get('SecurityQuestion').value,
      SecurityQuestionAnswer:this.rentalForm.controls['OnlineAccessForm'].get('Answer').value,
      OnlineRegistrationDate: new Date()
    };
    const check = this.CheckValidations();
    if (!check) {
      this.loaderIcon = Promise.resolve(false);
      return;
    }

    this.sharedDataService.newVendorLoginDetails = this.vendorLoginData;
    this.sharedDataService.newVendorContactInfo = this.vendorinfo;
    if(this.sharedDataService.paymentSharedData){      
      if (this.rentalForm.value['rentalTypes'] == 1) {
        if (this.sharedDataService.paymentSharedData.DailyRentalBooth && this.sharedDataService.paymentSharedData.DailyRentalBooth.length > 0) {
          for (const item of this.sharedDataService.paymentSharedData.DailyRentalBooth) {
            item.ProductId = this.rentalForm.controls['VendorForm'].value["ProductPriority"];
          }
        }
      }
      else if (this.rentalForm.value['rentalTypes'] == 2 || this.rentalForm.value['rentalTypes'] == 4) {
        if (this.sharedDataService.paymentSharedData.MonthlyRentalBooth && this.sharedDataService.paymentSharedData.MonthlyRentalBooth.length > 0) {
          for (const item of this.sharedDataService.paymentSharedData.MonthlyRentalBooth) {
            item.ProductId = this.rentalForm.controls['VendorForm'].value["ProductPriority"];
          }
        }
      }
      else if (this.rentalForm.value['rentalTypes'] == 3) {
        if (this.sharedDataService.paymentSharedData.WeeklyRentalBooth && this.sharedDataService.paymentSharedData.WeeklyRentalBooth.length > 0) {
          for (const item of this.sharedDataService.paymentSharedData.WeeklyRentalBooth) {
            item.ProductId = this.rentalForm.controls['VendorForm'].value["ProductPriority"];
          }
        }
      }
      else if (this.rentalForm.value['rentalTypes'] == 5) {
        if (this.sharedDataService.paymentSharedData.CombinedWeeklyRentalBooth && this.sharedDataService.paymentSharedData.CombinedWeeklyRentalBooth.length > 0) {
          for (const item of this.sharedDataService.paymentSharedData.CombinedWeeklyRentalBooth) {
            item.ProductId = this.rentalForm.controls['VendorForm'].value["ProductPriority"];
          }
        }
      }
    }
    if (this.businessLicenseNoRequired) {
      this.businessLicenseInfo = [];
      var obj =
      {
        BusinessLicenseNoRequired: this.businessLicenseNoRequired,
        LicenseNumber: this.rentalForm.controls['VendorForm'].value['BusinessLicenseNumber'].trim(),
        ExpiryDate: this.rentalForm.controls['VendorForm'].value['BusinessLicenseExpiryDate']
      }
      this.businessLicenseInfo = obj;
    }
    this.paymentInfo();
  }

  CheckValidations() {
    if (this.rentalForm.controls['OnlineAccessForm'].get('UserName').hasError('required') || this.rentalForm.controls['OnlineAccessForm'].get('Password').hasError('required') || this.rentalForm.controls['OnlineAccessForm'].get('ConfirmPassword').hasError('required') || this.rentalForm.controls['OnlineAccessForm'].get('SecurityQuestion').hasError('required') || this.rentalForm.controls['OnlineAccessForm'].get('Answer').hasError('required')) {
      this.toastr.error($localize`Please fill all mandatory fields properly`, $localize`Error`, this.toasterConfig)
      return false;
    }

    if (this.rentalForm.controls['OnlineAccessForm'].get('Password').hasError('minLength')) {
      this.toastr.error($localize`Password must be atleast 6 character`, $localize`Error`, this.toasterConfig)
      return false;
    }
    if (this.rentalForm.controls['OnlineAccessForm'].get('Password').hasError('maxLength')) {
      this.toastr.error($localize`Password must be at least 20 characters long.`, $localize`Error`, this.toasterConfig)
      return false;
    }
    if (this.rentalForm.controls['OnlineAccessForm'].get('Password').hasError('upperCase')) {
      this.toastr.error($localize`Password must contain at least one uppercase letter`, $localize`Error`, this.toasterConfig)
      return false;
    }
    if (this.rentalForm.controls['OnlineAccessForm'].get('Password').hasError('specialChar')) {
      this.toastr.error($localize`Password must contain at least one special`, $localize`Error`, this.toasterConfig)
      return false;
    }
    if (this.rentalForm.controls['OnlineAccessForm'].get('Password').hasError('lowerCase')) {
      this.toastr.error($localize`password must contain at least one lowercase letter`, $localize`Error`, this.toasterConfig)
      return false;
    }
    if (this.rentalForm.controls['OnlineAccessForm'].get('Answer').hasError('minLength')) {
      this.toastr.error($localize`Security Answer must be atleast 4 character`, $localize`Error`, this.toasterConfig)
      return false;
    }
    if (this.rentalForm.controls['OnlineAccessForm'].get('Password').value != this.rentalForm.controls['OnlineAccessForm'].get('ConfirmPassword').value) {
      this.toastr.error($localize`Confirm Password doesn't match.`, $localize`Error`, this.toasterConfig)
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
