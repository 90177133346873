<div class="container-fluid">
  <div class="row">
    <div class="col-lg-2 col-xl-2 alignMenu shadow text-left pt-2 px-1">
      <app-rental-menu></app-rental-menu>
    </div>
    <div class="col-lg-10 col-xl-10 bgMainSection bgBlue text-left">
      <div class="container mainContainer">
        <div class="row">
          <div class="col-5 text-left mt-4">
            <label class="mainHeading">Contact Us</label>
          </div>
          <div class="col-7 text-right mt-4">
            <label class="mainHeading">{{marketName}}</label>
          </div>
        </div>
        <div class="bgWghite shadow pt-2 mt-2">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-start mt-3">
                <label class="mb-0">*Name:</label>
                <input type="text" class="form-control" [(ngModel)]="name">
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-start mt-3">
                <label class="mb-0">*Phone:</label>
                <input type="tel" class="form-control" [(ngModel)]="phone" maxlength="20" pattern="[0-9]" (paste)="numberOnlyPaste($event)" (keypress)="numberOnly($event)">
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-start mt-3">
                <label class="mb-0">*E-Mail:</label>
                <input type="email" class="form-control" [(ngModel)]="email">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-3">
                <label class="mb-0">*Subject</label>
                <input class="form-control" [(ngModel)]="subject">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-3">
                <label class="mb-0">*Comments</label>
                <textarea class="form-control" [(ngModel)]="comments"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8 col-md-8 col-lg-5 col-xl-5 text-left mt-3" style="font-size: medium; font-weight: bold;  display: flex;">
                <label class="mb-0 mt-2"><span id="firstNum"></span> + <span id="secondNum"></span> =</label>
                <input type="text" style="width: 23%; font-weight: bold;" class="form-control ml-2" id="captchaResult" (keydown)="validateCaptchaResult($event)" maxlength="4">
                <i type="button" class="fas fa-refresh ml-2 mt-2" style="font-weight: bold;" (click)="generateCaptcha()"></i>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-5 col-xl-5 text-left mt-3" style="font-size: medium; font-weight: bold;  display: flex;">
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-right mt-2 mb-3">
                <button class="btn btn-outline-primary mr-1" (click)="resetForm()">Cancel</button>
                <button class="btn btn-primary" (click)="onSubmit()">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
