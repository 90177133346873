import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit{
  marketId;
  marketName = "";
  private subscriptions: Subscription[] = [];
  public loaderIcon: Promise<boolean>;  
  constructor(
    private route: ActivatedRoute,
    private marketInfoService: MarketInfoService) {}
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
      this.getMarketDetails();
    });
  }

  getMarketDetails() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.marketInfoService.getMarketDetails(this.marketId).subscribe((res: any) => {
      if (res) {
          this.marketName = res.MarketName;
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }
}
