<form [formGroup]="bookingDetailsForm" *ngIf="!processPayment">
    <div class="row">
        <div class="col-12 text-center">
            <hr class="my-2" />
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-left">
            <label class="fw-700">{{RentalTypeText}} Bookings:</label>
        </div>
    </div>

    <div *ngIf="rentalType == 2">
        <div class="row">
            <div class="col-12 text-left mt-2">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" class="right-border text-left" style="width: 8rem;">Booking Details</th>
                                <th scope="col" class="border text-left" style="width: 8rem;">Renew Booking</th>
                                <th scope="col" class="border text-left" style="width: 8rem;">Renew Start Month</th>
                                <th scope="col" class="border text-left" style="width: 8rem;">Renew End Month</th>
                                <th scope="col" class="left-border text-left" style="width: 8rem;">Rent</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="selectedMonthlyBooths.length > 0">
                            <tr *ngFor="let item of selectedMonthlyBooths; let i = index">
                                <td scope="row">
                                    Booth # : {{item.BoothNumber}}<br />
                                    Status : <span style="color: red">{{item.Status == 'R'? 'Reserved' : 'Booked'}}</span><br />
                                    <a (click)="toggleDetails(i)" class="showDetails">
                                        <img [src]="item.showDetails ? '../assets/images/minus.jpg' : '../assets/images/plus.jpg'"
                                         style="width: 10%;"> {{ item.showDetails ? 'Hide Details' : 'Show Details' }}
                                    </a>
                                    <div *ngIf="item.showDetails">
                                        Product : {{item.ProductName}}<br />
                                        Rent Charged :{{item.CurrentRent}}<br />
                                        Sales Tax : {{item.CurrentSalesTax}}<br />
                                        {{secondTaxName}} : {{item.CurrentSecondTax}}<br />
                                        Booking Start Week : {{item.CurrentRentStartPeriod}}<br />
                                        Booking End Week : {{item.CurrentRentEndPeriod}}<br />
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="checkbox-wrapper mt-2">
                                        <label class="pure-material-checkbox">
                                            <input type="checkbox" class="CheckboxClass" [(ngModel)]="item.Reservation"
                                            [ngModelOptions]="{standalone: true}" (change)="updateRentalRecord(item)">
                                            <span></span>
                                        </label>
                                    </div>
                                </td>
                                <td class="text-left">{{item.RenewalStartMonth}}
                                </td>
                                <td class="text-left"><select class="form-control" [(ngModel)]="item.MonthlyRenewalMonth"
                                    [ngModelOptions]="{standalone: true}" (change)="extendMonthlyRental(item)" [disabled]="!item.BookingCanExtend">
                                    <ng-container *ngFor="let marketDay of monthlyMarketDays; let i = index">
                                        <option *ngIf="i > item.RentEndPeriodIndex" [ngValue]="marketDay.RentalMonths">
                                            {{ marketDay.RentalMonths }}
                                        </option>
                                    </ng-container>
                                </select></td>
                                <td class="text-left">{{item.Rent}}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="selectedMonthlyBooths.length == 0">
                            <tr><td scope="row" colspan="5">No records found</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-left">
                <label class="fw-700">Other Rentals Bookings:</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-left">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" class="right-border text-left" style="width: 10rem;">Booking Details</th>
                                <th scope="col" class="border text-left" style="width: 10rem;">Renew Bookings</th>
                                <th scope="col" class="border text-left" style="width: 13rem;">Renewal Start Month</th>
                                <th scope="col" class="border text-center" style="width: 13rem;">Renewal End Month</th>
                                <th scope="col" class="border text-left" style="width: 8rem;">Charge</th>
                                <th scope="col" class="border text-left" style="width: 8rem;">Qty</th>
                                <th scope="col" class="left-border text-left" style="width: 5rem;">Total</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="selectedOtherItems.length > 0">
                            <tr *ngFor="let item of selectedOtherItems; let i = index">
                                <td scope="row">
                                    Product: {{item.OtherItem}}<br />
                                    Status : <span style="color: red">Booked</span><br />
                                    <a (click)="toggleOtherItemsDetails(i)" class="showDetails">
                                        <img [src]="item.showOtherItemsDetails ? '../assets/images/minus.jpg' : '../assets/images/plus.jpg'" style="width: 15%;">
                                        {{ item.showOtherItemsDetails ? 'Hide Details' : 'Show Details' }}
                                    </a>
                                    <div *ngIf="item.showOtherItemsDetails">
                                        Booking Start Month : {{item.CurrentRentalMonth}}<br />
                                        Rent Charged : {{item.CurrentOtherItemtotal}}<br />
                                        Sales Tax : {{item.CurrentSalesTax}}<br />
                                        {{secondTaxName}}: {{item.CurrentSecLevelTax}}<br />                                       
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="checkbox-wrapper mt-2">
                                        <label class="pure-material-checkbox">
                                            <input type="checkbox" class="CheckboxClass" [(ngModel)]="item.Recurring"
                                            [ngModelOptions]="{standalone: true}">
                                            <span></span>
                                        </label>
                                    </div>
                                </td>
                                <td class="text-left">
                                    {{item.RenewalStartMonth}}
                                </td>
                                <td class="text-left"><select class="form-control" [(ngModel)]="item.MonthlyRenewalMonth"
                                        [ngModelOptions]="{standalone: true}" (change)="extendMonthlyOtherRental(item)" [disabled]="!item.BookingCanExtend">
                                        <ng-container *ngFor="let marketDay of monthlyMarketDays; let i = index">
                                            <option *ngIf="i > item.RentEndPeriodIndex" [ngValue]="marketDay.RentalMonths">
                                                {{ marketDay.RentalMonths }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </td>
                                <td class="text-left"> {{item.OtherItemCharge}}</td>
                                <td class="text-left"> {{item.Quantity}}</td>
                                <td class="text-left"> {{item.OtherItemtotal}}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="selectedOtherItems.length == 0">
                            <tr><td scope="row" colspan="7">No records found</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="rentalType == 3">
        <div class="row">
            <div class="col-12 text-left mt-2">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" class="right-border text-left" style="width: 10rem;">Booking Details</th>
                                <th scope="col" class="border text-left" style="width: 10rem;">Product</th>
                                <th scope="col" class="border text-left" style="width: 10rem;">Renew Booking</th>
                                <th scope="col" class="border text-left" style="width: 13rem;">Renewal Start Week</th>
                                <th scope="col" class="border text-left" style="width: 13rem;">Renewal End Week</th>
                                <th scope="col" class="border text-left" style="width: 8rem;">Week Day</th>
                                <th scope="col" class="left-border text-left" style="width: 8rem;">Rent</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="selectedWeeklyBooths.length > 0">
                            <tr *ngFor="let item of selectedWeeklyBooths; let i = index">
                                <td scope="row">
                                    Booth # : {{item.BoothNumber}}<br />
                                    Status : <span style="color: red">{{item.Status == 'R'? 'Reserved' : 'Booked'}}</span><br />
                                    <a (click)="toggleWeeklyBookingDetails(i)" class="showDetails">
                                        <img [src]="item.showDetails ? '../assets/images/minus.jpg' : '../assets/images/plus.jpg'"
                                            style="width: 10%;"> {{ item.showDetails ? 'Hide Details' : 'Show Details' }}
                                    </a>
                                    <div *ngIf="item.showDetails">
                                        Product : {{item.ProductName}}<br />
                                        Rent Charged :{{item.CurrentRent}}<br />
                                        Sales Tax : {{item.CurrentSalesTax}}<br />
                                        {{secondTaxName}} : {{item.CurrentSecondTax}}<br />
                                        Booking Start Week : {{item.CurrentRentStartPeriod}}<br />
                                        Booking End Week : {{item.CurrentRentEndPeriod}}<br />
                                    </div>
                                </td>
                                <td class="text-left">{{item.ProductName}}
                                <td class="text-center">
                                    <div class="checkbox-wrapper mt-2">
                                        <label class="pure-material-checkbox">
                                            <input type="checkbox" class="CheckboxClass" [(ngModel)]="item.Reservation"
                                                [ngModelOptions]="{standalone: true}" (change)="updateWeeklyRentalRecord(item)">
                                            <span></span>
                                        </label>
                                    </div>
                                </td>
                                <td class="text-left">{{item.RenewalStartWeek}}
                                </td>
                                <td class="text-left"><select class="form-control" [(ngModel)]="item.CWRenewalWeek"
                                        [ngModelOptions]="{standalone: true}" (change)="extendWeeklyRental(item)"
                                        [disabled]="!item.BookingCanExtend">
                                        <ng-container *ngFor="let marketDay of weeklyMarketDays; let i = index">
                                            <option *ngIf="i > item.RentEndPeriodIndex" [ngValue]="marketDay.RentalEndDays">
                                                {{ marketDay.RentalEndDays }}
                                            </option>
                                        </ng-container>
                                    </select></td>
                                <td class="text-left">{{item.WeekDay}}</td>
                                <td class="text-left">{{item.Rent}}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="selectedWeeklyBooths.length == 0">
                            <tr><td scope="row" colspan="7">No records found</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12 text-left">
                <label class="fw-700">Other Rentals Bookings:</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-left">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" class="right-border text-left" style="width: 10rem;">Booking Details</th>
                                <th scope="col" class="border text-left" style="width: 10rem;">Renew Booking</th>
                                <th scope="col" class="border text-left" style="width: 13rem;">Renewal Start Week</th>
                                <th scope="col" class="border text-left" style="width: 13rem;">Renewal End Week</th>
                                <th scope="col" class="border text-left" style="width: 8rem;">Charge</th>
                                <th scope="col" class="border text-left" style="width: 8rem;">Qty</th>
                                <th scope="col" class="left-border text-left" style="width: 5rem;">Total</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="selectedWeeklyBooths.length > 0">
                            <tr *ngFor="let item of selectedOtherItems; let i = index">
                                <td scope="row">
                                    Product: {{item.OtherItem}}<br />
                                    Status : <span style="color: red">Booked</span><br />
                                    <a (click)="toggleOtherItemsDetails(i)" class="showDetails">
                                        <img [src]="item.showOtherItemsDetails ? '../assets/images/minus.jpg' : '../assets/images/plus.jpg'" style="width: 15%;">
                                        {{ item.showOtherItemsDetails ? 'Hide Details' : 'Show Details' }}
                                    </a>
                                    <div *ngIf="item.showOtherItemsDetails">
                                        Booking Start Week : {{item.CurrentRentalWeek}}<br />
                                        Rent Charged : {{item.CurrentOtherItemtotal}}<br />
                                        Sales Tax : {{item.CurrentSalesTax}}<br />
                                        {{secondTaxName}} : {{item.CurrentSecLevelTax}}<br />
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="checkbox-wrapper mt-2">
                                        <label class="pure-material-checkbox">
                                            <input type="checkbox" class="CheckboxClass" [(ngModel)]="item.Recurring"
                                            [ngModelOptions]="{standalone: true}" (change)="updateWeeklyOtherItemRecord(item)">
                                            <span></span>
                                        </label>
                                    </div>
                                </td>
                                <td class="text-left">{{item.RenewalStartWeek}}
                                </td>
                                <td class="text-left"><select class="form-control" [(ngModel)]="item.CWRenewalWeek"
                                    [ngModelOptions]="{standalone: true}" (change)="extendCWOtherRental(item)" [disabled]="!item.BookingCanExtend">
                                    <ng-container *ngFor="let marketDay of weeklyMarketDays; let i = index">
                                        <option *ngIf="i > item.RentEndPeriodIndex" [ngValue]="marketDay.RentalEndDays">
                                            {{ marketDay.RentalEndDays}}
                                        </option>
                                    </ng-container>
                                </select>
                                </td>
                                <td class="text-left">{{item.OtherItemCharge}}</td>
                                <td class="text-left">{{item.Quantity}}</td>
                                <td class="text-left">{{item.OtherItemtotal}}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="selectedOtherItems.length == 0">
                            <tr><td scope="row" colspan="7">No records found</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-2">
            <label class="mb-0">Prior Balance</label>
            <input type="text" class="form-control text-right" formControlName="priorBalance" readonly>
            <br />
            <label class="mb-0">Rental Charge</label>
            <input type="text" class="form-control text-right" formControlName="rentalCharge" readonly>
            <br />
            <label class="mb-0">Other Charges</label>
            <input type="text" class="form-control text-right" formControlName="otherCharge" readonly>
            <br />
            <label class="mb-0">Discount</label>
            <input type="text" class="form-control text-right" formControlName="discount" readonly>
            <br />
            <label class="mb-0">Sales Tax</label>
            <input type="text" class="form-control text-right" formControlName="totalSalesTax" readonly>
            <br />
            <label class="mb-0">{{secondTaxName}}</label>
            <input type="text" class="form-control text-right" formControlName="totalSecondLeveltax" readonly>
            <br />
            <label class="mb-0">Additional Charges</label>
            <input type="text" class="form-control d-inline text-right" style="width: 80%;" formControlName="additionalCharge">
            &nbsp;&nbsp;<a class="rules d-inline" style="text-decoration: underline !important;">View</a>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-2">
            <label class="mb-0">Total Charge:</label>
            <input type="text" class="form-control text-right" formControlName="totalCharge" readonly>
            <br />
            <label class="mb-0">Payment Amount</label>
            <input type="text" class="form-control text-right" formControlName="paymentAmount"
            (keypress)="numberOnly($event)" 
            (input)="setCurrencyFormat(this.bookingDetailsForm.get('paymentAmount'))"
            (blur)="setMinimum(this.bookingDetailsForm.get('paymentAmount'))"
            (click)="handleClick($event)" (dblclick)="handledblClick($event)"
            (change)="setMinimum(this.bookingDetailsForm.get('paymentAmount'))">
            <br />
            <label class="mb-0">CC Convenience Fee</label>
            <input type="text" class="form-control text-right" formControlName="convenienceFee" readonly>
            <br />
            <label class="mb-0">Total Payment</label>
            <input type="text" class="form-control text-right fw-700" formControlName="totalPayment" readonly>
            <br />
            <label class="mb-0">Balance due</label>
            <input type="text" class="form-control text-right" formControlName="balanceDue" readonly>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left"></div>
    </div>

    <div *ngFor="let rule of rulesFormArray; let i = index">
        <div class="row">
            <div class="col-12 text-left">
                <div>
                    <label class="mb-0">
                        <input type="checkbox" class="CheckboxClass mt-3" [formControlName]="isChecked"
                            (change)="updateArrayDirectly(i,$event)">
                        <label class="chkAlign mb-0">{{rule.rulesAndRegualtions}}</label>
                    </label>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="rule.AgreementLinkRequired">
            <div class="col-12 text-left mt-1">
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS4xMjQiIGhlaWdodD0iMTUuODQzIiB2aWV3Qm94PSIwIDAgMjEuMTI0IDE1Ljg0MyI+CiAgPHBhdGggaWQ9Ikljb25fYXdlc29tZS1oYW5kLXBvaW50LXJpZ2h0IiBkYXRhLW5hbWU9Ikljb24gYXdlc29tZS1oYW5kLXBvaW50LXJpZ2h0IiBkPSJNMjEuMTI0LDEwLjFBMS44NiwxLjg2LDAsMCwxLDE5LjI3NiwxMS45aC00LjEyYTIuMDI5LDIuMDI5LDAsMCwxLS4yNiwyLjkyNywxLjg5LDEuODksMCwwLDEtLjY3OSwyLjU3MWMuMzcsMi4wMjctLjkwNSwyLjk0LTMsMi45NC0uMTEzLDAtLjU0OC0uMDA4LS42Ni0uMDA4LTIuNTU3LjAwNy0zLjE3Mi0xLjI4Mi01LjEtMS41ODFhLjk4OS45ODksMCwwLDEtLjgzNi0uOTc4VjEwLjdoMEExLjk4LDEuOTgsMCwwLDEsNS44LDguODljMS4xOTItLjUzNiwzLjkzNy0yLjAyMyw0LjQzNy0zLjE5YTEuNzU5LDEuNzU5LDAsMCwxLDEuNjUtMS4yQTEuOTgyLDEuOTgyLDAsMCwxLDEzLjcsNy4yNmE1LjYwOCw1LjYwOCwwLDAsMS0uNTg0LDEuMDI4aDYuMTU3QTEuODksMS44OSwwLDAsMSwyMS4xMjQsMTAuMVptLTE3LjE2My4wMTR2Ny45MjJhLjk5Ljk5LDAsMCwxLS45OS45OUguOTlhLjk5Ljk5LDAsMCwxLS45OS0uOTlWMTAuMTExYS45OS45OSwwLDAsMSwuOTktLjk5aDEuOThBLjk5Ljk5LDAsMCwxLDMuOTYxLDEwLjExMVpNMi44MDYsMTcuMDQyYS44MjUuODI1LDAsMSwwLS44MjUuODI1QS44MjUuODI1LDAsMCwwLDIuODA2LDE3LjA0MloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTQuNSkiIGZpbGw9IiMwNzI4ZmYiLz4KPC9zdmc+Cg=="
                    class="img-fluid" />
                &nbsp;<a [href]="rule.AgreementBlobURL" target="_blank" class="rules">{{rule.AgreementLinkTextContent}}</a>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 text-right mt-3 mb-3">
            <button class="btn btn-outline-primary mr-1">Cancel</button>
            <button class="btn btn-primary" (click)="saveRentalWithPayment()" [disabled]="disableSavePayment() || !allCheckboxesCheckedValidator()">Submit</button>
        </div>
    </div>
</form>

<div *ngIf="processPayment">
    <app-payment [VendorId]="vendorId" [keyedInTransaction]="keyedInTransaction" [MarketId]="marketId"
        [CCAmount]="paymentAmount" [TransactionType]="transactionType" [voidPayment]="voidPayment"
        (PaymentResponse)="PaymentResponse($event)" [VerifoneId]="verifoneId"
        (ClosePaymentPage)="ClosePaymentPage($event)"></app-payment>
</div>

<div *ngIf="openConfirmationPage">
    <app-rental-confirmation [VendorId]="vendorId" [AccountReceipId]="accountReceipId" [RentalType]="this.rentalType"></app-rental-confirmation>
</div>

<div class="modal fade bd-example-modal-xl" #modalResaleNo id="modalResaleNo" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header modal-headerNewBooths">
                <h5 class="modal-title" id="exampleModalCenterTitle" i18n>Resale Number</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="closeResaleModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                        <div class="checkbox-wrapper mt-2">
                            <label class="pure-material-checkbox">Resale Number is required! Please select one of the
                                following options.</label>
                        </div>
                        <hr class="my-3">
                        <div class="checkbox-wrapper mt-2">
                            <div class="md-radio col-md-12 col-8">
                                <input type="radio" id="resaleNo1" name="resaleOptions" value="1"
                                    [(ngModel)]="resaleNoOption" (change)="resaleOptionValidation()">
                                <label for="resaleNo1" i18n>Proceed without resale number.</label>
                            </div>
                        </div>
                        <div class="checkbox-wrapper mt-2">
                            <div class="md-radio col-md-12 col-8">
                                <input type="radio" id="resaleNo2" name="resaleOptions" value="2"
                                    [(ngModel)]="resaleNoOption" (change)="resaleOptionValidation()">
                                <label for="resaleNo2" i18n>Exempt from resale number.</label>
                            </div>
                            <div class="col-md-12 col-8 mt-2">
                                <label for="reasonInput">Reason</label>
                                <input type="text" id="reasonInput" class="form-control"
                                    [(ngModel)]="exemptResaleReason" [disabled]="resaleNoOption != '2'" />
                            </div>
                        </div>
                        <div class="checkbox-wrapper mt-2">
                            <div class="md-radio col-md-12 col-8">
                                <input type="radio" id="resaleNo3" name="resaleOptions" value="3"
                                    [(ngModel)]="resaleNoOption" (change)="resaleOptionValidation()">
                                <label for="resaleNo3" i18n>Enter resale number now.</label>
                            </div>
                            <div class="col-md-12 col-8 mt-2">
                                <label for="resaleNumberInput">Resale Number</label>
                                <input type="text" id="resaleNumberInput" class="form-control" [(ngModel)]="resaleNo"
                                    [disabled]="resaleNoOption != '3'" maxlength="30" />
                            </div>
                        </div>
                        <div class="checkbox-wrapper mt-2">
                            <div class="md-radio col-md-12 col-8">
                                <input type="radio" id="resaleNo4" name="resaleOptions" value="4"
                                    [(ngModel)]="resaleNoOption" (change)="resaleOptionValidation()">
                                <label for="resaleNo4" i18n>Do not proceed.</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer d-block pt-1 pb-2">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right mt-1">
                            <button class="btn btn-primary mr-1" data-dismiss="modal" aria-label="Close" i18n>OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>