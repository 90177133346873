<div id="main">
    <div class="container">
        <div class="container" style="background-color: white">
            <div class="row mt-4">
                <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-xs-12"></div>
                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 shadow text-center" style="background-color: white">
                    <img [src]="imageUrl" class="img-fluid" style="height: 95px;" /><br />
                    <span class="clsThankU">Thank You!</span><br />
                    <img src="../assets/images/SuccessTick.png" class="img-fluid" style="height: 95px;" />
                    <br />
                    <br />
                    <br />
                    <div class="text-left mt-2 mb-3 thankyouContent">
                        <span class="ThankyouText">Dear {{VendorName}},</span><br /><br />
                        <span class="ThankyouText">{{MarketName}} would like to thank you for being one of our highly valued
                            customers.</span><br /><br />
                        <span class="ThankyouText">We have received your online booking request, and as a confirmation of this online booking, 
                            we will be sending you an email enclosing your booking summary. Please review it and feel free to contact {{MarketName}} for your questions.</span><br /><br />
                        <span class="ThankyouText">Your online payment authorization number is {{AuthCode}}, and account receipt number is
                            {{MarketAccountReceiptId}}. Please print this page for your reference.</span>
                        <br />
                        <br />
                    </div>
                
                    <div class="row">
                        <div class="col-12 text-left mt-2 mb-3 thankyouContent text-center">
                            <a class="printReceiptText" (click)="PreviewRentalReceipt()"><b>Open Rental Receipt</b></a>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-12 text-left mt-2 mb-3 thankyouContent">
                            <span class="ThankyouText">Thank you,</span><br />
                            <span class="ThankyouText">{{MarketName}}</span><br /><br />
                        </div>
                    </div>
                
                    <div class="row">
                        <div class="col-6 text-right mt-2 mb-3">
                            <button class="btn btn-primary btnThankyouPage" style="width: 90px;"
                                (click)="goBack()">Back</button>
                        </div>
                        <div class="col-6 text-left mt-2 mb-3">
                            <button class="btn btn-primary btnThankyouPage" style="width: 90px;"
                                (click)="printRentalReceipt()">Print</button>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    </div>
</div>

<div bsModal #printReceiptPopup="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-sizes-name1">
    <div class="modal-dialog modal-dialog-centered modal-fullSize  modal-xl modal-xxl">
        <div class="modal-content" style="margin-top: 3rem;">
            <div class="modal-header">
                <h5 class="modal-title">Preview Receipt</h5>
                <button type="button" class="close" (click)="printReceipt.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body previewModal" style="max-height:76vh ;">
                <div>
                    <iframe id="printf" #printf name="printf" style="width:100%; height:73.5vh">
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</div>