import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})

export class NewsService {
    private baseURL: string;
    constructor(private http: HttpClient,
    ) {
        this.baseURL = environment.apiBaseUrl;
    }

    public options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };


    getActiveNews(MarketId: any): Observable<any[]> {
        console.log("MarketId: ", MarketId);
        return this.http.get<any[]>(
            `${this.baseURL}/News/GetAllActiveNews?MarketId=` + MarketId,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const result = res.body as any[];
                    return result;
                }),
                catchError(this.handleError)
            );
    }


    private handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            return throwError(errMessage);
        }
        return throwError(error || 'Node.js server error');
    }
}