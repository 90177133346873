<app-loader *ngIf="loaderIcon | async"></app-loader>
<div id="ContainerMain" class="container dashBoardMainClass divVendorsPayment">
    <div class="container dashBoardMainClass paymentClass" style="min-height: 0px;">
        <div class="third_party_payment" *ngIf="showIFrame">
            <!--iFrame-->
            <iframe width="100%" #CPNIFrame id="CPNIFrame" height="90%" frameBorder="0" [src]="urlSafe"
                (load)="getHiddenInput()"></iframe>
            <div
                [ngClass]="customerInfoPresent ? 'row my-3 mr-5 ml-5 autopay-footer' : 'row my-3 justify-content-end mx-0'">
                <label class="pure-material-checkbox float-left" *ngIf="customerInfoPresent">
                    <input type="checkbox" class="checkBoxClass" [(ngModel)]="isAutoPopulateBillingInfo"
                        (change)="onClickAutoPopulateBillingInfo(209)">
                    <span class="charitypay_text mt-0">Auto Populate Customer Information</span>
                </label>
                <div class="pay_rgt_butt">
                    <button type="button" class="btn btn-outline-primary"
                        (click)="onhstPayBtn(hstPayBtnMsg)">{{hstPayBtnMsg}}</button>
                </div>
            </div>
        </div>

        <div *ngIf="voidPayment">

            <!-- loader -->
            Refund Payment is processing
        </div>
    </div>

    <ng-template #paymentSuccess>
        <div class="modal-body">
            <div class="row mx-0">
                <div class="payment_successful_txt" *ngIf="paymentSuccessFul">
                    <app-success-tick></app-success-tick><span>{{payStatus}}!</span>
                </div>
                <div class="payment_failed_txt" *ngIf="!paymentSuccessFul">
                    <app-failure-cross></app-failure-cross><span>{{payStatus}}!</span>
                </div>
            </div>
            <div class="row mx-0 justify-content-between" *ngIf="paymentSuccessFul && showPrintEmailRcpt">
                <div class="confirmation_det" style="width: 100%;" *ngIf="isResponse">
                    <ul>
                        <li *ngIf="apiResponse.ReceiptId && apiResponse.ReceiptId !== 0">
                            <label class="font-weight-bold">Receipt#</label>
                            <div *ngIf="apiResponse.ReceiptId; else elseBlock"><span>{{apiResponse.ReceiptId}}</span></div>
                            <ng-template #elseBlock><span>{{apiResponse.Id}}</span></ng-template>
                        </li>
                        <li>
                            <label class="font-weight-bold">Amount</label>
                            <span>{{tillNowPaid | currency}}</span>
                        </li>
                        <li>
                            <label class="font-weight-bold">Date</label>
                            <span>{{apiResponse.date | date: 'MM/dd/yyyy hh:mm:ss a'}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row mt-4 pt-2 mx-0 text-center paymentMessage" *ngIf="paymentSuccessFul"><label class="w-100"><strong>Payment processed successfully!</strong></label></div>
            <div class="row mt-4 pt-2 mx-0 text-center paymentMessage" *ngIf="!paymentSuccessFul"><label class="w-100"><strong>Uh-oh! We
                        Were Unable To Process Your Payment.</strong></label></div>
            <div class="text-center paymentMessage" *ngIf="!paymentSuccessFul">
                <div class="payment_fail_reason">
                    <label class="font-weight-bold">{{paymentFailReason}}.</label>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" [disabled]="" (click)="afterpaymentSuccess()">OK</button>
        </div>
    </ng-template>
</div>