import { Component, HostListener, Renderer2 } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/User.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';

@Component({
  selector: 'app-vendor-login',
  templateUrl: './vendor-login.component.html',
  styleUrls: ['./vendor-login.component.scss']
})
export class VendorLoginComponent {
  loginForm: FormGroup;
  loginError=false;
  inActive = false;
  displayUsernameError = false;
  displayPasswordError = false;
  marketId
  confirmMailInput: HTMLInputElement
  imageUrl= "";
  private subscriptions: Subscription[] = [];
  toasterConfig = {
    closeButton: true,
    timeOut: 4500,
    easing: 'ease-out',
    progressBar: true,
    positionClass: 'toast-top-right',
    tapToDismiss: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'slideUp'
  };
  public loaderIcon: Promise<boolean>;

  constructor(private formBuilder: FormBuilder,
    private userService: UserService,
    private route: ActivatedRoute,
    private router:Router,
    private marketInfoService:MarketInfoService,
    private toastr: ToastrService,
    private renderer: Renderer2) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required],
      rememberMe:[false],
      loginUserName:["1"]
    });
   }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.validateLogin();
    }
  }

  ngOnInit(): void {
     this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
    });
    this.getMarketLogo();
    this.checkRememberMe();
    this.applyBackground();   
  //  window.addEventListener('resize', () => this.applyBackground());
  }

  getMarketLogo() {
    const timestamp = new Date().getTime();
    const sub = this.marketInfoService.getMarketLogo(this.marketId).subscribe((res: any) => {
      if (res) {
          this.imageUrl = res.MarketLogoBlobURL + `?timestamp=${timestamp}`;
      }
      else {
        this.imageUrl = '../../../assets/images/NoImage.jpg'
      }
    });
    this.subscriptions.push(sub);
  }

  private applyBackground(): void {
    if (window.innerWidth < 992) {
      this.renderer.setStyle(document.body, 'backgroundColor', '#508AE2');
    } else {
      const gradient = `linear-gradient(335deg, hsl(214deg 66% 55%) 0%, hsl(214deg 67% 56%) 5%, hsl(215deg 68% 57%) 10%, hsl(215deg 69% 58%) 14%, hsl(216deg 70% 59%) 19%, hsl(217deg 71% 60%) 24%, hsl(217deg 72% 61%) 29%, hsl(218deg 73% 62%) 33%, hsl(218deg 74% 63%) 38%, hsl(219deg 76% 64%) 43%, hsl(219deg 77% 65%) 48%, hsl(220deg 79% 66%) 52%, hsl(221deg 80% 67%) 57%, hsl(221deg 82% 68%) 62%, hsl(222deg 84% 69%) 67%, hsl(222deg 85% 70%) 71%, hsl(223deg 88% 71%) 76%, hsl(223deg 90% 72%) 81%, hsl(224deg 92% 73%) 86%, hsl(225deg 94% 74%) 90%, hsl(225deg 97% 75%) 95%, hsl(226deg 100% 76%) 100%)`;
    document.body.style.backgroundImage = gradient;
    }
  }

  checkRememberMe() {
    if (localStorage.getItem(`rememberMe_${this.marketId}`) === 'true') {
      const username = localStorage.getItem(`username_${this.marketId}`) || '';
      this.loginForm.get('username').setValue(username);
      this.loginForm.get('rememberMe').setValue(true);
    }
  }

  validateLoginCredentials(){
    if (!this.loginForm.valid) {
      this.displayUsernameError = this.loginForm.get('username').hasError('required');
      this.displayPasswordError = this.loginForm.get('password').hasError('required');
    }
    else{
      this.displayUsernameError = false;
      this.displayPasswordError = false;
    }
  }

  validateLogin() {
    this.loaderIcon = Promise.resolve(true);
    if (this.loginForm.valid) {
      this.loginError=false;
      const credentials =
      {
        username: this.loginForm.value['username'],
        password: this.loginForm.value['password'],
        EmailLogin: this.loginForm.value['loginUserName'] == "2" ? true : false,
        MarketId: this.marketId
      }
      this.userService.getToken(credentials).subscribe(async (response) => {
        if(response){
          if (!response.InActive) {
            this.loginError =false;
            const rememberMe = this.loginForm.value['rememberMe'];
            if (rememberMe) {
              localStorage.setItem(`rememberMe_${this.marketId}`, 'true');
              localStorage.setItem(`username_${this.marketId}`, this.loginForm.value['username']);
            } else {
              localStorage.removeItem(`rememberMe_${this.marketId}`);
              localStorage.removeItem(`username_${this.marketId}`);
            }
            const vendorId = response.UserId;
            sessionStorage.setItem('token', response.token);
            this.router.navigate(['/rent-online/'+this.marketId + '/' + vendorId]);
          }
          else {
            this.inActive = response.InActive;
          }
          this.loaderIcon = Promise.resolve(false);
        }
        else{
            this.loginError =true
            this.loaderIcon = Promise.resolve(false);
        }
      });
    }
    else{
      this.displayUsernameError = this.loginForm.get('username').hasError('required');
      this.displayPasswordError = this.loginForm.get('password').hasError('required');
      this.loaderIcon = Promise.resolve(false);
    }
  }

  ForgotPassword(event) {
    event.preventDefault();
    Swal.fire<boolean>({
      title: 'Confirm Mail',
      html: `
       <input type="text" id="email" class="swal2-input" style="width:82%" placeholder="Email">
       <small style="color : #106EBE !important">Your new login password will be sent to your registered email address. <i class="fa fa-warning " style="color: red"></i> Please check your email inbox!!</small>
     `,
      confirmButtonText: 'Confirm',
      focusConfirm: false,
      showCloseButton: true,
      didOpen: () => {
        const popup = Swal.getPopup()!;
        this.confirmMailInput = popup.querySelector('#email') as HTMLInputElement;
        this.confirmMailInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
      },
      preConfirm: () => {
        const confirmMail = this.confirmMailInput?.value
        if (!confirmMail) {
          Swal.showValidationMessage(`Please enter email address`);
          return false;
        }
        else {
          return true;
        }
      },
    }).then((result) => {
      if (result.value) {
        Swal.close();
        this.subscriptions.push(this.userService.ForgotPassword(this.marketId, this.confirmMailInput?.value).subscribe((res: any) => {
          var response = res.Success;
          if (response) {
            this.toastr.success(res.Message, $localize`Success`, this.toasterConfig);
          }
          else {
            this.toastr.error(res.Message, $localize`Error`, this.toasterConfig)
          }
        }));
      }
    });
  }

  ngOnDestroy(): void {
    document.body.style.backgroundImage = '';
    document.body.style.backgroundColor = '';
  }
}
