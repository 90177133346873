import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  DiagramComponent, NodeModel, NodeConstraints, MarginModel, ZoomOptions, ConnectorModel, SelectorConstraints,
  AnnotationConstraints, SelectorModel, PaletteModel,DiagramTooltipModel, ImageModel, DiagramTools, SnapConstraints , SnapSettingsModel, DiagramBeforeMenuOpenEventArgs, BasicShapeModel, PathAnnotationModel, TextModel
} from '@syncfusion/ej2-angular-diagrams';
import { Observable, Subscription, catchError, forkJoin, map, of } from 'rxjs';
import { RentalService } from 'src/app/services/Rental.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from 'src/app/Shared/SharedData.service';
import { DatePipe } from '@angular/common';
import { customCurrencyPipe } from 'src/app/Shared/customCurrency.pipe';
import Swal from 'sweetalert2';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';

interface DateString {
  startDate: string;
}

@Component({
  selector: 'app-rentallayout-online',
  templateUrl: './rentallayout-online.component.html',
  styleUrls: ['./rentallayout-online.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RentalLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  public loaderIcon: Promise<boolean>;
  @ViewChild('diagram', { static: false })
  public diagram: DiagramComponent;
  private subscriptions: Subscription[] = [];
  layoutForm: FormGroup;
  defaultZoomFactor= 0;
  @Input() marketId;
  @Input() layoutParts : any;
  @Input() rentalType;
  @Input() LayoutData;
  @Output() selectedBoothsFromLayout = new EventEmitter<any>();
  @Output() CloseLayout = new EventEmitter<any>();
  @Output() removeBoothCharges = new EventEmitter<any>();
  allExistingBuilding = [];
  boothShapes;
  fontName;
  fontStyle=[];
  layoutImages;
  images =[];
  selectedNode=[];
  selectedBoothData=[];
  allBooths=[];
  partId;
  isDropdownOpen = false;
  public palettes?: PaletteModel[];
  connectors: ConnectorModel[];
  selectedItems: SelectorModel;
  symbolMargin: MarginModel;
  private monthEndDateCache = new Map<string, Date>();
  noboothSelected = true
  public snapSettings: SnapSettingsModel = {
  constraints: SnapConstraints.None, 
  };
  tool: DiagramTools;
  vendorId;
  BoothNumber;
  BoothType;
  blinkingNode: string = '';
  categoryLegend = [];
  blinkInterval: any;
  selectedPartName= "";
  toasterConfig = {
    closeButton: true,
    timeOut: 4500,
    easing: 'ease-out',
    progressBar: true,
    positionClass: 'toast-top-right',
    tapToDismiss: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'slideUp'
  };
  selectedBoothsForRental = [];
  public tooltip?: DiagramTooltipModel;
  constructor(private formbuilder: FormBuilder,
    private route:ActivatedRoute,
    private toastr: ToastrService,
    private sharedDataService:SharedDataService,
    private rentalService:RentalService,
    private datePipe: DatePipe,
    private customCurrency: customCurrencyPipe,
    private marketInfoService:MarketInfoService,
    private elementRef: ElementRef) {
    this.layoutForm = this.formbuilder.group({
      layoutDiagramFilter: this.formbuilder.group({
        searchBooth: ['']
      })
    });
    this.tool = DiagramTools.SingleSelect;
    this.selectedItems = {
      constraints: SelectorConstraints.All &
        ~(SelectorConstraints.ResizeAll | SelectorConstraints.Rotate)
    };
    this.symbolMargin = { left: 15, right: 15, top: 15, bottom: 15 };
  }    

  @HostListener('document:click', ['$event'])
  clickOutsideDropdown(event: MouseEvent): void {
    const legendDropDown = document.querySelector('.legendDropDown');
    const dropdownElement = document.querySelector('.custom-dropdown');
    if (!legendDropDown.contains(event.target as Node) && !dropdownElement.contains(event.target as Node) && ((document.querySelector('.legendDropDown') as HTMLElement).classList.contains('open'))) {
      this.isDropdownOpen = false;
    }
  }

  closeCategoryLegend(){
    this.isDropdownOpen = false;
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  toggleDropdown() {
   if(!this.isDropdownOpen)
   (document.querySelector('.legendDropDown') as HTMLElement).classList.add('open');
  else
   (document.querySelector('.legendDropDown') as HTMLElement).classList.remove('open');
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  
  ngOnInit(): void {
    console.log('inside layout')
    this.route.params.subscribe((params: Params) => {
      this.vendorId = params['id'];
    }); 
    this.partId = this.layoutParts;
    this.getParts();
    this.getProductCategories();
    this.getListOfValuesData();
    this.getImages();  
  }

  ngAfterViewInit() {
  }

  getParts() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.marketInfoService.getParts(this.marketId).subscribe((res: any) => {
      if (res) {
        this.selectedPartName = this.selectedPartName = res.filter(e=>e.PartId ==  this.partId)[0].PartDescription;
        this.loaderIcon = Promise.resolve(false);
      }
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  AddSelectedBoothsToGrid(){
    this.selectedBoothsFromLayout.emit(this.selectedBoothData);
  }

  closeLayoutForm() {
    if (this.rentalType == "D") {
      if (this.selectedBoothsForRental.length > 0) {
        this.selectedBoothsForRental.forEach(ele => {
          this.sharedDataService.dailyRentalSelectedDays.map(date => {
            const temp = {
              TempRecordNo: this.sharedDataService.tempRecordNo.toString(),
              BoothNumber: ele.BoothNumber,
              StartDate: new Date(date),
              RentalType: 1
            }
            this.subscriptions.push(
              this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
              })
            );
          });
        });
      }
    }
    else if (this.rentalType == "M") {
      if (this.selectedBoothsForRental.length > 0) {
        this.selectedBoothsForRental.forEach(ele => {
          const temp = {
            TempRecordNo: this.LayoutData.tempRecordNo.toString(),
            BoothNumber: ele.BoothNumber,
            StartDate: new Date(ele.StartDate),
            RentalType: 2,
            EndDate: new Date(ele.EndDate),
            Sunday: ele.Sunday,
            Monday: ele.Monday,
            Tuesday: ele.Tuesday,
            Wednesday: ele.Wednesday,
            Thursday: ele.Thursday,
            Friday: ele.Friday,
            Saturday: ele.Saturday,
          }
          this.subscriptions.push(
            this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
            })
          );
        });
      }
    }
    else if (this.rentalType == "CM") {
      if (this.selectedBoothsForRental.length > 0) {
        this.selectedBoothsForRental.forEach(ele => {
          const temp = {
            TempRecordNo: this.LayoutData.tempRecordNo.toString(),
            BoothNumber: ele.BoothNumber,
            StartDate: new Date(ele.StartDate),
            RentalType: 4,
            EndDate: new Date(ele.EndDate),
            Sunday: ele.Sunday,
            Monday: ele.Monday,
            Tuesday: ele.Tuesday,
            Wednesday: ele.Wednesday,
            Thursday: ele.Thursday,
            Friday: ele.Friday,
            Saturday: ele.Saturday,
          }
          this.subscriptions.push(
            this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
            })
          );
        });
      }
    }
    else if (this.rentalType == "CW") {
      if (this.selectedBoothsForRental.length > 0) {
        this.selectedBoothsForRental.forEach(ele => {
          const temp = {
            TempRecordNo: this.LayoutData.tempRecordNo.toString(),
            BoothNumber: ele.BoothNumber,
            StartDate: new Date(ele.StartDate),
            RentalType: 5,
            EndDate: new Date(ele.EndDate),
            Sunday: ele.Sunday,
            Monday: ele.Monday,
            Tuesday: ele.Tuesday,
            Wednesday: ele.Wednesday,
            Thursday: ele.Thursday,
            Friday: ele.Friday,
            Saturday: ele.Saturday,
          }
          this.subscriptions.push(
            this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
            })
          );
        });
      }
    }
    else if (this.rentalType == "W") {
      if (this.selectedBoothsForRental.length > 0) {
        this.selectedBoothsForRental.forEach(ele => {
          const temp = {
            TempRecordNo: this.LayoutData.tempRecordNo.toString(),
            BoothNumber: ele.BoothNumber,
            StartDate: new Date(ele.StartDate),
            RentalType: 5,
            EndDate: new Date(ele.EndDate),
            Sunday: ele.Sunday,
            Monday: ele.Monday,
            Tuesday: ele.Tuesday,
            Wednesday: ele.Wednesday,
            Thursday: ele.Thursday,
            Friday: ele.Friday,
            Saturday: ele.Saturday,
          }
          this.subscriptions.push(
            this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
            })
          );
        });
      }
    }

    if (this.selectedBoothsForRental.length > 0) {
      setTimeout(() => {
        this.CloseLayout.emit();
      }, 400);
    } else {
      this.CloseLayout.emit();
    }
  }

  public created(args: Object): void {
   // fit the diagram to the page with respect to mode and region
     this.diagram.fitToPage({
         mode: 'Page',
         region: 'Content',
        margin: {
             bottom: 50
         },
         canZoomIn: true
     });
}

  markSelectedBooths() {
    if (this.rentalType == "D") {
      if (this.sharedDataService.dailyRentalSelectedBooths.length > 0) {
        this.sharedDataService.dailyRentalSelectedDays.forEach(date => {
          this.sharedDataService.dailyRentalSelectedBooths.forEach(element => {
            if (this.datePipe.transform(date, 'MM/dd/yyyy') == element.RentalDay) {
              const nodeId = "node_" + element.BoothNumber;
              const selectedNode = this.diagram.getObject(nodeId) as NodeModel;
              if (selectedNode) {
                const diagramNode: NodeModel = {
                  id: nodeId.replace("&","_amper_") + "mark",
                  offsetX: selectedNode.offsetX,
                  offsetY: selectedNode.offsetY,
                  width: selectedNode.width / 2,
                  height: selectedNode.height / 2,
                  shape: {
                    type: "HTML",
                    content: `<div style="width:${selectedNode.width/2}px; height: ${selectedNode.height/2}px">
                    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style="display: block; margin: 0; padding: 0;">
                        <line x1="0" y1="0" x2="100%" y2="100%" stroke="black" stroke-width="2"/>
                        <line x1="100%" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2"/>
                    </svg>
                </div>`
                  },
                  constraints: NodeConstraints.Default & ~(NodeConstraints.Drag),
                };
                this.diagram.add(diagramNode);
                var selectedBoothData = this.allBooths.filter(e => e.BoothNumber == element.BoothNumber);
                this.selectedBoothData.push(
                  {
                    BoothNumber: selectedBoothData[0].BoothNumber,
                    BoothType: selectedBoothData[0].BoothType,
                    BoothDescription: selectedBoothData[0].BoothDescription,
                    BoothTypeId: selectedBoothData[0].BoothTypeId,
                  });
              }
              else{
                var selectedBoothData = this.allBooths.filter(e => e.BoothNumber == element.BoothNumber);
                this.selectedBoothData.push(
                  {
                    BoothNumber:element.BoothNumber,
                    BoothType: element.BoothType,
                    BoothDescription: selectedBoothData[0].BoothDescription,
                    BoothTypeId: element.BoothTypeId,
                  });
              }
            }
          });
        });
      }
      this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
    }
    else if (this.rentalType == "W" || this.rentalType == "CW") {
      if (this.LayoutData.weeklyRentalSelectedBooths.length > 0) {
        this.LayoutData.weeklyRentalSelectedBooths.forEach(element => {
          this.LayoutData.weeklyRentalMarketDays.forEach(days => {
            const selectedDates = [];
            this.LayoutData.weeklyRentalMarketDays.map((el, i) => {
              const startDate = new Date(element.RentStartPeriod.split('-')[0]);
              const endDate = new Date(element.RentEndPeriod.split('-')[1]);
              const selectedDayOfWeek = el - 1;
              let currentDay = new Date(startDate);
              while (currentDay <= endDate) {
                if (currentDay.getDay() === selectedDayOfWeek) {
                  selectedDates.push(this.datePipe.transform(new Date(currentDay), 'MM/dd/yyyy'));
                }
                currentDay.setDate(currentDay.getDate() + 1);
              }

              if (selectedDates.some(element => this.LayoutData.selectedDates.includes(element))) {
                const nodeId = "node_" + element.BoothNumber;
                const selectedNode = this.diagram.getObject(nodeId) as NodeModel;
                if (selectedNode) {
                  const diagramNode: NodeModel = {
                    id: nodeId.replace("&","_amper_") + "mark",
                    offsetX: selectedNode.offsetX,
                    offsetY: selectedNode.offsetY,
                    width: selectedNode.width / 2,
                    height: selectedNode.height / 2,
                    shape: {
                      type: "HTML",
                      content: `<div style="width:${selectedNode.width/2}px; height: ${selectedNode.height/2}px">
                      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style="display: block; margin: 0; padding: 0;">
                          <line x1="0" y1="0" x2="100%" y2="100%" stroke="black" stroke-width="2"/>
                          <line x1="100%" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2"/>
                      </svg>
                  </div>`
                    },
                    constraints: NodeConstraints.Default & ~(NodeConstraints.Drag),
                  };
                  this.diagram.add(diagramNode);
                }
              }
            });
          });
        });
      }
    }
    else if (this.rentalType == "M" || this.rentalType == "CM") {
      if (this.LayoutData.monthlyRentalSelectedBooths.length > 0) {
        this.LayoutData.monthlyRentalSelectedBooths.forEach(element => {
          this.LayoutData.monthlyRentalMarketDays.forEach(days => {
            const selectedDates = [];
            this.LayoutData.monthlyRentalMarketDays.map((el, i) => {
              const startDate = new Date(element.RentalStartDate);
              const endDate = new Date(element.RentalEndDate);
              const selectedDayOfWeek = el - 1;
              let currentDay = new Date(startDate);
              while (currentDay <= endDate) {
                if (currentDay.getDay() === selectedDayOfWeek) {
                  selectedDates.push(this.datePipe.transform(new Date(currentDay), 'MM/dd/yyyy'));
                }
                currentDay.setDate(currentDay.getDate() + 1);
              }

              if (selectedDates.some(element => this.LayoutData.selectedDates.includes(element))) {
                const nodeId = "node_" + element.BoothNumber;
                const selectedNode = this.diagram.getObject(nodeId) as NodeModel;
                if (selectedNode) {
                  const diagramNode: NodeModel = {
                    id: nodeId.replace("&","_amper_") + "mark",
                    offsetX: selectedNode.offsetX,
                    offsetY: selectedNode.offsetY,
                    width: selectedNode.width / 2,
                    height: selectedNode.height / 2,
                    shape: {
                      type: "HTML",
                      content: `<div style="width:${selectedNode.width/2}px; height: ${selectedNode.height/2}px">
                      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style="display: block; margin: 0; padding: 0;">
                          <line x1="0" y1="0" x2="100%" y2="100%" stroke="black" stroke-width="2"/>
                          <line x1="100%" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2"/>
                      </svg>
                  </div>`
                    },
                    constraints: NodeConstraints.Default & ~(NodeConstraints.Drag),                    
                  };
                  this.diagram.add(diagramNode);
                }
              }
            });
          });
        });
      }
    }
  }

  getProductCategories() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.rentalService.getProductCategories(this.marketId).subscribe((res: any) => {
      if (res) {
        res.map((el, i) => {
          const ob = {
            categoryName: el.CategoryDescription,
            color: this.convertColor(el.Color)
          }
          this.categoryLegend.push(JSON.parse(JSON.stringify(ob)));
        })
      }
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  bindBoothsToLayout(data,boothStatusRecords) {
    this.loaderIcon = Promise.resolve(true);
    this.allBooths = data;
    if (data.length > 0) {
      for(const item of data){
        var boothStatus = "Booked";
        var backColor = "#fff"
        switch (item.Status.toLowerCase()) {
          case "r": boothStatus = "Reserved"
          backColor = '#0032CC';
            break;
          case "ro": boothStatus = "Reserved Only"
          backColor = '#483D8B';
            break;
          case "a": boothStatus = "Available"
          backColor = '#fff';
            break;
          case "ab": boothStatus = "Absentee Booking"
          backColor = '#4F9F60';
            break;
          default: boothStatus = "Booked"
          backColor = '#4F9F60';
            break;
        }
        if(item.ExcludeForWeb){
          backColor = '#ff007f';
          item.Status = "NR"
        }

        if (boothStatus == 'Closed Out' || (item.Status.toLowerCase() == 'd' && !item.AccountReceiptId)) {
          //Booth closeout - do nothing
        }
        else {
          const fontColor = item.Status.toLowerCase() == "r" ? "#fff" : "#000";
          var tooltipCaption;
          if (item.ExcludeForWeb) {
            tooltipCaption = "This booth is not available for online rental";
          }
          else {
            tooltipCaption = item.VendorId ? 'Vendor Id:' + item.MarketVendorId + ' Name: ' + item.VendorName + ' Type: ' + item.RentalType + ' Status: ' + boothStatus + ' Product: ' + item.ProductDescription + ' VendorBalance : ' + this.customCurrency.transform(((-1) * item.PriorBalance).toString()) + ' Booth Number: ' + item.BoothNumber + ' Booth Type: ' + item.BoothType + ' Booth Description: ' + item.BoothDescription : ' Booth Number: ' + item.BoothNumber + ' Booth Type: ' + item.BoothType + ' Booth Description: ' + item.BoothDescription;
          }
          const nodeId = 'node_' + item.BoothNumber;
          const diagramNode: NodeModel = {
            id: nodeId,
            shape: {
              type: 'Basic',
              shape: this.boothShapes.filter(e => e.Id == item.ShapeId)[0].Name
            },
            style: {
              fill: backColor,
              strokeColor: this.convertColor(item.BorderColor),
              color: fontColor,
              strokeWidth: 1,
              fontSize: item.FontSize,
              textWrapping: 'Wrap',
              fontFamily: this.fontName.filter(e => e.Id == item.FontName)[0].Name
            },
            annotations:
              [{
                content: item.BoothNumber,
                style: {
                  fontSize: item.FontSize,
                  color: fontColor,
                  fontFamily: this.fontName.filter(e => e.Id == item.FontName)[0].Name,
                  textWrapping: 'Wrap',
                  bold: this.fontStyle.filter(e => e.Id == item.FontStyle)[0].Name.toLowerCase().includes('bold'),
                  italic: this.fontStyle.filter(e => e.Id == item.FontStyle)[0].Name.toLowerCase().includes('italic')
                },

                constraints: AnnotationConstraints.ReadOnly
              }],
              tooltip: {
                content: tooltipCaption,
                position: 'TopCenter',
                relativeMode: 'Object',
              },
            width: item.Width * 3,
            height: item.Length * 3,
            addInfo: {
              allowDrag: true,
              name: 'booth',
              status: item.Status
            },
            constraints: NodeConstraints.Default & ~(NodeConstraints.Drag) | NodeConstraints.Tooltip,
            offsetX: item.LeftCoordinate,
            offsetY: item.TopCoordinate,
            rotateAngle: item.RotateAngle
          };
          this.diagram.add(diagramNode);
        }
      }
      this.diagram.fitToPage();
      if (this.defaultZoomFactor) {
        let zoomOptions: ZoomOptions = {
          type: 'ZoomIn',
          zoomFactor: this.defaultZoomFactor
        };
        this.diagram.zoomTo(zoomOptions);
      }
    }
    this.markSelectedBooths();
    this.loaderIcon = Promise.resolve(false);
  }


  getAllBoothsForLayout() {
    this.loaderIcon = Promise.resolve(true);
    if (this.rentalType == "D") {
      var obj;
      var rentalStartDate = this.sharedDataService.dailyRentalSelectedDays[0];
      var rentalDaysLength = this.sharedDataService.dailyRentalSelectedDays.length;
      const rentalStartDateYear = new Date(this.sharedDataService.dailyRentalSelectedDays[0]).getFullYear();
      const rentalStartDateMonth = new Date(this.sharedDataService.dailyRentalSelectedDays[0]).getMonth() + 1;
      const rentalStartDateYearMonth = `${rentalStartDateYear}${rentalStartDateMonth < 10 ? '0' : ''}${rentalStartDateMonth}`;
      var selectedMarketDays = [];
      rentalStartDate.getDay() == 0 ? selectedMarketDays.push(1) : 0;
      rentalStartDate.getDay() == 1 ? selectedMarketDays.push(2) : 0;
      rentalStartDate.getDay() == 2 ? selectedMarketDays.push(3) : 0;
      rentalStartDate.getDay() == 3 ? selectedMarketDays.push(4) : 0;
      rentalStartDate.getDay() == 4 ? selectedMarketDays.push(5) : 0;
      rentalStartDate.getDay() == 5 ? selectedMarketDays.push(6) : 0;
      rentalStartDate.getDay() == 6 ? selectedMarketDays.push(7) : 0;
      obj = {
        RentalStartDate: this.sharedDataService.dailyRentalSelectedDays[0],
        RentalEndDate: this.sharedDataService.dailyRentalSelectedDays[rentalDaysLength - 1],
        RentalYearMonth: rentalStartDateYearMonth,
        Monday: rentalStartDate.getDay() == 1 ? true : false,
        Tuesday: rentalStartDate.getDay() == 2 ? true : false,
        Wednesday: rentalStartDate.getDay() == 3 ? true : false,
        Thursday: rentalStartDate.getDay() == 4 ? true : false,
        Friday: rentalStartDate.getDay() == 5 ? true : false,
        Saturday: rentalStartDate.getDay() == 6 ? true : false,
        Sunday: rentalStartDate.getDay() == 0 ? true : false,
        MarketDays: selectedMarketDays.toString(),
        MarketId: this.marketId
      }
      forkJoin({
        boothsList: this.marketInfoService.getAllBoothsForDailyRentalTypeLayout(this.marketId, this.partId, this.sharedDataService.dailyRentalSelectedDays),
        boothStatus: this.rentalService.getBoothStatus(obj)
      }).subscribe(({ boothsList, boothStatus }) => {
        let data = boothsList;
        this.bindBoothsToLayout(data,boothStatus);
        this.loaderIcon = Promise.resolve(false);
      });
    }
    else if(this.rentalType == "W" || this.rentalType == "CW"){
      const subRentSettings = this.rentalService.getRentalSettings(this.marketId).subscribe((response: any) => {
        if (response) {
          const rentalStartDateYear = new Date(this.LayoutData.weeklyRentalStartDate.split('-')[0]).getFullYear();
          const rentalStartDateMonth = new Date(this.LayoutData.weeklyRentalStartDate.split('-')[0]).getMonth() + 1;
          const rentalEndDateYear = new Date(this.LayoutData.weeklyRentalEndDate.split('-')[1]).getFullYear();
          const rentalEndDateMonth = new Date(this.LayoutData.weeklyRentalEndDate.split('-')[1]).getMonth() + 1;
          const rentalStartDateYearMonth = `${rentalStartDateYear}${rentalStartDateMonth < 10 ? '0' : ''}${rentalStartDateMonth}`;
          const rentalEndDateYearMonth = `${rentalEndDateYear}${rentalEndDateMonth < 10 ? '0' : ''}${rentalEndDateMonth}`;
          var marketDays = '';
          this.LayoutData.weeklyRentalMarketDays.forEach(element => {
            marketDays = marketDays.concat(element) + ',';
          });
          var obj = {
            PartId: this.partId,
            RentalStartDate: new Date(this.LayoutData.weeklyRentalFirstDay),
            RentalEndDate: new Date(this.LayoutData.weeklyRentalEndDate.split('-')[1]),
            FirstDayOfRentalStartWeek: new Date(this.LayoutData.weeklyRentalStartDate.split('-')[0]),
            FirstDayOfRentalEndWeek: new Date(this.LayoutData.weeklyRentalEndDate.split('-')[0]),
            RentalStartDateYearMonth: rentalStartDateYearMonth,
            RentalEndDateYearMonth: rentalEndDateYearMonth,
            // SelectedRentalDates: ,
            // SelectedNumberOfRentalDates: ,
            MarketDays: marketDays.substring(0, marketDays.length - 1),
            WeeklyCombWeeklyReservationPeriod: response.WeeklyCombWeeklyReservationPeriod,
            MonthlyCombMonthlyReservationPeriod: response.MonthlyCombMonthlyReservationPeriod,
            RentalStartDateWeekDayNumber: new Date(this.LayoutData.weeklyRentalStartDate.split('-')[0]).getDay(),
            AllowVendorVacation: false,
            Monday: this.LayoutData.Monday ? true : false,
            Tuesday: this.LayoutData.Tuesday ? true : false,
            Wednesday: this.LayoutData.Wednesday ? true : false,
            Thursday: this.LayoutData.Thursday ? true : false,
            Friday: this.LayoutData.Friday ? true : false,
            Saturday: this.LayoutData.Saturday ? true : false,
            Sunday: this.LayoutData.Sunday ? true : false,
          }
          const sub = this.marketInfoService.getALLBoothsForLayoutALLRentalTypes(this.marketId, obj).subscribe((response) => {
            let data = response;
            this.bindBoothsToLayout(data,'');
            this.loaderIcon = Promise.resolve(false);
          }, err => {
            this.loaderIcon = Promise.resolve(false);
          });
          this.subscriptions.push(sub);
        }
      });
    }
    else if(this.rentalType == "M" || this.rentalType == "CM"){
      const subRentSettings = this.rentalService.getRentalSettings(this.marketId).subscribe((response: any) => {
        if (response) {         
          const rentalStartDateYear = new Date(this.LayoutData.monthlyRentalStartDate.split('-')[0]).getFullYear();
          const rentalStartDateMonth = new Date(this.LayoutData.monthlyRentalStartDate.split('-')[0]).getMonth() + 1;
          const rentalEndDateYear = new Date(this.LayoutData.monthlyRentalEndDate.split('-')[0]).getFullYear();
          const rentalEndDateMonth = new Date(this.LayoutData.monthlyRentalEndDate.split('-')[0]).getMonth() + 1;
          const rentalStartDateYearMonth = `${rentalStartDateYear}${rentalStartDateMonth < 10 ? '0' : ''}${rentalStartDateMonth}`;
          const rentalEndDateYearMonth = `${rentalEndDateYear}${rentalEndDateMonth < 10 ? '0' : ''}${rentalEndDateMonth}`;
          var marketDays = '';
          this.LayoutData.monthlyRentalMarketDays.forEach(element => {
            marketDays = marketDays.concat(element) + ',';
          });
          var obj = {
            PartId: this.partId,
            RentalStartDate:new Date(this.LayoutData.RentalStartDate),
            RentalEndDate:new Date(this.LayoutData.RentalEndDate),
            FirstDayOfRentalStartWeek:new Date(this.LayoutData.RentalStartDate),
            FirstDayOfRentalEndWeek: new Date(this.LayoutData.RentalEndDate),
            RentalStartDateYearMonth: rentalStartDateYearMonth,
            RentalEndDateYearMonth: rentalEndDateYearMonth,
            MarketDays: marketDays.substring(0, marketDays.length - 1),
            WeeklyCombWeeklyReservationPeriod: response.WeeklyCombWeeklyReservationPeriod,
            MonthlyCombMonthlyReservationPeriod: response.MonthlyCombMonthlyReservationPeriod,
            RentalStartDateWeekDayNumber: new Date(this.LayoutData.monthlyRentalStartDate.split('-')[0]).getDay(),
            AllowVendorVacation: false,
            Monday: this.LayoutData.Monday ? true : false,
            Tuesday: this.LayoutData.Tuesday ? true : false,
            Wednesday: this.LayoutData.Wednesday ? true : false,
            Thursday: this.LayoutData.Thursday ? true : false,
            Friday: this.LayoutData.Friday ? true : false,
            Saturday: this.LayoutData.Saturday ? true : false,
            Sunday: this.LayoutData.Sunday ? true : false,
          }
          const sub = this.marketInfoService.getALLBoothsForLayoutALLRentalTypes(this.marketId, obj).subscribe((response) => {
            let data = response;
            this.bindBoothsToLayout(data,'');
            this.loaderIcon = Promise.resolve(false);
          }, err => {
            this.loaderIcon = Promise.resolve(false);
          });
          this.subscriptions.push(sub);
        }
      });
    }
  }

  changeLayout() {
    this.diagram.clear();
    this.getBuildingForLayout();
    this.getImagesForLayout();
    this.getAllBoothsForLayout();
  }

  getListOfValuesData() {
    this.loaderIcon = Promise.resolve(true);
    forkJoin({
      res: this.marketInfoService.getListOfValuesData(),
      layoutFonts: this.marketInfoService.getLayoutFonts(this.marketId)
    }).subscribe(({ res, layoutFonts }) => {
      if (res) {
        this.boothShapes = res.BoothShapes;
        this.fontName = res.FontName;
        this.fontStyle = res.FontStyle;
      }      
      if(layoutFonts){
        this.defaultZoomFactor = layoutFonts.DefaultZoomFactor ?? 0;
      }
      this.getAllBoothsForLayout();
      this.getBuildingForLayout();
      this.getImagesForLayout();
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
  }

  convertColor(colorValue: number): string {
    if (colorValue == 255 || colorValue == 65280 || colorValue == 0)
      return '#' + colorValue.toString(16).padStart(6, '0');
    else
      return '#' + colorValue.toString(16);
  }  

  nodeMouseOver(event){
    if (event.target) {      
      const nodeId = event.target.id == ""? 'node_' + event.target.innerHTML : event.target.id.replace('_content', '');
      const selectedNode = this.diagram.getObject(nodeId) as NodeModel;
      if(selectedNode){
       var boothNo =  selectedNode.id.replace('node_','');
       var selectedBooth = this.allBooths.filter(e => e.BoothNumber == boothNo);
       this.BoothNumber = boothNo;
       this.BoothType = selectedBooth[0].BoothType;    
      }
      else{
        this.BoothNumber = "";
       this.BoothType = "";
      }
    }
  }

  nodeClick(event): void {
    var args;
    if (event.target) {
      const nodeId = event.target.id == "" ? 'node_' + event.target.innerHTML : event.target.id.replace('_content', '');
      args = this.diagram.getObject(nodeId.replace("&amp;","&")) as NodeModel;
    }
    else{
      const nodeId = event.element.properties.id;
      if (nodeId && nodeId.includes('mark')) {
        const mainNodeId = nodeId.replace('mark', '').replace("_amper_","&").replace("&amp;","&");
        args = this.diagram.getObject(mainNodeId) as NodeModel;
      }
    }
    if (args.properties.addInfo) {
      if (args.properties.addInfo.name == "booth" && args.properties.addInfo.status == "A") {
        this.rentalService.getRecordFromTemp().subscribe(async (response: any[]) => {
          const dataFromTemp = response;
          const nodeId = args.properties.id;
          var selectedBooth = this.allBooths.filter(e => e.BoothNumber == args.properties.annotations[0].properties.content);
          this.BoothNumber = args.properties.annotations[0].properties.content;
          this.BoothType = selectedBooth[0].BoothType;
          const selectedNode = this.diagram.getObject(nodeId) as NodeModel;
          var boothIndex = this.selectedBoothData.findIndex(e => e.BoothNumber == args.properties.annotations[0].properties.content);
          if (boothIndex == -1) {
            const diagramNode: NodeModel = {
              id: nodeId.replace("&","_amper_") + "mark",
              offsetX: selectedNode.offsetX,
              offsetY: selectedNode.offsetY,
              width: selectedNode.width / 2,
              height: selectedNode.height / 2,
              shape: {
                type: "HTML",              
                content: `<div style="width:${selectedNode.width/2}px; height: ${selectedNode.height/2}px">
                <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style="display: block; margin: 0; padding: 0;">
                    <line x1="0" y1="0" x2="100%" y2="100%" stroke="black" stroke-width="2"/>
                    <line x1="100%" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2"/>
                </svg>
            </div>`
              },
              constraints: NodeConstraints.Default & ~(NodeConstraints.Drag),
            };

            var boothSelectedByOtherUser = false;
            if (this.rentalType == "D") {
              if (dataFromTemp.length > 0) {
                this.sharedDataService.dailyRentalSelectedDays.forEach(date => {
                  var Sunday = new Date(date).getDay() == 0 ? true : false;
                  var Monday = new Date(date).getDay() == 1 ? true : false;
                  var Tuesday = new Date(date).getDay() == 2 ? true : false;
                  var Wednesday = new Date(date).getDay() == 3 ? true : false;
                  var Thursday = new Date(date).getDay() == 4 ? true : false;
                  var Friday = new Date(date).getDay() == 5 ? true : false;
                  var Saturday = new Date(date).getDay() == 6 ? true : false;
                  dataFromTemp.forEach(element => {
                    if (this.datePipe.transform(date, 'MM/dd/yyyy') >= this.datePipe.transform(element.StartDate, 'MM/dd/yyyy')
                      && this.datePipe.transform(date, 'MM/dd/yyyy') <= this.datePipe.transform(element.EndDate, 'MM/dd/yyyy') && element.TempRecordNo != this.sharedDataService.tempRecordNo
                    && (element.Sunday == Sunday || element.Monday == Monday || element.Tuesday == Tuesday
                      || element.Wednesday == Wednesday || element.Thursday == Thursday || element.Friday == Friday || element.Saturday == Saturday)) {
                      if (element.BoothNo == selectedBooth[0].BoothNumber) {
                        boothSelectedByOtherUser = true;
                      }
                    }
                  });
                });
              }
              if (boothSelectedByOtherUser) {
                Swal.fire({
                  text: $localize`This booth has been already selected by another user`,
                  icon: "warning",
                  confirmButtonText: $localize`Ok`,
                });
              } else {
                this.diagram.add(diagramNode);
              }
            }
            else if (this.rentalType == "M") {
              const rentalDates = [];
              var rentedBoothDates = [];
              var rentalstartDate;
              const startDate = new Date(this.LayoutData.RentalStartDate);
              const endMonth = this.LayoutData.monthlyRentalEndDate
              const [endMonthDate, endMonthYear] = endMonth.split(' ');
              const endMonthIndex = this.getMonthIndex(endMonthDate);
              const endDate = new Date(endMonthYear, endMonthIndex + 1, 0);
              this.LayoutData.monthlyRentalMarketDays.map((el, i) => {
                const selectedDayOfWeek = el - 1;

                let currentDay = new Date(startDate);
                while (currentDay <= endDate) {
                  if (currentDay.getDay() === selectedDayOfWeek) {
                    if (!rentalstartDate) {
                      rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                    }
                    marketDays += 1;
                    rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                  }
                  currentDay.setDate(currentDay.getDate() + 1);
                }
                if (dataFromTemp.length > 0) {
                  dataFromTemp.forEach(element => {
                    var dayOfWeek;
                    var selectedDaysOfWeek = [];
                    element.Sunday == true ? selectedDaysOfWeek.push(1) : 0;
                    element.Monday == true ? selectedDaysOfWeek.push(2) : 0;
                    element.Tuesday == true ? selectedDaysOfWeek.push(3) : 0;
                    element.Wednesday == true ? selectedDaysOfWeek.push(4) : 0;
                    element.Thursday == true ? selectedDaysOfWeek.push(5) : 0;
                    element.Friday == true ? selectedDaysOfWeek.push(6) : 0;
                    element.Saturday == true ? selectedDaysOfWeek.push(7) : 0;

                    const startDate = new Date(element.StartDate);
                    const endDate = new Date(element.EndDate);
                    while (new Date(startDate) <= new Date(endDate)) {
                      selectedDaysOfWeek.map((el, i) => {
                        dayOfWeek = el - 1;
                        if (startDate.getDay() === dayOfWeek) {
                          rentedBoothDates.push(this.datePipe.transform(new Date(startDate), 'MMM d, y'));
                        }
                      });
                      startDate.setDate(startDate.getDate() + 1);
                    }
                    var Sunday = el == 1 ? true : false;
                    var Monday = el == 2 ? true : false;
                    var Tuesday = el == 3 ? true : false;
                    var Wednesday = el == 4 ? true : false;
                    var Thursday = el == 5 ? true : false;
                    var Friday = el == 6 ? true : false;
                    var Saturday = el == 7 ? true : false;
                    const isDateIncluded = rentalDates.some(rentalDate => {
                      const startDate = rentalDate.startDate;
                      return rentedBoothDates.includes(startDate);
                    });
                    if (isDateIncluded && element.BoothNo == selectedBooth[0].BoothNumber && element.TempRecordNo != this.LayoutData.tempRecordNo
                      && (element.Sunday == Sunday || element.Monday == Monday || element.Tuesday == Tuesday
                      || element.Wednesday == Wednesday || element.Thursday == Thursday || element.Friday == Friday || element.Saturday == Saturday)
                    ) {
                      boothSelectedByOtherUser = true;
                    }
                  });
                }
                if (boothSelectedByOtherUser) {
                  Swal.fire({
                    text: $localize`This booth has been already selected by another user`,
                    icon: "warning",
                    confirmButtonText: $localize`Ok`,
                  });
                } else {
                  this.diagram.add(diagramNode);
                }
              });
            }
            else if (this.rentalType == "CM") {
              const rentalDates = [];
              var rentedBoothDates = [];
              var rentalstartDate;
              const startDate = new Date(this.LayoutData.RentalStartDate);
              const endMonth = this.LayoutData.monthlyRentalEndDate
              const [endMonthDate, endMonthYear] = endMonth.split(' ');
              const endMonthIndex = this.getMonthIndex(endMonthDate);
              var endDate = new Date(endMonthYear, endMonthIndex + 1, 0);
              if (this.LayoutData.combinedMonthlyBookingEndDate) {
                const rentalEndDateYear = endDate.getFullYear();
                const rentalEndDateMonth = endDate.getMonth() + 1;
                const rentalEndDateYearMonth = `${rentalEndDateYear}${rentalEndDateMonth < 10 ? '0' : ''}${rentalEndDateMonth}`;
                const response = await this.rentalService.GetSelectedMonthEndDate(rentalEndDateYearMonth,this.marketId).toPromise();
                endDate = new Date(response.MonthEndDateSelected);
              }
              this.LayoutData.monthlyRentalMarketDays.map((el, i) => {
                const selectedDayOfWeek = el - 1;

                let currentDay = new Date(startDate);
                while (currentDay <= endDate) {
                  if (currentDay.getDay() === selectedDayOfWeek) {
                    if (!rentalstartDate) {
                      rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                    }
                    marketDays += 1;
                    rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                  }
                  currentDay.setDate(currentDay.getDate() + 1);
                }
                if (dataFromTemp.length > 0) {
                  dataFromTemp.forEach(element => {
                    const marketDaysOfTemprecord = [];
                    var rentedBoothDates = [];
                    element.Sunday ? marketDaysOfTemprecord.push(1) : 0;
                    element.Monday ? marketDaysOfTemprecord.push(2) : 0;
                    element.Tuesday ? marketDaysOfTemprecord.push(3) : 0;
                    element.Wednesday ? marketDaysOfTemprecord.push(4) : 0;
                    element.Thursday ? marketDaysOfTemprecord.push(5) : 0;
                    element.Friday ? marketDaysOfTemprecord.push(6) : 0;
                    element.Saturday ? marketDaysOfTemprecord.push(7) : 0;
                    marketDaysOfTemprecord.map((el, i) => {
                      const dayOfWeek = el - 1;
                      const startDate = new Date(element.StartDate);
                      const endDate = new Date(element.EndDate);
                      while (new Date(startDate) <= new Date(endDate)) {
                        if (startDate.getDay() === dayOfWeek) {
                          rentedBoothDates.push(this.datePipe.transform(new Date(startDate), 'MMM d, y'));
                        }
                        startDate.setDate(startDate.getDate() + 1);
                      }
                    });
                    var Monday = this.LayoutData.Monday ? true : false;
                    var Tuesday = this.LayoutData.Tuesday ? true : false;
                    var Wednesday = this.LayoutData.Wednesday ? true : false;
                    var Thursday = this.LayoutData.Thursday ? true : false;
                    var Friday = this.LayoutData.Friday ? true : false;
                    var Saturday = this.LayoutData.Saturday ? true : false;
                    var Sunday = this.LayoutData.Sunday ? true : false;
                    const isDateIncluded = rentalDates.some(rentalDate => {
                      const startDate = rentalDate.startDate;
                      return rentedBoothDates.includes(startDate);
                    });
                    if (isDateIncluded && element.BoothNo == selectedBooth[0].BoothNumber && element.TempRecordNo != this.LayoutData.tempRecordNo
                      && (element.Sunday == Sunday || element.Monday == Monday || element.Tuesday == Tuesday
                        || element.Wednesday == Wednesday || element.Thursday == Thursday || element.Friday == Friday || element.Saturday == Saturday)
                    ) {
                      boothSelectedByOtherUser = true;
                    }
                  });
                }
                if (boothSelectedByOtherUser) {
                  Swal.fire({
                    text: $localize`This booth has been already selected by another user`,
                    icon: "warning",
                    confirmButtonText: $localize`Ok`,
                  });
                } else {
                  this.diagram.add(diagramNode);
                }
              });
            }
            else if (this.rentalType == "CW") {
              const rentalDates = [];
              var rentedBoothDates = [];
              var rentalstartDate;
              const startDate = new Date(this.LayoutData.weeklyRentalStartDate.split("-")[0]);
              const endDate = new Date(this.LayoutData.weeklyRentalEndDate.split("-")[1]);
              this.LayoutData.weeklyRentalMarketDays.map((el, i) => {
                const selectedDayOfWeek = el - 1;
                let currentDay = new Date(startDate);
                while (currentDay <= endDate) {
                  if (currentDay.getDay() === selectedDayOfWeek) {
                    if (!rentalstartDate) {
                      rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                    }
                    marketDays += 1;
                    rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                  }
                  currentDay.setDate(currentDay.getDate() + 1);
                }
                if (dataFromTemp.length > 0) {
                  dataFromTemp.forEach(element => {
                    const marketDaysOfTemprecord = [];
                    var rentedBoothDates = [];
                    element.Sunday ? marketDaysOfTemprecord.push(1) : 0;
                    element.Monday ? marketDaysOfTemprecord.push(2) : 0;
                    element.Tuesday ? marketDaysOfTemprecord.push(3) : 0;
                    element.Wednesday ? marketDaysOfTemprecord.push(4) : 0;
                    element.Thursday ? marketDaysOfTemprecord.push(5) : 0;
                    element.Friday ? marketDaysOfTemprecord.push(6) : 0;
                    element.Saturday ? marketDaysOfTemprecord.push(7) : 0;
                    marketDaysOfTemprecord.map((el, i) => {
                      const dayOfWeek = el - 1;
                      const startDate = new Date(element.StartDate);
                      const endDate = new Date(element.EndDate);
                      while (new Date(startDate) <= new Date(endDate)) {
                        if (startDate.getDay() === dayOfWeek) {
                          rentedBoothDates.push(this.datePipe.transform(new Date(startDate), 'MMM d, y'));
                        }
                        startDate.setDate(startDate.getDate() + 1);
                      }
                    });
                    var Monday = this.LayoutData.Monday ? true : false;
                    var Tuesday = this.LayoutData.Tuesday ? true : false;
                    var Wednesday = this.LayoutData.Wednesday ? true : false;
                    var Thursday = this.LayoutData.Thursday ? true : false;
                    var Friday = this.LayoutData.Friday ? true : false;
                    var Saturday = this.LayoutData.Saturday ? true : false;
                    var Sunday = this.LayoutData.Sunday ? true : false;
                    const isDateIncluded = rentalDates.some(rentalDate => {
                      const startDate = rentalDate.startDate;
                      return rentedBoothDates.includes(startDate);
                    });
                    if (isDateIncluded && element.BoothNo == selectedBooth[0].BoothNumber && element.TempRecordNo != this.LayoutData.tempRecordNo
                      && (element.Sunday == Sunday || element.Monday == Monday || element.Tuesday == Tuesday
                        || element.Wednesday == Wednesday || element.Thursday == Thursday || element.Friday == Friday || element.Saturday == Saturday)
                    ) {
                      boothSelectedByOtherUser = true;
                    }
                  });
                }
                if (boothSelectedByOtherUser) {
                  Swal.fire({
                    text: $localize`This booth has been already selected by another user`,
                    icon: "warning",
                    confirmButtonText: $localize`Ok`,
                  });
                } else {
                  this.diagram.add(diagramNode);
                }
              });
            }
            else if (this.rentalType == "W") {
              const rentalDates = [];
              var rentedBoothDates = [];
              var rentalstartDate;
              const startDate = new Date(this.LayoutData.weeklyRentalStartDate.split("-")[0]);
              const endDate = new Date(this.LayoutData.weeklyRentalEndDate.split("-")[1]);
              this.LayoutData.weeklyRentalMarketDays.map((el, i) => {
                const selectedDayOfWeek = el - 1;
                let currentDay = new Date(startDate);
                while (currentDay <= endDate) {
                  if (currentDay.getDay() === selectedDayOfWeek) {
                    if (!rentalstartDate) {
                      rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                    }
                    marketDays += 1;
                    rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                  }
                  currentDay.setDate(currentDay.getDate() + 1);
                }
                if (dataFromTemp.length > 0) {
                  dataFromTemp.forEach(element => {
                    const marketDaysOfTemprecord = [];
                    var rentedBoothDates = [];
                    element.Sunday ? marketDaysOfTemprecord.push(1) : 0;
                    element.Monday ? marketDaysOfTemprecord.push(2) : 0;
                    element.Tuesday ? marketDaysOfTemprecord.push(3) : 0;
                    element.Wednesday ? marketDaysOfTemprecord.push(4) : 0;
                    element.Thursday ? marketDaysOfTemprecord.push(5) : 0;
                    element.Friday ? marketDaysOfTemprecord.push(6) : 0;
                    element.Saturday ? marketDaysOfTemprecord.push(7) : 0;
                    marketDaysOfTemprecord.map((el, i) => {
                      const dayOfWeek = el - 1;
                      const startDate = new Date(element.StartDate);
                      const endDate = new Date(element.EndDate);
                      while (new Date(startDate) <= new Date(endDate)) {
                        if (startDate.getDay() === dayOfWeek) {
                          rentedBoothDates.push(this.datePipe.transform(new Date(startDate), 'MMM d, y'));
                        }
                        startDate.setDate(startDate.getDate() + 1);
                      }
                    });
                    var Monday = this.LayoutData.Monday ? true : false;
                    var Tuesday = this.LayoutData.Tuesday ? true : false;
                    var Wednesday = this.LayoutData.Wednesday ? true : false;
                    var Thursday = this.LayoutData.Thursday ? true : false;
                    var Friday = this.LayoutData.Friday ? true : false;
                    var Saturday = this.LayoutData.Saturday ? true : false;
                    var Sunday = this.LayoutData.Sunday ? true : false;
                    const isDateIncluded = rentalDates.some(rentalDate => {
                      const startDate = rentalDate.startDate;
                      return rentedBoothDates.includes(startDate);
                    });
                    if (isDateIncluded && element.BoothNo == selectedBooth[0].BoothNumber && element.TempRecordNo != this.LayoutData.tempRecordNo
                      && (element.Sunday == Sunday || element.Monday == Monday || element.Tuesday == Tuesday
                        || element.Wednesday == Wednesday || element.Thursday == Thursday || element.Friday == Friday || element.Saturday == Saturday)
                    ) {
                      boothSelectedByOtherUser = true;
                    }
                  });
                }
                if (boothSelectedByOtherUser) {
                  Swal.fire({
                    text: $localize`This booth has been already selected by another user!`,
                    icon: "warning",
                    confirmButtonText: $localize`Ok`,
                  });
                } else {
                  this.diagram.add(diagramNode);
                }
              });
            }
            else {
              this.diagram.add(diagramNode);
            }

            if (this.rentalType == "D") {
              if (!boothSelectedByOtherUser) {
                this.selectedBoothData.push(
                  {
                    BoothNumber: selectedBooth[0].BoothNumber,
                    BoothType: selectedBooth[0].BoothType,
                    BoothDescription: selectedBooth[0].BoothDescription,
                    BoothTypeId: selectedBooth[0].BoothTypeId,
                  });
                this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
                this.insertDataToTemp(selectedBooth[0].BoothNumber,'','','');
                var selectedBoothsForRental = {
                  BoothNumber: selectedBooth[0].BoothNumber
                }
                this.selectedBoothsForRental.push(selectedBoothsForRental);
              }
            }
            else if (this.rentalType == "W") {
              if (!boothSelectedByOtherUser) {
                this.LayoutData.weeklyRentalMarketDays.map((el, i) => {
                  var selectedBooth = this.allBooths.filter(e => e.BoothNumber == args.properties.annotations[0].properties.content);
                  var marketDay;
                  switch (el) {
                    case 1: marketDay = 'Sun'
                      break;
                    case 2: marketDay = 'Mon'
                      break;
                    case 3: marketDay = 'Tue'
                      break;
                    case 4: marketDay = 'Wed'
                      break;
                    case 5: marketDay = 'Thu'
                      break;
                    case 6: marketDay = 'Fri'
                      break;
                    case 7: marketDay = 'Sat'
                      break;
                  }
                  var selectedRentalDates = [];
                  const startDate = new Date(this.LayoutData.weeklyRentalStartDate.split("-")[0]);
                  const endDate = new Date(this.LayoutData.weeklyRentalEndDate.split("-")[1]);
                  const selectedDayOfWeek = el - 1;
                  while (new Date(startDate) <= new Date(endDate)) {
                    if (startDate.getDay() === selectedDayOfWeek) {
                      selectedRentalDates.push(this.datePipe.transform(new Date(startDate), 'MMM d, y'));
                    }
                    startDate.setDate(startDate.getDate() + 1);
                  }
                  this.selectedBoothData.push(
                    {
                      BoothNumber: selectedBooth[0].BoothNumber,
                      BoothType: selectedBooth[0].BoothType,
                      BoothTypeId: selectedBooth[0].BoothTypeId,
                      BoothDescription: selectedBooth[0].BoothDescription,
                      Sunday: el == 1 ? true : false,
                      Monday: el == 2 ? true : false,
                      Tuesday: el == 3 ? true : false,
                      Wednesday: el == 4 ? true : false,
                      Thursday: el == 5 ? true : false,
                      Friday: el == 6 ? true : false,
                      Saturday: el == 7 ? true : false,
                      MarketDay: marketDay
                    });
                    this.insertDataToTemp(selectedBooth[0].BoothNumber, selectedRentalDates[0], selectedRentalDates[selectedRentalDates.length - 1], '');
                    var selectedBoothsForCWRental = {
                      BoothNumber: selectedBooth[0].BoothNumber,
                      StartDate: selectedRentalDates[0],
                      EndDate: selectedRentalDates[selectedRentalDates.length - 1],
                      Monday: this.LayoutData.Monday ? true : false,
                      Tuesday: this.LayoutData.Tuesday ? true : false,
                      Wednesday: this.LayoutData.Wednesday ? true : false,
                      Thursday: this.LayoutData.Thursday ? true : false,
                      Friday: this.LayoutData.Friday ? true : false,
                      Saturday: this.LayoutData.Saturday ? true : false,
                      Sunday: this.LayoutData.Sunday ? true : false
                    }
                    this.selectedBoothsForRental.push(selectedBoothsForCWRental);
                    this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
                });
                this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
              }
            }
            else if (this.rentalType == "CW") {
              if (!boothSelectedByOtherUser) {
                var selectedBooth = this.allBooths.filter(e => e.BoothNumber == args.properties.annotations[0].properties.content);
                const rentalDates = [];
                const currentWeekRentalDates = [];
                var marketDays = 0;
                this.LayoutData.weeklyRentalMarketDays.map((el, i) => {
                  const startDate = new Date(this.LayoutData.weeklyRentalStartDate.split("-")[0]);
                  const endDate = new Date(this.LayoutData.weeklyRentalEndDate.split("-")[1]);
                  const currentWeekEnd = new Date(this.LayoutData.weeklyRentalStartDate.split("-")[1]);
                  const selectedDayOfWeek = el - 1;
                  const today: Date = new Date(new Date().toDateString())
                  let currentDay = new Date(startDate);
                  while (currentDay <= endDate) {
                    if (currentDay >= today) {
                      if (currentDay.getDay() === selectedDayOfWeek) {
                        marketDays += 1;
                        rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                      }
                    }
                    currentDay.setDate(currentDay.getDate() + 1);
                  }
                  currentDay = new Date(startDate);
                  while (currentDay <= currentWeekEnd) {
                    if (currentDay >= today) {
                      if (currentDay.getDay() === selectedDayOfWeek) {
                        marketDays += 1;
                        currentWeekRentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                      }
                    }
                    currentDay.setDate(currentDay.getDate() + 1);
                  }
                  const compareDates = (a: DateString, b: DateString): number => {
                    const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                    const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                    return dateA - dateB;
                  };
                  const sortedDates = rentalDates.sort(compareDates);
                });
                this.selectedBoothData.push(
                  {
                    BoothNumber: selectedBooth[0].BoothNumber,
                    BoothType: selectedBooth[0].BoothType,
                    BoothTypeId: selectedBooth[0].BoothTypeId,
                    BoothDescription: selectedBooth[0].BoothDescription,
                    Sunday: this.LayoutData.weeklyRentalMarketDays.includes(1) ? true : false,
                    Monday: this.LayoutData.weeklyRentalMarketDays.includes(2) ? true : false,
                    Tuesday: this.LayoutData.weeklyRentalMarketDays.includes(3) ? true : false,
                    Wednesday: this.LayoutData.weeklyRentalMarketDays.includes(4) ? true : false,
                    Thursday: this.LayoutData.weeklyRentalMarketDays.includes(5) ? true : false,
                    Friday: this.LayoutData.weeklyRentalMarketDays.includes(6) ? true : false,
                    Saturday: this.LayoutData.weeklyRentalMarketDays.includes(7) ? true : false,
                    RentalEndDate: rentalDates[rentalDates.length - 1].startDate,
                    NoOfMarketDays: marketDays,
                    StartDateList: currentWeekRentalDates,
                  });
                this.insertDataToTemp(selectedBooth[0].BoothNumber, rentalDates[0].startDate, rentalDates[rentalDates.length - 1].startDate, '');
                var selectedBoothsForCWRental = {
                  BoothNumber: selectedBooth[0].BoothNumber,
                  StartDate: rentalDates[0].startDate,
                  EndDate: rentalDates[rentalDates.length - 1].startDate,
                  Monday: this.LayoutData.Monday ? true : false,
                  Tuesday: this.LayoutData.Tuesday ? true : false,
                  Wednesday: this.LayoutData.Wednesday ? true : false,
                  Thursday: this.LayoutData.Thursday ? true : false,
                  Friday: this.LayoutData.Friday ? true : false,
                  Saturday: this.LayoutData.Saturday ? true : false,
                  Sunday: this.LayoutData.Sunday ? true : false
                }
                this.selectedBoothsForRental.push(selectedBoothsForCWRental);
                this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
              }
            }
            else if (this.rentalType == "M") {
              if (!boothSelectedByOtherUser) {
                var marketDay;
                const rentalDates = [];
                const currentMonthRentalDates = [];
                this.LayoutData.monthlyRentalMarketDays.map((el, i) => {
                  var selectedBooth = this.allBooths.filter(e => e.BoothNumber == args.properties.annotations[0].properties.content);
                  var marketDay;
                  switch (el) {
                    case 1: marketDay = 'Sun'
                      break;
                    case 2: marketDay = 'Mon'
                      break;
                    case 3: marketDay = 'Tue'
                      break;
                    case 4: marketDay = 'Wed'
                      break;
                    case 5: marketDay = 'Thu'
                      break;
                    case 6: marketDay = 'Fri'
                      break;
                    case 7: marketDay = 'Sat'
                      break;
                  }
                  var rentalstartDate = "";
                  var marketDays = 0;
                  const startDate = new Date(this.LayoutData.RentalStartDate);
                  const endMonth = this.LayoutData.monthlyRentalEndDate
                  const [endMonthDate, endMonthYear] = endMonth.split(' ');
                  const endMonthIndex = this.getMonthIndex(endMonthDate);
                  const endDate = new Date(endMonthYear, endMonthIndex + 1, 0);
                  const currentMonthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

                  const selectedDayOfWeek = el - 1;
                  let currentDay = new Date(startDate);
                  while (currentDay <= endDate) {
                    if (currentDay.getDay() === selectedDayOfWeek) {
                      if (!rentalstartDate) {
                        rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                      }
                      marketDays += 1;
                      rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                    }
                    currentDay.setDate(currentDay.getDate() + 1);
                  }

                  currentDay = new Date(startDate);
                  while (currentDay <= currentMonthEnd) {
                    if (currentDay.getDay() === selectedDayOfWeek) {
                      if (!rentalstartDate) {
                        rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                      }
                      marketDays += 1;
                      currentMonthRentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                    }
                    currentDay.setDate(currentDay.getDate() + 1);
                  }

                  const compareDates = (a: DateString, b: DateString): number => {
                    const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                    const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                    return dateA - dateB;
                  };
                  const sortedDates = rentalDates.sort(compareDates);

                  const compareCurrentMonthDates = (a: DateString, b: DateString): number => {
                    const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                    const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                    return dateA - dateB;
                  };
                  const currentMonthSortedDates = currentMonthRentalDates.sort(compareCurrentMonthDates);

                  if (rentalstartDate) {
                    this.selectedBoothData.push(
                      {
                        BoothNumber: selectedBooth[0].BoothNumber,
                        BoothType: selectedBooth[0].BoothType,
                        BoothTypeId: selectedBooth[0].BoothTypeId,
                        BoothDescription: selectedBooth[0].BoothDescription,
                        Sunday: el == 1 ? true : false,
                        Monday: el == 2 ? true : false,
                        Tuesday: el == 3 ? true : false,
                        Wednesday: el == 4 ? true : false,
                        Thursday: el == 5 ? true : false,
                        Friday: el == 6 ? true : false,
                        Saturday: el == 7 ? true : false,
                        MarketDay: marketDay,
                        RentalStartDate: rentalstartDate,
                        NoOfMarketDays: marketDays,
                        RentalEndDate: rentalDates[rentalDates.length - 1].startDate,
                        StartDateList: currentMonthRentalDates,
                        RentalStartDateYearMonth: this.LayoutData.RentalStartDateYearMonth
                      });

                      this.insertDataToTemp(selectedBooth[0].BoothNumber,rentalstartDate,rentalDates[rentalDates.length - 1].startDate,el);
                      var selectedBoothsForRental = {
                        BoothNumber: selectedBooth[0].BoothNumber,
                        StartDate: rentalstartDate,
                        EndDate: rentalDates[rentalDates.length - 1].startDate,
                        Sunday: el == 1 ? true : false,
                        Monday: el == 2 ? true : false,
                        Tuesday: el == 3 ? true : false,
                        Wednesday: el == 4 ? true : false,
                        Thursday: el == 5 ? true : false,
                        Friday: el == 6 ? true : false,
                        Saturday: el == 7 ? true : false
                      }
                     this.selectedBoothsForRental.push(selectedBoothsForRental);
                  }
                });
                this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
              }
            }
            else if (this.rentalType == "CM") {
              if (!boothSelectedByOtherUser) {
                var selectedBooth = this.allBooths.filter(e => e.BoothNumber == args.properties.annotations[0].properties.content);
                var marketDay;
                var marketDays = 0;
                const rentalDates = [];
                var currentMonthRentalDates = [];
                for (const el of this.LayoutData.monthlyRentalMarketDays) {
                  var marketDay;
                  const currentDate = new Date();
                  const currentYear = currentDate.getFullYear();
                  const currentMonth = currentDate.getMonth();
                  var endDateYearMonth;
                  const currentYearMonth =  `${currentYear}${(currentMonth +1) < 10 ? '0' : ''}${(currentMonth +1)}`;
                  var startDate = new Date(this.LayoutData.RentalStartDate);
                  const endMonth = this.LayoutData.monthlyRentalEndDate
                  const [endMonthDate, endMonthYear] = endMonth.split(' ');
                  const endMonthIndex = this.getMonthIndex(endMonthDate);
                  const rentalStartDateYear = startDate.getFullYear();
                  const rentalStartDateMonth = startDate.getMonth() + 1;
                  const rentalStartDateYearMonth = `${rentalStartDateYear}${rentalStartDateMonth < 10 ? '0' : ''}${rentalStartDateMonth}`;
                  var endDate = new Date(endMonthYear, endMonthIndex + 1, 0);             
                
                  if (this.LayoutData.combinedMonthlyBookingEndDate) {
                    const rentalEndDateYear = endDate.getFullYear();
                    const rentalEndDateMonth = endDate.getMonth() + 1;
                    const rentalEndDateYearMonth = `${rentalEndDateYear}${rentalEndDateMonth < 10 ? '0' : ''}${rentalEndDateMonth}`;
                    const response = await this.rentalService.GetSelectedMonthEndDate(rentalEndDateYearMonth,this.marketId).toPromise();
                    endDate = new Date(response.MonthEndDateSelected);
                    endDateYearMonth = rentalEndDateYearMonth;

                    const rentalStartDate = new Date(startDate);          
                    var previousMonth = new Date(rentalStartDate.setMonth(rentalStartDate.getMonth() - 1)).getMonth() + 1;
                    var PreviousMonthYear = new Date(rentalStartDate.setMonth(rentalStartDate.getMonth() - 1)).getFullYear();
                    const PreviousMonthYearAndMonth = `${PreviousMonthYear}${previousMonth < 10 ? '0' : ''}${previousMonth}`;
                    if (PreviousMonthYearAndMonth >= currentYearMonth) {
                      const previousMonthEndDate = await this.rentalService.GetSelectedMonthEndDate(PreviousMonthYearAndMonth, this.marketId).toPromise();
                     const monthEndDate = new Date(previousMonthEndDate.MonthEndDateSelected);
                      const dateDiff = this.getDaysDifference(monthEndDate, new Date(startDate)); //calculates the diff between current month start date and previous monthend date     
                      if (dateDiff <= 7) {
                       startDate = new Date(monthEndDate.setDate(monthEndDate.getDate() + 1));
                      }
                    }
                  }

                  var currentMonthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
                  if (this.LayoutData.combinedMonthlyBookingEndDate) {
                    const currentStartMonth = this.LayoutData.monthlyRentalStartDate;
                    const [startMonthDate, startMonthYear] = currentStartMonth.split(' ');
                    const startMonthIndex = this.getMonthIndex(startMonthDate);
                    var currentMonthEnd = new Date(startMonthYear, startMonthIndex + 1, 0);
                    const rentalEndDateYear = currentMonthEnd.getFullYear();
                    const rentalEndDateMonth = currentMonthEnd.getMonth() + 1;
                    const rentalEndDateYearMonth = `${rentalEndDateYear}${rentalEndDateMonth < 10 ? '0' : ''}${rentalEndDateMonth}`;
                    const response = await this.rentalService.GetSelectedMonthEndDate(rentalEndDateYearMonth, this.marketId).toPromise();
                    if (rentalStartDateYearMonth == rentalEndDateYearMonth)
                      currentMonthEnd = new Date(response.MonthEndDateSelected);
                  }

                  const selectedDayOfWeek = el - 1;
                  let currentDay = new Date(startDate);
                  while (currentDay <= endDate) {                
                    if (currentDay.getDay() === selectedDayOfWeek) {
                      if (!rentalstartDate) {
                        rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                      }
                      marketDays += 1;
                      rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                    }
                    currentDay.setDate(currentDay.getDate() + 1);
                  }

                  if (this.LayoutData.combinedMonthlyBookingEndDate) {
                    currentDay = new Date(startDate);
                    while (currentDay <= currentMonthEnd) {
                      if (currentDay.getDay() === selectedDayOfWeek) {
                        if (!rentalstartDate) {
                          rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                        }
                        currentMonthRentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                      }
                      currentDay.setDate(currentDay.getDate() + 1);
                    }
                  }

                  const compareDates = (a: DateString, b: DateString): number => {
                    const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                    const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                    return dateA - dateB;
                  };
                  const sortedDates = rentalDates.sort(compareDates);

                  if (this.LayoutData.combinedMonthlyBookingEndDate) {
                    const compareCurrentMonthDates = (a: DateString, b: DateString): number => {
                      const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                      const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                      return dateA - dateB;
                    };
                    const currentMonthSortedDates = currentMonthRentalDates.sort(compareCurrentMonthDates);
                  }
                  else {
                    currentMonthRentalDates = rentalDates;
                  }  
                }
             
                this.selectedBoothData.push(
                  {
                    BoothNumber: selectedBooth[0].BoothNumber,
                    BoothType: selectedBooth[0].BoothType,
                    BoothTypeId: selectedBooth[0].BoothTypeId,
                    BoothDescription: selectedBooth[0].BoothDescription,
                    Sunday: this.LayoutData.monthlyRentalMarketDays.includes(1) ? true : false,
                    Monday: this.LayoutData.monthlyRentalMarketDays.includes(2) ? true : false,
                    Tuesday: this.LayoutData.monthlyRentalMarketDays.includes(3) ? true : false,
                    Wednesday: this.LayoutData.monthlyRentalMarketDays.includes(4) ? true : false,
                    Thursday: this.LayoutData.monthlyRentalMarketDays.includes(5) ? true : false,
                    Friday: this.LayoutData.monthlyRentalMarketDays.includes(6) ? true : false,
                    Saturday: this.LayoutData.monthlyRentalMarketDays.includes(7) ? true : false,
                    MarketDay: marketDay,
                    RentalStartDate: this.datePipe.transform(new Date(this.LayoutData.RentalStartDate), 'MMM d, y'),
                    NoOfMarketDays: marketDays,
                    RentalEndDate: rentalDates[rentalDates.length - 1].startDate,
                    StartDateList: currentMonthRentalDates,
                    AllRentalDates:rentalDates.map(el => new Date(el.startDate))
                  });

                this.insertDataToTemp(selectedBooth[0].BoothNumber, rentalDates[0].startDate, rentalDates[rentalDates.length - 1].startDate, '');
                var selectedBoothsForCMRental = {
                  BoothNumber: selectedBooth[0].BoothNumber,
                  StartDate: rentalDates[0].startDate,
                  EndDate: rentalDates[rentalDates.length - 1].startDate,
                  Monday: this.LayoutData.Monday ? true : false,
                  Tuesday: this.LayoutData.Tuesday ? true : false,
                  Wednesday: this.LayoutData.Wednesday ? true : false,
                  Thursday: this.LayoutData.Thursday ? true : false,
                  Friday: this.LayoutData.Friday ? true : false,
                  Saturday: this.LayoutData.Saturday ? true : false,
                  Sunday: this.LayoutData.Sunday ? true : false
                }
                this.selectedBoothsForRental.push(selectedBoothsForCMRental);
                this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
              }
            }
          }
          else if (this.rentalType == "D") {
            var markedNodeId = nodeId.replace("&","_amper_") + "mark";
            const selectedNode = this.diagram.getObject(markedNodeId) as NodeModel;
            var boothCount = 0;
            this.sharedDataService.dailyRentalSelectedDays.map(date => {
              const boothRecord = this.sharedDataService.dailyRentalSelectedBooths.filter(selectedBooth => selectedBooth.BoothNumber === this.BoothNumber && selectedBooth.RentalDay == this.datePipe.transform(date, 'MM/dd/yyy'));
              if (boothRecord.length > 0) {
                boothCount += 1;
              }
            });

            if (boothCount != 0) {
              Swal.fire({
                title: $localize`Confirm`,
                text: $localize`Are you sure you want to delete the selected Booth?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: $localize`Yes`,
                cancelButtonText: $localize`No`,
              }).then((result) => {
                if (result.value) {
                  this.sharedDataService.dailyRentalSelectedDays.map(date => {
                    const index = this.sharedDataService.dailyRentalSelectedBooths.findIndex(selectedBooth => selectedBooth.BoothNumber === this.BoothNumber && selectedBooth.RentalDay == this.datePipe.transform(date, 'MM/dd/yyy'));
                    if (index != -1) {
                      this.diagram.remove(selectedNode);
                      this.removeBoothCharges.emit(this.sharedDataService.dailyRentalSelectedBooths[index]);
                      this.sharedDataService.dailyRentalSelectedBooths.splice(index, 1);
                      this.selectedBoothData.splice(boothIndex, 1);
                      this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
                    }
                    else {
                      const temp = {
                        TempRecordNo: this.sharedDataService.tempRecordNo.toString(),
                        BoothNumber: this.BoothNumber,
                        StartDate: new Date(date),
                        RentalType: 1
                      }
                      this.subscriptions.push(
                        this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
                        })
                      );
                    }
                  });
                }
              });
            }
            else {
              this.diagram.remove(selectedNode);
              this.selectedBoothData.splice(boothIndex, 1);
              this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
              this.sharedDataService.dailyRentalSelectedDays.map(date => {
                const temp = {
                  TempRecordNo: this.sharedDataService.tempRecordNo.toString(),
                  BoothNumber: this.BoothNumber,
                  StartDate: new Date(date),
                  RentalType: 1
                }
                this.subscriptions.push(
                  this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
                  })
                );
              });
            }
          }

          else if (this.rentalType == "M") {
            var markedNodeId = nodeId.replace("&","_amper_") + "mark";
            const selectedNode = this.diagram.getObject(markedNodeId) as NodeModel;
            const index = this.LayoutData.monthlyRentalSelectedBooths.findIndex(selectedBooth => selectedBooth.BoothNumber === this.BoothNumber && selectedBooth.RentStartPeriod == this.LayoutData.monthlyRentalSelectedBooths[0].RentStartPeriod && selectedBooth.RentEndPeriod == this.LayoutData.monthlyRentalSelectedBooths[0].RentEndPeriod);
            if (index != -1) {
              Swal.fire({
                title: $localize`Confirm`,
                text: $localize`Are you sure you want to delete the selected Booth?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: $localize`Yes`,
                cancelButtonText: $localize`No`,
              }).then((result) => {
                if (result.value) {
                  this.diagram.remove(selectedNode);
                  this.removeBoothCharges.emit(this.LayoutData.monthlyRentalSelectedBooths[index]);
                  this.LayoutData.monthlyRentalSelectedBooths.splice(index, 1);
                  this.selectedBoothData.splice(boothIndex, 1);
                  this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
                  const startDate = new Date(this.LayoutData.RentalStartDate);
                  const endMonth = this.LayoutData.monthlyRentalEndDate
                  const [endMonthDate, endMonthYear] = endMonth.split(' ');
                  const endMonthIndex = this.getMonthIndex(endMonthDate);
                  const endDate = new Date(endMonthYear, endMonthIndex + 1, 0);
                  
                  this.LayoutData.monthlyRentalMarketDays.map((el, i) => {
                    const rentalDates=[];
                    var rentalstartDate = "";
                    const selectedDayOfWeek = el - 1;
                    let currentDay = new Date(startDate);
                    while (currentDay <= endDate) {
                      if (currentDay.getDay() === selectedDayOfWeek) {
                        if (!rentalstartDate) {
                          rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                        }
                        marketDays += 1;
                        rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                      }
                      currentDay.setDate(currentDay.getDate() + 1);
                    }
                    const compareDates =(a: DateString, b: DateString): number => {
                      const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                      const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                      return dateA - dateB;
                    };
                    const sortedDates = rentalDates.sort(compareDates);
                    const temp = {
                      TempRecordNo: this.LayoutData.tempRecordNo.toString(),
                      BoothNumber: this.BoothNumber,
                      StartDate: new Date(rentalstartDate),
                      RentalType: 2,
                      EndDate: new Date(rentalDates[rentalDates.length-1].startDate),
                      Sunday: el == 1 ? true : false,
                      Monday: el == 2 ? true : false,
                      Tuesday: el == 3 ? true : false,
                      Wednesday: el == 4 ? true : false,
                      Thursday: el == 5 ? true : false,
                      Friday: el == 6 ? true : false,
                      Saturday: el == 7 ? true : false
                    }
                    this.subscriptions.push(
                      this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
                      })
                    );
                  });
                }
              });
            }
            else {
              this.diagram.remove(selectedNode);
              this.selectedBoothData.splice(boothIndex, 1);
              this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
              const startDate = new Date(this.LayoutData.RentalStartDate);
              const endMonth = this.LayoutData.monthlyRentalEndDate
              const [endMonthDate, endMonthYear] = endMonth.split(' ');
              const endMonthIndex = this.getMonthIndex(endMonthDate);
              const endDate = new Date(endMonthYear, endMonthIndex + 1, 0);
              
              this.LayoutData.monthlyRentalMarketDays.map((el, i) => {
                const rentalDates=[];
                var rentalstartDate = "";
                const selectedDayOfWeek = el - 1;
                let currentDay = new Date(startDate);
                while (currentDay <= endDate) {
                  if (currentDay.getDay() === selectedDayOfWeek) {
                    if (!rentalstartDate) {
                      rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                    }
                    marketDays += 1;
                    rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                  }
                  currentDay.setDate(currentDay.getDate() + 1);
                }
                const compareDates =(a: DateString, b: DateString): number => {
                  const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                  const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                  return dateA - dateB;
                };
                const sortedDates = rentalDates.sort(compareDates);
                const temp = {
                  TempRecordNo: this.LayoutData.tempRecordNo.toString(),
                  BoothNumber: this.BoothNumber,
                  StartDate: new Date(rentalstartDate),
                  RentalType: 2,
                  EndDate: new Date(rentalDates[rentalDates.length-1].startDate),
                  Sunday: el == 1 ? true : false,
                  Monday: el == 2 ? true : false,
                  Tuesday: el == 3 ? true : false,
                  Wednesday: el == 4 ? true : false,
                  Thursday: el == 5 ? true : false,
                  Friday: el == 6 ? true : false,
                  Saturday: el == 7 ? true : false
                }
                this.subscriptions.push(
                  this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
                  })
                );
              });
            }
          }

          else if (this.rentalType == "CW") {
            var markedNodeId = nodeId.replace("&","_amper_") + "mark";
            const selectedNode = this.diagram.getObject(markedNodeId) as NodeModel;
            const index = this.LayoutData.weeklyRentalSelectedBooths.findIndex(selectedBooth => selectedBooth.BoothNumber === this.BoothNumber && selectedBooth.RentStartPeriod == this.LayoutData.weeklyRentalSelectedBooths[0].RentStartPeriod && selectedBooth.RentEndPeriod == this.LayoutData.weeklyRentalSelectedBooths[0].RentEndPeriod);
            if (index != -1) {
              Swal.fire({
                title: $localize`Confirm`,
                text: $localize`Are you sure you want to delete the selected Booth?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: $localize`Yes`,
                cancelButtonText: $localize`No`,
              }).then((result) => {
                if (result.value) {
                  this.diagram.remove(selectedNode);
                  this.removeBoothCharges.emit(this.LayoutData.weeklyRentalSelectedBooths[index]);
                  this.LayoutData.weeklyRentalSelectedBooths.splice(index, 1);
                  this.selectedBoothData.splice(boothIndex, 1);
                  this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
                  const rentalDates = [];
                  var rentedBoothDates = [];
                  var rentalstartDate;
                  const startDate = new Date(this.LayoutData.weeklyRentalStartDate.split("-")[0]);
                  const endDate = new Date(this.LayoutData.weeklyRentalEndDate.split("-")[1]);
                  this.LayoutData.weeklyRentalMarketDays.map((el, i) => {
                    const selectedDayOfWeek = el - 1;
                    const today: Date = new Date(new Date().toDateString())
                    let currentDay = new Date(startDate);
                    while (currentDay <= endDate) {
                      if (currentDay >= today) {
                        if (currentDay.getDay() === selectedDayOfWeek) {
                          marketDays += 1;
                          rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                        }
                      }
                      currentDay.setDate(currentDay.getDate() + 1);
                    }
                  });
                  const compareDates = (a: DateString, b: DateString): number => {
                    const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                    const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                    return dateA - dateB;
                  };
                  const sortedDates = rentalDates.sort(compareDates);
                  const temp = {
                    TempRecordNo: this.LayoutData.tempRecordNo.toString(),
                    BoothNumber: this.BoothNumber,
                    StartDate: new Date(rentalDates[0].startDate),
                    RentalType: 5,
                    EndDate: new Date(rentalDates[rentalDates.length - 1].startDate),
                    Monday: this.LayoutData.Monday ? true : false,
                    Tuesday: this.LayoutData.Tuesday ? true : false,
                    Wednesday: this.LayoutData.Wednesday ? true : false,
                    Thursday: this.LayoutData.Thursday ? true : false,
                    Friday: this.LayoutData.Friday ? true : false,
                    Saturday: this.LayoutData.Saturday ? true : false,
                    Sunday: this.LayoutData.Sunday ? true : false
                  }
                  this.subscriptions.push(
                    this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
                    })
                  );
                }
              });
            }
            else {
              this.diagram.remove(selectedNode);
              this.selectedBoothData.splice(boothIndex, 1);
              this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
              const rentalDates = [];
              var rentedBoothDates = [];
              var rentalstartDate;
              const startDate = new Date(this.LayoutData.weeklyRentalStartDate.split("-")[0]);
              const endDate = new Date(this.LayoutData.weeklyRentalEndDate.split("-")[1]);
              this.LayoutData.weeklyRentalMarketDays.map((el, i) => {
                const selectedDayOfWeek = el - 1;
                const today: Date = new Date(new Date().toDateString())
                let currentDay = new Date(startDate);
                while (currentDay <= endDate) {
                  if (currentDay >= today) {
                    if (currentDay.getDay() === selectedDayOfWeek) {
                      marketDays += 1;
                      rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                    }
                  }
                  currentDay.setDate(currentDay.getDate() + 1);
                }
              });
              const compareDates = (a: DateString, b: DateString): number => {
                const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                return dateA - dateB;
              };
              const sortedDates = rentalDates.sort(compareDates);
              const temp = {
                TempRecordNo: this.LayoutData.tempRecordNo.toString(),
                BoothNumber: this.BoothNumber,
                StartDate: new Date(rentalDates[0].startDate),
                RentalType: 5,
                EndDate: new Date(rentalDates[rentalDates.length - 1].startDate),
                Monday: this.LayoutData.Monday ? true : false,
                Tuesday: this.LayoutData.Tuesday ? true : false,
                Wednesday: this.LayoutData.Wednesday ? true : false,
                Thursday: this.LayoutData.Thursday ? true : false,
                Friday: this.LayoutData.Friday ? true : false,
                Saturday: this.LayoutData.Saturday ? true : false,
                Sunday: this.LayoutData.Sunday ? true : false
              }
              this.subscriptions.push(
                this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
                })
              );
            }
          }

          else if (this.rentalType == "CM") {
            var markedNodeId = nodeId.replace("&","_amper_") + "mark";
            const selectedNode = this.diagram.getObject(markedNodeId) as NodeModel;
            const index = this.LayoutData.monthlyRentalSelectedBooths.findIndex(selectedBooth => selectedBooth.BoothNumber === this.BoothNumber && selectedBooth.RentStartPeriod == this.LayoutData.monthlyRentalSelectedBooths[0].RentStartPeriod && selectedBooth.RentEndPeriod == this.LayoutData.monthlyRentalSelectedBooths[0].RentEndPeriod);
            if (index != -1) {
              Swal.fire({
                title: $localize`Confirm`,
                text: $localize`Are you sure you want to delete the selected Booth?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: $localize`Yes`,
                cancelButtonText: $localize`No`,
              }).then((result) => {
                if (result.value) {
                  this.diagram.remove(selectedNode);
                  this.removeBoothCharges.emit(this.LayoutData.monthlyRentalSelectedBooths[index]);
                  this.LayoutData.monthlyRentalSelectedBooths.splice(index, 1);
                  this.selectedBoothData.splice(boothIndex, 1);
                  this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
                  const startDate = new Date(this.LayoutData.RentalStartDate);
                  const endMonth = this.LayoutData.monthlyRentalEndDate
                  const [endMonthDate, endMonthYear] = endMonth.split(' ');
                  const endMonthIndex = this.getMonthIndex(endMonthDate);
                  const endDate = new Date(endMonthYear, endMonthIndex + 1, 0);
                  const rentalDates = [];
                  var rentalstartDate = "";
                  this.LayoutData.monthlyRentalMarketDays.map((el, i) => {
                    const selectedDayOfWeek = el - 1;
                    let currentDay = new Date(startDate);
                    while (currentDay <= endDate) {
                      if (currentDay.getDay() === selectedDayOfWeek) {
                        if (!rentalstartDate) {
                          rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                        }
                        marketDays += 1;
                        rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                      }
                      currentDay.setDate(currentDay.getDate() + 1);
                    }
                  });
                    const compareDates = (a: DateString, b: DateString): number => {
                      const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                      const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                      return dateA - dateB;
                    };
                    const sortedDates = rentalDates.sort(compareDates);
                    const temp = {
                      TempRecordNo: this.LayoutData.tempRecordNo.toString(),
                      BoothNumber: this.BoothNumber,
                      StartDate: new Date(rentalDates[0].startDate),
                      RentalType: 4,
                      EndDate: new Date(rentalDates[rentalDates.length - 1].startDate),
                      Monday: this.LayoutData.Monday ? true : false,
                      Tuesday: this.LayoutData.Tuesday ? true : false,
                      Wednesday: this.LayoutData.Wednesday ? true : false,
                      Thursday: this.LayoutData.Thursday ? true : false,
                      Friday: this.LayoutData.Friday ? true : false,
                      Saturday: this.LayoutData.Saturday ? true : false,
                      Sunday: this.LayoutData.Sunday ? true : false
                    }
                    this.subscriptions.push(
                      this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
                      })
                    );                 
                }
              });
            }
            else {
              this.diagram.remove(selectedNode);
              this.selectedBoothData.splice(boothIndex, 1);
              this.noboothSelected = this.selectedBoothData.length > 0 ? false : true;
              const startDate = new Date(this.LayoutData.RentalStartDate);
              const endMonth = this.LayoutData.monthlyRentalEndDate
              const [endMonthDate, endMonthYear] = endMonth.split(' ');
              const endMonthIndex = this.getMonthIndex(endMonthDate);
              const endDate = new Date(endMonthYear, endMonthIndex + 1, 0);
              const rentalDates = [];
              rentalstartDate = "";
              this.LayoutData.monthlyRentalMarketDays.map((el, i) => {
                const selectedDayOfWeek = el - 1;
                let currentDay = new Date(startDate);
                while (currentDay <= endDate) {
                  if (currentDay.getDay() === selectedDayOfWeek) {
                    if (!rentalstartDate) {
                      rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                    }
                    marketDays += 1;
                    rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                  }
                  currentDay.setDate(currentDay.getDate() + 1);
                }
                const compareDates = (a: DateString, b: DateString): number => {
                  const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                  const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                  return dateA - dateB;
                };
                const sortedDates = rentalDates.sort(compareDates);
              });
              const temp = {
                TempRecordNo: this.LayoutData.tempRecordNo.toString(),
                BoothNumber: this.BoothNumber,
                StartDate: new Date(rentalDates[0].startDate),
                RentalType: 4,
                EndDate: new Date(rentalDates[rentalDates.length - 1].startDate),
                Monday: this.LayoutData.Monday ? true : false,
                Tuesday: this.LayoutData.Tuesday ? true : false,
                Wednesday: this.LayoutData.Wednesday ? true : false,
                Thursday: this.LayoutData.Thursday ? true : false,
                Friday: this.LayoutData.Friday ? true : false,
                Saturday: this.LayoutData.Saturday ? true : false,
                Sunday: this.LayoutData.Sunday ? true : false
              }
              this.subscriptions.push(
                this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
                })
              );
            }
          }
        });
      };
    }
  }
  
  async getMonthEndingDateFromSetting(rentalYearMonth: string): Promise<Date> {
    if (this.monthEndDateCache.has(rentalYearMonth)) {
      return this.monthEndDateCache.get(rentalYearMonth);
    }
    const response = await this.rentalService.GetSelectedMonthEndDate(rentalYearMonth, this.marketId).toPromise();
    if (response) {
      const monthEndDate = new Date(response.MonthEndDateSelected);
      this.monthEndDateCache.set(rentalYearMonth, monthEndDate);
      return monthEndDate;
    }
    else {
      return null
    }
  }

  getDaysDifference(date1: Date, date2: Date): number {
    // Normalize both dates to start of the day (midnight)
    const start = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const end = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    // Calculate the difference in time (milliseconds)
    const timeDiff = Math.abs(end.getTime() - start.getTime());
    // Convert time difference from milliseconds to days
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return dayDiff;
  }

  insertDataToTemp(BoothNumber, RentalStartDate, RentalEndDate, el) {
    var tempData = [];
    if (this.rentalType == "D") {
      this.sharedDataService.dailyRentalSelectedDays.forEach(date => {
        tempData.push({
          BoothNo: BoothNumber,
          TempRecordNo: this.sharedDataService.tempRecordNo.toString(),
          StartDate: new Date(date),
          EndDate: new Date(date),
          Sunday: new Date(date).getDay() == 0 ? true : false,
          Monday: new Date(date).getDay() == 1 ? true : false,
          Tuesday: new Date(date).getDay() == 2 ? true : false,
          Wednesday: new Date(date).getDay() == 3 ? true : false,
          Thursday: new Date(date).getDay() == 4 ? true : false,
          Friday: new Date(date).getDay() == 5 ? true : false,
          Saturday: new Date(date).getDay() == 6 ? true : false,
          RentalRenewal: true,
          BoothSearch: true,
          IsOnline: false
        });
      });
    } else if (this.rentalType == "M") {
      tempData.push({
        BoothNo: BoothNumber,
        TempRecordNo: this.LayoutData.tempRecordNo.toString(),
        StartDate: new Date(RentalStartDate),
        EndDate: new Date(RentalEndDate),
        Sunday: el == 1 ? true : false,
        Monday: el == 2 ? true : false,
        Tuesday: el == 3 ? true : false,
        Wednesday: el == 4 ? true : false,
        Thursday: el == 5 ? true : false,
        Friday: el == 6 ? true : false,
        Saturday: el == 7 ? true : false,
        RentalRenewal: true,
        BoothSearch: true,
        IsOnline: false
      });
    } else if (this.rentalType == "CM") {
      tempData.push({
        BoothNo: BoothNumber,
        TempRecordNo: this.LayoutData.tempRecordNo.toString(),
        StartDate: new Date(RentalStartDate),
        EndDate: new Date(RentalEndDate),
        Monday: this.LayoutData.Monday ? true : false,
        Tuesday: this.LayoutData.Tuesday ? true : false,
        Wednesday: this.LayoutData.Wednesday ? true : false,
        Thursday: this.LayoutData.Thursday ? true : false,
        Friday: this.LayoutData.Friday ? true : false,
        Saturday: this.LayoutData.Saturday ? true : false,
        Sunday: this.LayoutData.Sunday ? true : false,
        RentalRenewal: true,
        BoothSearch: true,
        IsOnline: false
      });
    }
    else if (this.rentalType == "CW" || this.rentalType == "W") {
      tempData.push({
        BoothNo: BoothNumber,
        TempRecordNo: this.LayoutData.tempRecordNo.toString(),
        StartDate: new Date(RentalStartDate),
        EndDate: new Date(RentalEndDate),
        Monday: this.LayoutData.Monday ? true : false,
        Tuesday: this.LayoutData.Tuesday ? true : false,
        Wednesday: this.LayoutData.Wednesday ? true : false,
        Thursday: this.LayoutData.Thursday ? true : false,
        Friday: this.LayoutData.Friday ? true : false,
        Saturday: this.LayoutData.Saturday ? true : false,
        Sunday: this.LayoutData.Sunday ? true : false,
        RentalRenewal: true,
        BoothSearch: true,
        IsOnline: false
      });
    }
    this.subscriptions.push(this.rentalService.insertDataToTemp(tempData).subscribe((res: any) => {
    }));
  }

  getMonthIndex(monthString: string): number {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthIndex = months.findIndex(month => month.toLowerCase() === monthString.toLowerCase());
    return monthIndex !== -1 ? monthIndex : 0;
  }

  getBuildingForLayout() {
    this.loaderIcon = Promise.resolve(true);
    this.allExistingBuilding = [];
    const sub = this.marketInfoService.getBuildingAreasInLayOut(this.partId, this.marketId).subscribe((res: any) => {
      this.allExistingBuilding = res;
      let data = res;
      if (data.length > 0) {
       for(const item of data){
          const nodeId = 'node_building' + item.BuildingId;
          const diagramNode: NodeModel = {
            id: nodeId,
            shape: {
              type: 'Basic',
              shape: this.boothShapes.filter(e => e.Id == item.ShapeId)[0].Name
            },
            style: {
              fill: item.BuildingBackColor != 0 ? this.convertColor(item.BuildingBackColor) : "#000",
              strokeColor: item.BuildingBackColor != 0 ? this.convertColor(item.BuildingBorderColor) : "#000",
              strokeWidth: 1,
              fontSize: item.BuildingFontSize,
              textWrapping: 'Wrap',
              fontFamily: this.fontName.filter(e => e.Id == item.BuildingFontNameId)[0].Name
            },
            annotations:
              [{
                content: item.Caption,
                style: {
                  fontSize: item.BuildingFontSize,
                  fontFamily: this.fontName.filter(e => e.Id == item.BuildingFontNameId)[0].Name,
                  textWrapping: 'Wrap',
                  bold: this.fontStyle.filter(e => e.Id == item.BuildingFontStyleId)[0].Name.toLowerCase().includes('bold'),
                  italic: this.fontStyle.filter(e => e.Id == item.BuildingFontStyleId)[0].Name.toLowerCase().includes('italic')
                },
                constraints: AnnotationConstraints.ReadOnly
              }],
            width: item.BuildingWidth * 3,
            height: item.BuildingLength * 3,
            addInfo: {
              allowDrag: false, // Enable drag-and-drop for this shape
              id: item.BuildingId,
              name: 'building'
            },
            constraints: NodeConstraints.Default & ~(NodeConstraints.Drag),
            offsetX: item.LeftCoordinate,
            offsetY: item.TopCoordinate,
            rotateAngle: item.BuildingVerticalDisplay == true ? 90 : 0
          };
          this.diagram.add(diagramNode);
        }
        this.diagram.fitToPage();
        if (this.defaultZoomFactor) {
          let zoomOptions: ZoomOptions = {
            type: 'ZoomIn',
            zoomFactor: this.defaultZoomFactor
          };
          this.diagram.zoomTo(zoomOptions);
        }
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  getImagesForLayout() {
    this.loaderIcon = Promise.resolve(true);
    this.marketInfoService.getLayoutImages(this.partId, this.marketId).subscribe((response: any) => {
      if (response) {
        let data = response;
        if (data.length > 0) {

          for (let i = 0; i < data.length; i++) {
            let node = {
              id: 'node_images' + data[i].ImagesForLayoutId,
              addInfo: {
                status: this.layoutImages.filter(e => e.ImageId == data[i].LayoutImagesId)[0].ImagePath,
                id: data[i].ImagesForLayoutId,
                name: "image",
                ImageId: data[i].LayoutImagesId,
              },
              style: {
                strokeWidth: 0
              },
              annotations: [
                {
              //    content: this.layoutImages.filter(e => e.ImageId == data[i].LayoutImagesId)[0].ImageName,
                  constraints: AnnotationConstraints.ReadOnly,
                  offset: { x: 0.05, y: -0.07 }
                }
              ],
              constraints: NodeConstraints.Default & ~(NodeConstraints.Drag),
              shape: { type: 'Image', shape: 'Image' } as ImageModel, // Type assertion to ImageModel
              width: data[i].ImageWidth * 3,
              height: data[i].ImageLength * 3,
              offsetX: data[i].ImageLeftCoordinate,
              offsetY: data[i].ImageTopCoordinate,
            };

            if ((node.shape as { type: string }).type === 'Image') {
              (node.shape as ImageModel).source = node.addInfo.status;
            }

            this.diagram.add(node);
          }
          this.diagram.fitToPage();
          if (this.defaultZoomFactor) {
            let zoomOptions: ZoomOptions = {
              type: 'ZoomIn',
              zoomFactor: this.defaultZoomFactor
            };
            this.diagram.zoomTo(zoomOptions);
          }
          this.loaderIcon = Promise.resolve(false);
        }
      }
      this.loaderIcon = Promise.resolve(false);
    });
  }

  getImages() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.marketInfoService.getImages().subscribe((res: any) => {
      if (res) {
        this.layoutImages = res;
        let data = this.layoutImages;
        if (this.layoutImages.length > 0) {
          for (let i = 0; i < this.layoutImages.length; i++) {
            const nodeId = 'node_' + data[i].ImageId;
            let node = {
              id: nodeId, addInfo: {
                status: data[i].ImagePath, id: 'z' + data[i].ImageId, name: "image", ImageId: data[i].ImageId,
              },
              annotations: [{ content: data[i].ImageName, constraints: AnnotationConstraints.ReadOnly, offset: { x: 0.5, y: -0.07 } }],
              shape: { type: 'Image', shape: 'Image' },
              width: 50, height: 50, offsetX: 50, offsetY: 50,
            };
            if (node.shape.type === 'Image') {
              (node.shape as ImageModel).source = node.addInfo.status;
            }
            this.images.push(node);
          }

          this.palettes = [
            {
              id: 'Images',
              expanded: true,
              symbols: this.images,
              title: 'Images'
            },
          ]
        }
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  layoutZoomInClick(event) {
    let zoomOptions: ZoomOptions = {
      type: "ZoomIn",
      zoomFactor: 0.2
    }
    if (event.detail > 0) {
      this.diagram.zoomTo(zoomOptions)
    }
  }

  layoutZoomOutClick(flag = false) {
    let zoomOptions: ZoomOptions = {
      type: "ZoomOut",
      zoomFactor: 0.2
    }
    if (flag) {
      this.diagram.zoomTo(zoomOptions)
    }
  }
  
  searchNodes(): void {
    const nodes = this.diagram.nodes as any[];
    var nodeExist = false
    this.stopBlinking();
    if (this.layoutForm.controls['layoutDiagramFilter'].value['searchBooth']) {
      nodes.forEach(node => {
        if (node.properties.addInfo.name == "booth") {
          if (node.annotations && node.annotations[0].content.toLowerCase() == this.layoutForm.controls['layoutDiagramFilter'].value['searchBooth'].toLowerCase()) {
            this.blinkingNode = node.id;
            this.startBlinking();
            nodeExist = true;
          }
        }
      });
      if (!nodeExist)
        // this.toastr.warning($localize`There is no space having this number`, $localize`Error`, this.toasterConfig);
        Swal.fire({
          title: $localize`Warning`,
          text: $localize`There is no booth having this number`,
          icon: "warning",
          confirmButtonText: $localize`Ok`,
        });
    }
    else {
      Swal.fire({
        title: $localize`Warning`,
        text: $localize`Please enter the booth number to search`,
        icon: "warning",
        confirmButtonText: $localize`Ok`,
      });
    }
  }

  changeSearchText() {
    if (this.layoutForm.controls['layoutDiagramFilter'].value['searchBooth'] == '') {
      this.stopBlinking();
    }
  }

  startBlinking(): void {
    this.stopBlinking(); // Ensure no previous intervals are running

    this.blinkInterval = setInterval(() => {
      const node = this.diagram.getObject(this.blinkingNode) as NodeModel;
      if (node) {
        node.style.fill = node.style.fill === 'red' ? 'transparent' : 'red';
      }
    }, 500); // Blink every 500 milliseconds

    setTimeout(() => {
      this.stopBlinking();
      this.layoutForm.controls['layoutDiagramFilter'].get('searchBooth').setValue('');
    }, 5000); // Stop blinking after 5 seconds
  }

  stopBlinking(): void {
    if (this.blinkInterval) {
      clearInterval(this.blinkInterval);
      this.blinkInterval = null;

      const node = this.diagram.getObject(this.blinkingNode) as NodeModel;
      if (node) {
        const nodeId = node.id.replace("node_","");
        const boothData = this.allBooths.find(item => item.BoothNumber == nodeId);
        if (boothData) {
          const fontColor = boothData.Status.toLowerCase() == "r" ? "#fff" : "#000";
          node.style.fill = this.convertColor(boothData.BackColor);
          node.style.strokeColor = this.convertColor(boothData.BorderColor);
        }
      }
    }
  }
  
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
