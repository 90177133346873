import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) { }
  marketId;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.route.params.subscribe((params: Params) => {
      this.marketId = route.paramMap.get('marketId');
    });
    let isLoggedIn: boolean = this.authService.isLoginAutenticated();

    if (isLoggedIn)
      return true;
    else
      this.router.navigate(['/login/' + this.marketId]);
  }
}
