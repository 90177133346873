import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { GeneralService } from 'src/app/services/General.service';
import { IVendorGeneralSettings } from 'src/app/Shared/models/SettingsModel';
import { FormBuilder, FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ICountry, IStates, RegistrationFeeModel } from 'src/app/Shared/models/GeneralModel';
import { Subscription } from 'rxjs';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { VendorService } from 'src/app/services/Vendor.service';
import { registerLicense } from '@syncfusion/ej2-base';
import { IVendorInfo, IVendorProductModel, IVendorProductImageContents, IVendorProductImages, IVendorLoginInfoModel } from 'src/app/Shared/models/VendorModel';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { customCurrencyPipe } from 'src/app/Shared/customCurrency.pipe';
import { PaymentService } from 'src/app/services/paymentService.service';
import { RentalService } from 'src/app/services/Rental.service';
@Component({
  selector: 'app-vendor-registration',
  templateUrl: './vendor-registration.component.html',
  styleUrls: ['./vendor-registration.component.scss']
})
export class VendorRegistrationComponent {
  showContactInfo: boolean = true;
  showOnlineAccess: boolean = false;
  showregisterpayment: boolean = false;
  @ViewChild('btnContact') btnContact!: ElementRef;
  @ViewChild('btnOnlineAccess') btnOnlineAccess!: ElementRef;
  @ViewChild('btnPayment') btnPayment!: ElementRef;
  private subscriptions: Subscription[] = [];
  IsQDR = false;
  transactionType = "CREDITSALE";
  verifoneId = "";
  countries: any;
  VendorForm: FormGroup;
  maxDate: Date;
  ProductPriority: FormGroup;
  VendorProductForm: FormGroup;
  OnlineAccessForm: FormGroup;
  countryList: ICountry[];
  stateList: IStates[];
  VendorProductsList: IVendorProductModel[] = [];
  VendorProductsImageList: IVendorProductImages[] = [] as IVendorProductImages[];
  VendorProductsImageContentList: IVendorProductImageContents[] = [] as IVendorProductImageContents[];
  VendorTypeList = [];
  public ProductDropDownfields: Object = { text: 'ProductName', value: 'ProductId' };
  public StateDropDownfields: Object = { text: 'StateCode', value: 'StateCode' };
  filterSettings: Object = { ignoreCase: true, filterType: 'Contains' };
  public PhoneNumberMask: String = '000-000-0000';
  public ZipCodeMask: String = '00000-0000';
  public PhoneEXTMask: String = '000';
  VendorGeneralSetting: IVendorGeneralSettings = {} as IVendorGeneralSettings;
  response;
  toasterConfig = {
    closeButton: true,
    timeOut: 4500,
    easing: 'ease-out',
    progressBar: true,
    positionClass: 'toast-top-right',
    tapToDismiss: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'slideUp'
  };
  @Input() VId;
  showStateDropDown: boolean;
  public loaderIcon: Promise<boolean>;
  securityQuestions: any[];
  marketId: any;
  webRentalSetting: any;
  productList: any[];
  registrationFee: RegistrationFeeModel;
  vendorLoginData: IVendorLoginInfoModel;
  validationResponse: any;
  DisableRegisterBtn: boolean = true;
  vendorinfo: IVendorInfo;
  paymentData: any;
  voidPayment = false;
  processPayment = false;
  paymentGatewayCredentials: any;
  processCreditCardOnline: any;
  vendorId: any = 0;
  obj: any = {};
  marketName = "";
  constructor(private renderer: Renderer2,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private generalService: GeneralService,
    private marketInfoService: MarketInfoService,
    private vendorService: VendorService,
    private paymentService: PaymentService,
    private rentalService: RentalService,
    private customCurrency: customCurrencyPipe,
    private router: Router) {
    const today = new Date();
    this.maxDate = today;
    this.VendorForm = this.formBuilder.group(
      {
        Address: ['', [Validators.maxLength(50)]],
        BusAddress: ['', [Validators.maxLength(50)]],
        BusCity: [''],
        BusState: [''],
        BusTelePhone: [''],
        BusZipCode: ['', [Validators.maxLength(10)]],
        CellPhone: ['', [Validators.minLength(10), Validators.maxLength(12)]],
        City: [''],
        CompanyName: ['', [Validators.maxLength(50)]],
        Country: [''],
        CountryCode: [0],
        DLExpiryDate: [null],
        DriverLicenseNumber: [''],
        ResaleNumber: [''],
        BusinessLicenseNumber: [''],
        Email: ['', [Validators.maxLength(40), Validators.pattern('^(([\\w+-.%]+)@([\\w-.]+)\\.([A-Za-z]{2,}))([\\s]?[,;]{1}[\\s]?([\\w+-.%]+)@([\\w-.]+)\\.([A-Za-z]{2,})){0,4}$')]],
        FirstName: ['', [Validators.required, Validators.maxLength(50)]],
        LastName: ['', [Validators.maxLength(50)]],
        MarketId: [0, [Validators.required]],
        MarketVendorId: [0, [Validators.required]],
        MiddleName: ['', [Validators.maxLength(50)]],
        State: [''],
        VendorId: [0, [Validators.required]],
        VendorSince: [Date],
        VendorType: ['', Validators.required],
        WorkPhone: [''],
        TelePhone: [''],
        ZipCode: [''],
        ProductPriority: ['', [Validators.required]],
      });

    this.ProductPriority = this.formBuilder.group(
      {
        VProductId: [0, Validators.required],
        Priority: [false],
        VendorId: [0, Validators.required]
      }
    );
    this.VendorProductForm = this.formBuilder.group(
      {
        VProductId: [0, Validators.required],
        ProductId: [0, Validators.required],
        ProductCategoryId: [0, Validators.required],
        ProductCategory: ['', Validators.required],
        ProductName: ['', Validators.required],
        Priority: [false, Validators.required]
      }
    );
    const passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const password = control.value;
      const minLength = 6;
      const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      const hasUpperCase = /[A-Z]+/;
      const hasLowerCase = /[a-z]+/;

      // Check the password against the conditions
      if (password.length < minLength) {
        return { minLength: true };
      }
      if (!hasSpecialChar.test(password)) {
        return { specialChar: true };
      }
      if (!hasUpperCase.test(password)) {
        return { upperCase: true };
      }
      if (!hasLowerCase.test(password)) {
        return { lowerCase: true };
      }

      return null;
    };

    this.OnlineAccessForm = this.formBuilder.group(
      {
        UserName: ['', [Validators.maxLength(16), Validators.required]],
        Password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), passwordValidator]],
        ConfirmPassword: ['', Validators.required],
        SecurityQuestion: ['', Validators.required],
        Answer: ['', Validators.required],
        RegistrationFee: [],
        registrationPaymentCheck: new FormControl(false),
        registrationRuleReadCheck: new FormControl(false),
      });

  }

  ngOnInit(): void {
    this.loaderIcon = Promise.resolve(true);
    this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
      this.marketInfoService.setMarketId(this.marketId);
    });
    this.getMarketDetails();
    this.getWebRentalSetting(this.marketId);
    this.getVendorGeneralSettings();
    this.getCountries();
    this.getStates();
    this.getSecurityQuestion();
    this.getallproducts(this.marketId);
    this.getVendorTypes();
    this.RegistrationFee(this.marketId);
    this.getCCCredentials();
    this.loaderIcon = Promise.resolve(false);
  }

  getMarketDetails() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.marketInfoService.getMarketDetails(this.marketId).subscribe((res: any) => {
      if (res) {
          this.marketName = res.MarketName;
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  getCountries() {
    const sub = this.generalService.getCountries().subscribe((response: ICountry[]) => {
      this.countryList = response;
    });
    this.subscriptions.push(sub);
  }


  ngAfterViewInit() {
    this.renderer.setStyle(this.btnContact.nativeElement, 'background-color', '#DDEBFC');
    this.VendorForm.patchValue({
      CountryCode: this.countryList[0].Code
    });
    this.onCountryChange();
  }

  displayContactInfo() {
    this.showContactInfo = true;
    this.showOnlineAccess = false;
    this.showregisterpayment = false;
    this.renderer.setStyle(this.btnContact.nativeElement, 'background-color', '#DDEBFC');
    this.renderer.setStyle(this.btnOnlineAccess.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnPayment.nativeElement, 'background-color', 'white');
  }

  displayOnlineAccess() {
    this.showContactInfo = false;
    this.showOnlineAccess = true;
    this.showregisterpayment = false;
    this.renderer.setStyle(this.btnOnlineAccess.nativeElement, 'background-color', '#DDEBFC');
    this.renderer.setStyle(this.btnContact.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnPayment.nativeElement, 'background-color', 'white');
  }

  displayRegisterPayment() {
    this.showContactInfo = false;
    this.showOnlineAccess = false;
    this.showregisterpayment = true;
    this.renderer.setStyle(this.btnOnlineAccess.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnContact.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnPayment.nativeElement, 'background-color', '#DDEBFC');
  }

  onCountryChange() {
    this.showStateDropDown = true;
    if (this.VendorForm.value['CountryCode'] == 2) {
      this.showStateDropDown = false;
    }
  }

  getStates() {
    const sub = this.generalService.getUSStates().subscribe((response: IStates[]) => {
      this.stateList = response;
    });
    this.subscriptions.push(sub);
  }

  getVendorGeneralSettings() {
    this.loaderIcon = Promise.resolve(true);
    this.subscriptions.push(this.marketInfoService.getCurrentMarketVendorGeneralSettings(this.marketId).subscribe((res: any) => {
      
      if (res) {
        this.VendorGeneralSetting = {
          IsVendorIdManual : res.SpecifyVendorID,
          VendorAddressMandatory : res.VendAddressMandatory,
          AllowAbsenteeRefund : res.BoothStatusRefund,
          TermForLease : res.LeaseLabel,
          DefaultProductEnabled : res.DefaultProductSelected,
          Product : res.ProductId,
          ResaleNumberRequired : res.ResaleNumberRequired,
          DaysToSellWithoutResaleNo : res.DaysSellWithoutResaleNumber,
          BusinessLicenseNumberReq : res.BusinessLicenseNumberRequired,
          DaysToSellWithoutBusinessLicenseNo : res.DaysSellWithoutBusiLicNumber,
          AlphaNumericField1Reqd : res.AlphaNumericField1Reqd,
          AlphaNumericField2Reqd : res.AlphaNumericField2Reqd,
          AlphaNumericField1Name : res.AlphaNumericField1Name,
          AlphaNumericField2Name : res.AlphaNumericField2Name,
          NumericField1Reqd : res.NumericField1Reqd,
          NumericField2Reqd : res.NumericField2Reqd,
          NumericField1Name : res.NumericField1Name,
          NumericField2Name : res.NumericField2Name,
          VendorNameFormat : res.VendorNamingConvention,
          VendorNameFormatId : res.VendorNamingConventionId,
          BoothTerm : res.BoothTerm
        }
        if(!this.VendorGeneralSetting.IsVendorIdManual){
          this.VendorForm.get('MarketVendorId').disable(); 
        }

        if(this.VendorGeneralSetting.VendorAddressMandatory){

          this.VendorForm.get('City').setValidators([Validators.required]);
          this.VendorForm.get('ZipCode').setValidators([Validators.required]);
          this.VendorForm.get('State').setValidators([Validators.required]);
          this.VendorForm.get('Address').setValidators([Validators.required]);

          this.VendorForm.get('City').updateValueAndValidity();
          this.VendorForm.get('ZipCode').updateValueAndValidity();
          this.VendorForm.get('State').updateValueAndValidity();
          this.VendorForm.get('Address').updateValueAndValidity();
        }
      }
      this.loaderIcon = Promise.resolve(false);
    }));
    this.loaderIcon = Promise.resolve(false);
  }

  getVendorTypes() {
    const sub = this.vendorService.GetVendorTypes(this.marketId).subscribe((response: IStates[]) => {
      this.VendorTypeList = response;
    });
    this.subscriptions.push(sub);
  }

  getSecurityQuestion() {
    this.generalService.GetSecurityQuestion().subscribe((response) => {
      if (response) {
        this.securityQuestions = response.body;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getWebRentalSetting(marketId: any) {
    this.marketInfoService.getWebRentalSetting(marketId).subscribe((response) => {
      this.webRentalSetting = response;
      if (this.webRentalSetting.MandatoryFields!=""){
        const mandatoryFields = this.webRentalSetting.MandatoryFields.split(",").map(Number);
        console.log("mendatory",mandatoryFields)
        if (mandatoryFields.includes(91)){
          this.VendorForm.get('WorkPhone').setValidators([Validators.required]);
          this.VendorForm.get('WorkPhone').updateValueAndValidity();
        }
        if (mandatoryFields.includes(92)) {
          this.VendorForm.get('DriverLicenseNumber').setValidators([Validators.required]);
          this.VendorForm.get('DriverLicenseNumber').updateValueAndValidity();
        }
        if (mandatoryFields.includes(93)) {
          this.VendorForm.get('DLExpiryDate').setValidators([Validators.required]);
          this.VendorForm.get('DLExpiryDate').updateValueAndValidity();
        }
        if (mandatoryFields.includes(96)) {
          this.VendorForm.get('ResaleNumber').setValidators([Validators.required]);
          this.VendorForm.get('ResaleNumber').updateValueAndValidity();
        }
        if (mandatoryFields.includes(97)) {
          this.VendorForm.get('BusinessLicenseNumber').setValidators([Validators.required]);
          this.VendorForm.get('BusinessLicenseNumber').updateValueAndValidity();
        }
        if (mandatoryFields.includes(98)) {
          this.VendorForm.get('CellPhone').setValidators([Validators.required]);
          this.VendorForm.get('CellPhone').updateValueAndValidity();
        }
        if (mandatoryFields.includes(99)) {
          this.VendorForm.get('Email').setValidators([Validators.required]);
          this.VendorForm.get('Email').updateValueAndValidity();
        }
      }
    }, (err) => {
      console.log(err);
    });
  }

  getallproducts(marketId: any) {

    this.subscriptions.push(this.marketInfoService.GetAllMarketProducts(marketId).subscribe((res: any) => {
      this.productList = [];
      res.map((el, i) => {
        const ob = {
          ProductId: el.ProductId,
          ProductName: el.ProductName,
          ProductCategory: el.ProductCategory,
          ProductCategoryId: el.ProductCategoryId,
          IsInActive: el.IsInActive,
          CompanyProductId: el.CompanyProductId,
        }

        this.productList.push(ob);

      });
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    }));
  }

  RegistrationFee(marketId: any) {
    this.marketInfoService.RegistrationFee(marketId).subscribe((res: any) => {
      this.registrationFee = res;
      this.OnlineAccessForm.patchValue(
        {
          RegistrationFee: this.registrationFee.RegistrationFee.toString() != null ? this.customCurrency.transform(String(this.registrationFee.RegistrationFee)) : this.customCurrency.transform("0.00"),
        }
      );
    }, (err) => {
      console.log(err);
    });
  }

  ValidationVendorDetails(): void {
    this.loaderIcon = Promise.resolve(true);
    if (this.VendorForm.value['FirstName'].trim() == '') {
      this.toastr.error($localize`Vendor first name can't be empty`, $localize`Error`, this.toasterConfig)
      this.loaderIcon = Promise.resolve(false);
      return;
    }
    this.vendorinfo = {
      Address: this.VendorForm.get('Address').value,

      BusAddress: null,
      BusCity: null,
      BusState: null,
      BusTelePhone: null,
      BusZipCode: null,
      CellPhone: this.VendorForm.get('CellPhone').value,
      City: this.VendorForm.get('City').value,

      CompanyName: this.VendorForm.get('CompanyName').value,
      Country: this.VendorForm.get('Country').value,
      CountryCode: this.VendorForm.get('CountryCode').value,
      DLExpiryDate: this.VendorForm.get('DLExpiryDate').value,
      DriverLicenseNumber: this.VendorForm.get('DriverLicenseNumber').value == null ? '' : this.VendorForm.get('DriverLicenseNumber').value,
      Email: this.VendorForm.get('Email').value,
      FirstName: this.VendorForm.get('FirstName').value,

      LastName: this.VendorForm.get('LastName').value,
      MarketId: Number(this.marketId),
      MarketVendorId: this.VendorForm.get('MarketVendorId').value,
      MiddleName: this.VendorForm.get('MiddleName').value,

      State: this.VendorForm.get('State').value,
      TelephoneEXT: null,
      VendorId: this.VendorForm.get('VendorId').value,
      VendorSince: new Date(),
      VendorType: this.VendorForm.get('VendorType').value,
      TelePhone: this.VendorForm.get('TelePhone').value,
      WorkPhone: this.VendorForm.get('WorkPhone').value,
      ZipCode: this.VendorForm.get('ZipCode').value,
      BusTelephoneEXT: null,

      VendorName: "",
      VendorProductsList: this.VendorProductsList,
      VendorProductImageList: this.VendorProductsImageList,
      VendorProductImageContentList: this.VendorProductsImageContentList,
      VendorProfile: null,
      VenTxtPhotoFile: null,
      BusinessLicNum: this.VendorForm.get('BusinessLicenseNumber').value,
      ResaleNum: this.VendorForm.get('ResaleNumber').value,
    };

    this.vendorLoginData = {
      VendorId: 0,
      VendorName: null,
      OnlineAccess: true,
      OnlineUserId: this.OnlineAccessForm.get('UserName').value,
      OnlinePassword: this.OnlineAccessForm.get('Password').value,
      SecurityQuestionId: this.OnlineAccessForm.get('SecurityQuestion').value,
      SecurityQuestionAnswer: this.OnlineAccessForm.get('Answer').value,
      OnlineRegistrationDate: new Date()
    };

    this.vendorService.RegisterVendorInfoValidation(this.vendorinfo).subscribe((res: any) => {
      this.validationResponse = res;
      if (this.validationResponse.Success) {
        this.displayOnlineAccess();
      } else {
        this.toastr.error(this.validationResponse.Message, $localize`Error`, this.toasterConfig)
      }
      this.loaderIcon = Promise.resolve(false);
    });
    this.loaderIcon = Promise.resolve(false);
  }

  VendorLoginDetailsValidation() {
    this.vendorLoginData = {
      VendorId: 0,
      VendorName: null,
      OnlineAccess: true,
      OnlineUserId: this.OnlineAccessForm.get('UserName').value,
      OnlinePassword: this.OnlineAccessForm.get('Password').value,
      SecurityQuestionId: this.OnlineAccessForm.get('SecurityQuestion').value,
      SecurityQuestionAnswer: this.OnlineAccessForm.get('Answer').value,
      OnlineRegistrationDate: new Date()
    };

    const check = this.CheckValidations();
    if (!check) {
      this.loaderIcon = Promise.resolve(false);
      return;
    }
    this.vendorService.VendorLoginDetailsValidation(this.vendorLoginData).subscribe((res: any) => {
      this.validationResponse = res
      if (this.validationResponse.Success) {
        if (!(this.registrationFee.RegistrationFee > 0 && this.registrationFee.ChargeVendorRegistration)) {
          this.SaveVendorDetails();
        }
        this.displayRegisterPayment();
      }
      else {
        this.toastr.error(this.validationResponse.Message, $localize`Error`, this.toasterConfig);
      }
    });
  }
  SaveVendorDetails() {
    this.loaderIcon = Promise.resolve(true);
    this.subscriptions.push(this.vendorService.SaveVendor(this.vendorinfo).subscribe((SaveVenresponse: any[]) => {
      this.response = SaveVenresponse;
      if (this.response.Success) {
        //this.toastr.success(this.response.Message, $localize`Success`, this.toasterConfig);
        this.vendorId = this.response.Id;
        if (this.registrationFee.RegistrationFee > 0 && this.registrationFee.ChargeVendorRegistration) {
          this.paymentService.GetVerifoneUnSavedCompleteData(this.verifoneId).subscribe((GetVerfoneresponse: any) => {
            this.obj = {
              VendorId: this.vendorId,
              PaymentOrderId: GetVerfoneresponse.OrderId,
              RentalCharge: 0,
              SalesTax: 0,
              SecLevelTax: 0,
              OtherCharge: this.registrationFee.RegistrationFee,
              PriorBalance: 0,
              TotalBalanceDue: 0,
              RentalNotes: "Registration",
              CashAmount: 0,
              CheckAmount: 0,
              CheckNo: "",
              ChangeAmount: 0,
              CardAmount: this.registrationFee.RegistrationFee,
              DirectDebitAmount: 0,
              CardType: "1",
              PaymentDone: true,
              PaymentDueDate: new Date(),
              VerifoneId: GetVerfoneresponse.VerifoneId,
              DiscountAmount: 0,
              ConvenienceFee: 0,
              DailyRentalBooth: [],
              DailyRentalOtherCharges: [{
                OtherItemId: this.registrationFee.Id, OtherItemName: this.registrationFee.Description, OtherCharge: this.registrationFee.RegistrationFee, Quantity: 1, RentalDay: new Date(), SalesTax: 0, SecLevelTax: 0
              }],
              AppliedDiscountModel: []
            };
            const sub = this.rentalService.SaveDailyRental(this.obj).subscribe(async (SaveDailyRentalResponse: any) => {
              if (SaveDailyRentalResponse.Success) {
                GetVerfoneresponse.VerifoneId = this.verifoneId;
                GetVerfoneresponse.VendorId = this.vendorId;
                this.paymentService.saveVerifoneUnSaved(GetVerfoneresponse).subscribe((res: any) => {
                  this.SaveVendorLoginDetail(this.response.Id);
                });
              } else {
                if (this.verifoneId) {
                  this.voidPayment = true;
                }
              }
              this.loaderIcon = Promise.resolve(false);
            });

            this.subscriptions.push(sub);
          });
        } else {
          this.SaveVendorLoginDetail(this.response.Id);
        }
        this.vendorService.setVendorId(this.vendorId);
        const url = 'home/' + this.marketId;
        this.router.navigate([url]);

      }
      else {
        this.toastr.error(this.response.Message, $localize`Error`, this.toasterConfig)
      }
      this.loaderIcon = Promise.resolve(false);

    }, (err) => {
      this.toastr.error($localize`Something Went Wrong`, $localize`Error`, this.toasterConfig)
      this.loaderIcon = Promise.resolve(false);
    }));
  }

  primaryproductchange(args1) {
    const Id = args1;
    const product = this.productList.find(p => p.ProductId === Id);

    if (product) {
      this.VendorProductsList.pop();
      const newProduct: IVendorProductModel = {
        VProductId: 0,
        ProductId: product.ProductId,
        Priority: true,
        VendorId: 0,
        ProductName: product.ProductName,
        ProductCategoryId: product.ProductCategoryId,
        ProductCategory: product.ProductCategory,
      };
      this.VendorProductsList.push(newProduct);
    } else {
      console.log("Product not found with ID:", Id);
    }
  }

  SaveVendorLoginDetail(Id: any) {
    this.loaderIcon = Promise.resolve(true);
    this.vendorLoginData.VendorId = Id;
    this.vendorService.SaveVendorLoginDetail(this.vendorLoginData).subscribe((res) => {
      this.response = res;
      if (this.response.Success) {
        this.toastr.success($localize`Registration completed Successfully`, $localize`Success`, this.toasterConfig);
        this.vendorService.SendRegistrationEmail(parseInt(Id)).subscribe((response1: any) => {
          if (response1.body.Success) {
            this.toastr.success($localize`Registration Email has been sent to your registered email address`, $localize`Success`, this.toasterConfig);
          } else {
            this.toastr.error($localize`Registration Email sending failed`, $localize`Error`, this.toasterConfig);
          }
        });
      }
      else {
        this.toastr.error($localize`Save was Unsuccessful`, $localize`Error`, this.toasterConfig)
      }
      this.loaderIcon = Promise.resolve(false);
    }, (err) => {
      console.log(err);
      this.toastr.error($localize`Save was Unsuccessful`, $localize`Error`, this.toasterConfig)
      this.loaderIcon = Promise.resolve(false);
    });
  }


  CheckValidations() {
    if (this.OnlineAccessForm.get('UserName').hasError('required') || this.OnlineAccessForm.get('Password').hasError('required') || this.OnlineAccessForm.get('ConfirmPassword').hasError('required') || this.OnlineAccessForm.get('SecurityQuestion').hasError('required') || this.OnlineAccessForm.get('Answer').hasError('required')) {
      this.toastr.error($localize`Please fill all mandatory fields properly`, $localize`Error`, this.toasterConfig)
      return false;
    }

    if (this.OnlineAccessForm.get('Password').hasError('minLength')) {
      this.toastr.error($localize`Password must be atleast 6 character`, $localize`Error`, this.toasterConfig)
      return false;
    }
    if (this.OnlineAccessForm.get('Password').hasError('maxLength')) {
      this.toastr.error($localize`Password must be at least 20 characters long.`, $localize`Error`, this.toasterConfig)
      return false;
    }
    if (this.OnlineAccessForm.get('Password').hasError('upperCase')) {
      this.toastr.error($localize`Password must contain at least one uppercase letter`, $localize`Error`, this.toasterConfig)
      return false;
    }
    if (this.OnlineAccessForm.get('Password').hasError('specialChar')) {
      this.toastr.error($localize`Password must contain at least one special`, $localize`Error`, this.toasterConfig)
      return false;
    }
    if (this.OnlineAccessForm.get('Password').hasError('lowerCase')) {
      this.toastr.error($localize`password must contain at least one lowercase letter`, $localize`Error`, this.toasterConfig)
      return false;
    }
    if (this.OnlineAccessForm.get('Answer').hasError('minLength')) {
      this.toastr.error($localize`Security Answer must be atleast 4 character`, $localize`Error`, this.toasterConfig)
      return false;
    }
    if (this.OnlineAccessForm.get('Password').value != this.OnlineAccessForm.get('ConfirmPassword').value) {
      this.toastr.error($localize`Confirm Password doesn't match.`, $localize`Error`, this.toasterConfig)
      return false;
    }
    return true;
  }

  getCCCredentials() {
    this.paymentService.getCCCredentials(this.marketId).subscribe((res) => {
      if (res !== null) {
        this.paymentGatewayCredentials = res.GatewayDetails ? JSON.parse(res.GatewayDetails) : null;
        this.processCreditCardOnline = res.ProcessCreditCardOnline;
      }
    })
  }

  OnVendorRegister(e: Event) {
    e.preventDefault();
    this.loaderIcon = Promise.resolve(true);
    this.VendorLoginDetailsValidation();
    var creditCardAmount = this.registrationFee.RegistrationFee;
    if (creditCardAmount > 0 && this.registrationFee.ChargeVendorRegistration) {
      if (this.paymentGatewayCredentials && this.processCreditCardOnline)
        this.processPayment = true;
      else {
        var obj = {
          PaymentSuccess: true,
          OrderId: "",
          VerifoneId: 0
        }
        this.PaymentResponse(obj)
      }
    }
    this.loaderIcon = Promise.resolve(false);
    // else {
    //   var obj = {
    //     PaymentSuccess: true,
    //     OrderId: "",
    //     VerifoneId: 0
    //   }
    //   this.PaymentResponse(obj)
    // }
  }

  Oncancel() {
    Swal.fire({
      title: $localize`Are you sure ?`,
      text: $localize`You are about to cancel the registration process.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: $localize`Yes`,
      cancelButtonText: $localize`No`,

    }).then((result) => {
      if (result.value) {
        this.router.navigate(['home']);
      } else {
        return;
      }
    });
  }

  ClosePaymentPage() {
    this.processPayment = false;
    this.voidPayment = false;
    this.displayOnlineAccess();
  }


  PaymentResponse(response) {
    this.loaderIcon = Promise.resolve(true);
    if (response.PaymentSuccess) {
      this.verifoneId = response.VerifoneId;
      this.SaveVendorDetails();

    } else {
      this.processPayment = false;
      this.voidPayment = false;
      this.displayOnlineAccess();
    }
    this.loaderIcon = Promise.resolve(false);
  }
}

