<app-loader *ngIf="loaderIcon | async"></app-loader>
<form [formGroup]="layoutForm">
    <div id="ContainerMain" class="container-fluid">
        <div class="site_height">
                <div class="row my-2" formGroupName="layoutDiagramFilter">
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 align-items-center d-flex">
                        <label style="width: 120px;" i18n>Find Booth</label> &nbsp; &nbsp;
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search" formControlName="searchBooth"
                                (blur)="changeSearchText()">
                            <div class="input-group-append" (click)="searchNodes()">
                                <span class="input-group-text">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex layout_buttons">
                        <button class="btn btnZoom mr-1" (click)="layoutZoomInClick($event)">
                            <img src="../assets/images/ZoomIn.svg" class="img-fluid" />
                        </button>
                        <button class="btn btnZoom" (click)="layoutZoomOutClick($event)">
                            <img src="../assets/images/ZoomOut.svg" class="img-fluid" />
                        </button>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex layout_buttons float-right bottom_button mt-sm-2 w-100 d-flex">
                            <button type="button" class="btn btn-primary mr-1" i18n (click)="AddSelectedBoothsToGrid()"
                                [disabled]="noboothSelected">Done</button>
                            <button type="button" i18n class="btn btn-primary" (click)="closeLayoutForm()">Close</button>
                    </div>
                </div>
                <div class="row my-2" formGroupName="layoutDiagramFilter">  
                    <div class="col-md-4 align-items-center mt-2" style="font-weight: bold;text-align: left;">
                        Part: {{selectedPartName}}
                    </div>
                    <div class="col-md-8 align-items-center">
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 text-left">
                                <input type="checkbox" class="CheckboxClass chkAvailable mt-2" disabled>
                                <p i18n class="chkAlign">Available</p>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 text-left">
                                <input type="checkbox" class="CheckboxClass chkBooked mt-2" disabled>
                                <p i18n class="chkAlign">Booked</p>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 text-left">
                                <input type="checkbox" class="CheckboxClass chkReserved mt-2" disabled>
                                <p i18n class="chkAlign">Reserved</p>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 text-left">
                                <input type="checkbox" class="CheckboxClass chkNonRentable mt-2" disabled>
                                <p i18n class="chkAlign">Non Rentable</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                      <div class="layoutDiagramSection" id="diagram-container">
                           <ejs-diagram #diagram id="diagram" width="100%" [selectedItems]="selectedItems"
                                       (click)="nodeClick($event)" [tooltip]="tooltip" [tool]="tool"
                                       [snapSettings]='snapSettings' (created)='created($event)' (mouseover)="nodeMouseOver($event)"></ejs-diagram>
                        </div>
                      </div>
                </div>
        </div>
    </div>
</form>
