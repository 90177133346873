import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { NewsService } from 'src/app/services/News.service';
import { DatePipe } from "@angular/common";
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent {
  MarketId: any;
  private subscriptions: Subscription[] = [];
  news: any[];
  selectedNews: any;
  error: any;
  filteredNews: any[];
  isMoreNews: boolean;
  label: string;
  marketName = "";
  public loaderIcon: Promise<boolean>;
  constructor(public newsService: NewsService, private route: ActivatedRoute, public datePipe: DatePipe,
    private marketInfoService: MarketInfoService
  ) { }

  public NewsData: any;

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.MarketId = params.marketId;
      this.getMarketDetails();
      this.getNewsData();
      this.isMoreNews = true;
      this.label = '<<More News';
      console.log('Market ID:', this.MarketId);
    });
  }

  getMarketDetails() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.marketInfoService.getMarketDetails(this.MarketId).subscribe((res: any) => {
      if (res) {
          this.marketName = res.MarketName;
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  getNewsData() {
    this.newsService.getActiveNews(this.MarketId).subscribe({
      next: (data) => {
        this.NewsData = data;
        this.filteredNews = this.NewsData.slice(0, 4);
      },
      error: (err) => {
        this.error = err;
        console.error('Error fetching news data:', err);
      }
    });
  }

  openModal(newsData: any) {
    this.selectedNews = newsData;
  }

  loadMoreNews() {
    if (this.isMoreNews) {
      this.filteredNews = this.NewsData;
      this.label = 'Less News>>';
      this.isMoreNews = false;
    } else {
      this.filteredNews = this.NewsData.slice(0,4);
      this.label = '<<More News';
      this.isMoreNews = true;
    }
  }
}