// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';


// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppConfig } from './app/Shared/app-config.inject';
import { registerLicense } from '@syncfusion/ej2-base';

const requestOrigin = window.location.origin;
if (environment.production) {
  enableProdMode();
}

function bootstrapApp() {
  const req = new XMLHttpRequest();

  req.addEventListener('error', (res: any) => {
    console.log('Error: ', res);
  });


  //to auto login if user session already exists --End--
  req.addEventListener('load', (res: any) => {
    let appConfig = JSON.parse(res.currentTarget.response);
    environment.syncfusionKey = appConfig.syncfusionKey;
    environment.apiBaseUrl = appConfig.apiBaseUrl;
    environment.production = appConfig.production;
    registerLicense(environment.syncfusionKey);
    const loginRequest = {
      scopes: ['']
    }

    environment.apiBaseUrl = requestOrigin + "/api";
    console.log("baseURL = " , environment.apiBaseUrl);
    console.log('config', appConfig);

    platformBrowserDynamic([
      {
        provide: AppConfig, useValue: {
          ...appConfig
        }
      },
    ]).bootstrapModule(AppModule)
      .then(() => {
        if ('serviceWorker' in navigator && environment.production) {
          navigator.serviceWorker.register('/ngsw-worker.js');
        }
      })
      .catch(err => console.error(err));
  });

  req.open('GET', '/assets/config/config.json');
  req.send(null);
}

bootstrapApp();
