<app-loader *ngIf="loaderIcon | async"></app-loader>
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-2 col-xl-2 alignMenu shadow text-left pt-2 px-1">
            <app-rental-menu></app-rental-menu>
        </div>
        <div class="col-lg-10 col-xl-10 bgMainSection bgBlue text-left">
            <form [formGroup]="marketMap">
                <div class="container mainContainer">
                    <div class="row">
                        <div class="col-5 text-left mt-4">
                            <label class="mainHeading">Market Map</label>
                        </div>
                        <div class="col-7 text-right mt-4">
                            <label class="mainHeading">{{marketName}}</label>
                        </div>
                    </div>
                    <div class="bgWghite shadow pt-2 mt-2">
                        <div class="container" style="max-width: 100% !important;">
                            <div class="row mt-1 pl-4">
                                <div class="col-md-6 col-lg-2 col-sm-12 col-xl-2 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsDaily">
                                    <input type="radio" id="rdDaily" class="form-check-input" value="1"
                                        formControlName="rentalTypes" (change)="rentalTypeChange()">
                                    <label for="rdDaily" class="form-check-label">Daily</label>
                                </div>
                                <div class="col-md-6 col-lg-2 col-sm-12 col-xl-2 ml-lg-3 ml-xl-0"
                                    *ngIf="AllowBookingsWeekly">
                                    <input type="radio" id="rdWeekly" class="form-check-input" value="3"
                                        formControlName="rentalTypes" (change)="rentalTypeChange()">
                                    <label for="rdWeekly" class="form-check-label">Weekly</label>
                                </div>
                                <div class="col-md-6 col-lg-2 col-sm-12 col-xl-2 ml-lg-3 ml-xl-0"
                                    *ngIf="AllowBookingsMonthly">
                                    <input type="radio" id="rdMonthly" class="form-check-input" value="2"
                                        formControlName="rentalTypes" (change)="rentalTypeChange()">
                                    <label for="rdMonthly" class="form-check-label">Monthly</label>
                                </div>
                                <div class="col-md-6 col-lg-3 col-sm-12 col-xl-3 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsCw">
                                    <input type="radio" id="rdCombinedWeek" class="form-check-input" value="5"
                                        formControlName="rentalTypes" (change)="rentalTypeChange()">
                                    <label for="rdCombinedWeek" class="form-check-label">Combined Weekly</label>
                                </div>
                                <div class="col-md-6 col-lg-3 col-sm-12 col-xl-3 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsCm">
                                    <input type="radio" id="rdCombinedMonth" class="form-check-input" value="4"
                                        formControlName="rentalTypes" (change)="rentalTypeChange()">
                                    <label for="rdCombinedMonth" class="form-check-label">Combined Monthly</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <hr class="my-2" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 text-left"></div>
                                <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 text-right">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-4 col-xl-3 text-left">
                                            <label class="mt-3 mb-0">Select Layout Part</label>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-2">
                                            <select class="form-control alignLayoutPart" formControlName="marketParts"
                                                (change)="changeMarketMap()">
                                                <option *ngFor="let item of parts" [value]="item.PartId">
                                                    {{item.PartDescription}}</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-2 col-xl-2 text-left mt-2">
                                            <button class="btn btn-primary alignLayoutPart" (click)="viewLayout()">View Layout</button>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-2 col-xl-2 d-flex layout_buttons mt-1">
                                            <button class="btn btnZoom alignLayoutPart mr-1" (click)="layoutZoomInClick($event)">
                                                <img src="../assets/images/ZoomIn.svg" class="img-fluid" />
                                            </button>
                                            <button class="btn btnZoom" (click)="layoutZoomOutClick($event)">
                                                <img src="../assets/images/ZoomOut.svg" class="img-fluid" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3 text-left mt-2">
                                    <div class="card shadow">
                                        <div class="card-header fw-700">
                                            Market Day(s):
                                        </div>
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item" *ngIf="monday">
                                                <div class="form-check d-flex align-items-center">
                                                    <input class="form-check-input CheckboxClass" type="checkbox" id="chkMonday" formControlName="marketWeekMonday"
                                                    (change)="toggleDaySelection('Monday', $event)">
                                                    <label class="form-check-label ml-2 mt-1" for="chkMonday" i18n>
                                                        Monday
                                                    </label>
                                                </div>
                                            </li>
                                            <li class="list-group-item" *ngIf="tuesday">
                                                <div class="form-check d-flex align-items-center">
                                                    <input class="form-check-input CheckboxClass" type="checkbox" id="chkTuesday" formControlName="marketWeekTuesday"
                                                    (change)="toggleDaySelection('Tuesday', $event)">
                                                    <label class="form-check-label ml-2 mt-1" for="chkTuesday" i18n>
                                                        Tuesday
                                                    </label>
                                                </div>
                                            </li>
                                            <li class="list-group-item" *ngIf="wednesday">
                                                <div class="form-check d-flex align-items-center">
                                                    <input class="form-check-input CheckboxClass" type="checkbox" id="chkWednesday" formControlName="marketWeekWednesday"
                                                    (change)="toggleDaySelection('Wednesday', $event)">
                                                    <label class="form-check-label ml-2 mt-1" for="chkWednesday" i18n>
                                                        Wednesday
                                                    </label>
                                                </div>
                                            </li>
                                            <li class="list-group-item" *ngIf="thursay">
                                                <div class="form-check d-flex align-items-center">
                                                    <input class="form-check-input CheckboxClass" type="checkbox" id="chkThursday" formControlName="marketWeekThursday"
                                                    (change)="toggleDaySelection('Thursday', $event)">
                                                    <label class="form-check-label ml-2 mt-1" for="chkThursday" i18n>
                                                        Thursday
                                                    </label>
                                                </div>
                                            </li>
                                            <li class="list-group-item" *ngIf="friday">
                                                <div class="form-check d-flex align-items-center">
                                                    <input class="form-check-input CheckboxClass" type="checkbox" id="chkFriday" formControlName="marketWeekFriday"
                                                    (change)="toggleDaySelection('Friday', $event)">
                                                    <label class="form-check-label ml-2 mt-1" for="chkFriday" i18n>
                                                        Friday
                                                    </label>
                                                </div>
                                            </li>
                                            <li class="list-group-item" *ngIf="saturday">
                                                <div class="form-check d-flex align-items-center">
                                                    <input class="form-check-input CheckboxClass" type="checkbox" id="chkSaturday" formControlName="marketWeekSaturday"
                                                    (change)="toggleDaySelection('Saturday', $event)">
                                                    <label class="form-check-label ml-2 mt-1" for="chkSaturday" i18n>
                                                        Saturday
                                                    </label>
                                                </div>
                                            </li>
                                            <li class="list-group-item" *ngIf="sunday">
                                                <div class="form-check d-flex align-items-center">
                                                    <input class="form-check-input CheckboxClass" type="checkbox" id="chkSunday" formControlName="marketWeekSunday"
                                                    (change)="toggleDaySelection('Sunday', $event)">
                                                    <label class="form-check-label ml-2 mt-1" for="chkSunday" i18n>
                                                        Sunday
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card shadow mt-2" *ngIf="this.marketMap.value['rentalTypes'] == '1'">
                                        <div class="card-header fw-700">
                                            Rental Day(s):
                                        </div>
                                        <ul class="list-group list-group-flush marketDates">
                                            <li class="list-group-item"
                                                *ngFor="let item of filteredMarketDays; let i=index">
                                                <div class="form-check d-flex align-items-center">
                                                    <input class="form-check-input CheckboxClass" type="checkbox" [id]="'chk' + (i + 1)" (change)="checkboxChanged($event,item)">
                                                    <label class="form-check-label ml-2 mt-1" [attr.for]="'chk' + (i + 1)" i18n>
                                                        {{item.MarketDays}}
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="card shadow mt-2"
                                        *ngIf="this.marketMap.value['rentalTypes'] == '3' || this.marketMap.value['rentalTypes'] == '5'">
                                        <div class="card-header fw-700">
                                            Weekly Market(s):
                                        </div>
                                        <div class="mt-1">
                                            <select class="form-control" (change)="checkRentalWeeks()"
                                                formControlName="rentalStartDate">
                                                <option *ngFor="let item of weeklyMarketDays;"
                                                    [value]="item.RentalStartDays">
                                                    {{item.RentalStartDays}}</option>
                                            </select>
                                        </div>
                                        <div class="mt-1">
                                            <select class="form-control" (change)="checkRentalWeeks()"
                                                formControlName="rentalEndDate">
                                                <option *ngFor="let item of weeklyMarketDays;"
                                                    [value]="item.RentalEndDays">
                                                    {{item.RentalEndDays}}</option>
                                            </select>
                                        </div>
                                        <div class="row mt-1 mb-1" style="padding: .375rem .75rem;">
                                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                                                <label class="mb-0" i18n>Rental Start Date</label>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                                                <select class="form-control" formControlName="weeklyRentalStartDates">
                                                    <option *ngFor="let item of rentalStartDates;"
                                                        [value]="item.startDate">
                                                        {{item.startDate}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card shadow mt-2"
                                        *ngIf="this.marketMap.value['rentalTypes'] == '2' || this.marketMap.value['rentalTypes'] == '4'">
                                        <div class="card-header fw-700">
                                            Monthly Market(s):
                                        </div>
                                        <div class="row mt-1" style="padding: .375rem .75rem;">
                                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                                                <label class="mb-0" i18n>Rental Start Month</label>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                                                <select class="form-control" formControlName="rentalStartMonth"
                                                    (change)="checkRentalMonths()">
                                                    <option *ngFor="let item of monthlyMarketDays;"
                                                        [value]="item.RentalMonths">
                                                        {{item.RentalMonths}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mt-1" style="padding: .375rem .75rem;">
                                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                                                <label class="mb-0">Rental End Month</label>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                                                <select class="form-control" formControlName="rentalEndMonth"
                                                    (change)="checkRentalMonths()">
                                                    <option *ngFor="let item of monthlyMarketDays;"
                                                        [value]="item.RentalMonths">
                                                        {{item.RentalMonths}}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row mt-1" style="padding: .375rem .75rem;">
                                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                                                <label class="mb-0" i18n>Rental Start Date</label>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                                                <select class="form-control" formControlName="monthlyRentalStartDates">
                                                    <option *ngFor="let item of monthlyRentalStartDates;"
                                                        [value]="item.startDate">
                                                        {{item.startDate}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-8 col-xl-9 text-left pl-lg-0 mt-2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="Layout">
                                                <ejs-diagram #diagram id="diagram" width="100%" height="500px"
                                                    style="overflow: visible;" (created)='created($event)'
                                                    [selectedItems]="selectedItems" [snapSettings]='snapSettings'></ejs-diagram>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 text-left">
                                                    <input type="checkbox" class="CheckboxClass chkAvailable mt-2" disabled>
                                                    <p i18n class="chkAlign">Available</p>
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 text-left">
                                                    <input type="checkbox" class="CheckboxClass chkBooked mt-2" disabled>
                                                    <p i18n class="chkAlign">Booked</p>
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 text-left">
                                                    <input type="checkbox" class="CheckboxClass chkReserved mt-2" disabled>
                                                    <p i18n class="chkAlign">Reserved</p>
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 text-left">
                                                    <input type="checkbox" class="CheckboxClass chkNonRentable mt-2"
                                                        disabled>
                                                    <p i18n class="chkAlign">Non Rentable</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center mt-3 mb-3">
                                            <label style="color: red">Please log in for renting or renewing the
                                                spaces</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>