<app-loader *ngIf="loaderIcon | async"></app-loader>
<form [formGroup]="loginForm">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <div class="login shadow">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 text-center mt-2">
                                <img [src]="imageUrl" style="width: 30%;" class="img-fluid">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center mt-3">
                                <label class="txtWelcome">Welcome back! Please login to continue.</label>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                                <span class="error-message" *ngIf="loginError">
                                    <p i18n>User Name / Password is incorrect!</p>
                                </span>
                                <span class="error-message" *ngIf="inActive">
                                    <p i18n>User is Inactive!</p>
                                </span>
                            </div>
                        </div>
                        <div class="row" style="margin: 10px;">
                            <div class="col-6 text-left mt-1">
                                <input type="radio" id="rduserId" class="form-check-input" value="1"
                                    formControlName="loginUserName">
                                <label for="rduserId" class="form-check-label">User Id</label>
                            </div>
                            <div class="col-6 text-left mt-1">
                                <input type="radio" id="rdEmail" class="form-check-input" value="2"
                                    formControlName="loginUserName">
                                <label for="rdEmail" class="form-check-label">Email Address</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 text-center mt-1">
                                <div class="input-group shadow">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text email"></span>
                                    </div>
                                    <input type="text" class="form-control" placeholder="Username or email"
                                        formControlName="username" (keyup)="validateLoginCredentials()" required>
                                </div>
                                <div *ngIf="displayUsernameError" class="error-message text-left">
                                    <ng-container i18n>Email address is required.</ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center mt-3">
                                <div class="input-group shadow">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"></span>
                                    </div>
                                    <input type="password" class="form-control" placeholder="Password"
                                        formControlName="password" (keyup)="validateLoginCredentials()" required>
                                </div>
                                <div *ngIf="displayPasswordError" class="error-message text-left">
                                    <ng-container i18n>Password is required.</ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 text-left mt-2">
                                <div class="checkbox-wrapper">
                                    <label class="pure-material-checkbox">
                                        <input type="checkbox" class="CheckboxClass shadow"
                                            formControlName="rememberMe">
                                        <span i18n class="chkAlign fw-700 pl-0 respText">Remember Me</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-6 text-right mt-2">
                                <a class="respText fw-700" style="cursor: pointer;"
                                    (click)="ForgotPassword($event)">Forgot
                                    Password?</a>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-6 text-center mt-2">
                                <button class="btn btn-primary" style="width: 90px;"
                                    [routerLink]="['/home', marketId]">Back</button>
                            </div>
                            <div class="col-6 text-center mt-2">
                                <button class="btn btn-primary" style="width: 90px;"
                                    (click)="validateLogin()">Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>